import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITicketMessage } from "../../../types/ApiTypes";

export const useTicketMessages = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ITicketMessage>>(ApiRoutes.Tickets.Messages.getValue({param: RouteParam.TicketId, value: id }));

    return {
        messages: data,
        loadingMessages: isLoading,
        reloadMessages: mutate
    }
}