import React from "react";
import { useAnyClientAlias } from "../../state/swr/clients/useAnyClientAlias";
import { IClient } from "../../types/client.schema";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import Typography from "../text/Typography";
import UserRoleIndicator from "../user/RoleBanner";
import ClientAddresseeItem from "./ClientAddresseeItem";
import ClientDocumentClearanceReminderForm from "./ClientDocumentClearanceReminderForm";
import ClientEmployeeResponsibilityList from "./ClientEmployeeResponsibilityList";
import ClientTypeBanner from "./ClientTypeBanner";
import UpdateGeneralClientManagerForm from "./UpdateClientForm";

export default function ClientListItem({client}: {client: IClient}) {

    const { clientAlias, loadingClientAlias, reloadClientAlias } = useAnyClientAlias(client._id);

    return (
        <TableRow>
            <TableCell>
                <ClientTypeBanner alwaysShowClientType showClientName client={client} />
            </TableCell>
            <TableCell>
                <Flex row>
                    <ClientAddresseeItem addresseeId={client.naturalPerson} client={client} />
                    <ClientAddresseeItem addresseeId={client.legalPerson} client={client} />
                </Flex>
            </TableCell>
            <TableCell>
                {
                    client.clientManager 
                    ? <UserRoleIndicator user={client.clientManager} displayNameAsValue />
                    : <span>-</span>
                }
            </TableCell>
            <TableCell>
                <ClientEmployeeResponsibilityList client={client} />
            </TableCell>
            <TableCell>
                <Flex gap={1}>
                    {
                        loadingClientAlias 
                        ? <LoadingSpinner />
                        : (
                            clientAlias && clientAlias.length
                            ? clientAlias.map(a => <Typography key={a.aliasMailAddress}>{a.aliasMailAddress}</Typography>)
                            : <span>-</span>
                        )
                    }
                </Flex>
            </TableCell>
            <TableCell>
                <Flex row wrap>
                    <ClientDocumentClearanceReminderForm client={client} />
                    <UpdateGeneralClientManagerForm client={client} />
                </Flex>
            </TableCell>
        </TableRow>
    )
}