import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IFeature } from "../../../types/ApiTypes";

export const useFeatures = () => {
    const { data, isLoading, mutate } = useSWR<Array<IFeature>>(ApiRoutes.Subscriptions.FeaturesGetAll);

    return {
        features: data,
        loadingFeatures: isLoading,
        reloadFeatures: mutate
    }
}