import React from "react";
import Flex from "../container/Flex";
import Typography from "../text/Typography";
import Card from "../card/Card";
import LoadingSpinner from "../loader/LoadingSpinner";
import { AppColor } from "../../app/AppStyles";
import { IUserStatisticsCounts, IUserStatisticsDocument } from "../../types/ApiTypes";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../config/AppRoutes";
import { TicketSubcategory } from "../../hooks/useFilteredTickets";
import Icon from "../icons/Icon";
import ResetStatisticsButton from "./ResetStatisticsButton";
import { TicketProductivityTab } from "./ProductivityOverview";
import { useUser } from "../../state/swr/user/useUser";
import { getId } from "../../util/mongoUtil";

export interface ITicketStatsCardGridProps {
    cardColor?: AppColor, 
    filterComponent?: React.ReactNode, 
    statistics: IUserStatisticsDocument | undefined, 
    isLoading: boolean, 
    title: string,
    isPersonal?: boolean,
    resetTarget: string,
    currentTab: TicketProductivityTab
}

export default function TicketStatsCardGrid({statistics, isPersonal, currentTab, resetTarget, isLoading, title, cardColor = "muted", filterComponent}: ITicketStatsCardGridProps) {

    const {
        user
    } = useUser();

    const navigate = useNavigate();

    const getLink = (category: TicketSubcategory) => {
        switch (currentTab) {
            case TicketProductivityTab.Personal: 
                
                if (getId(user) === resetTarget) return `${AppRoutes.Tickets.path}?show=mine&view=grid&subcategory=${category}`;
                return `${AppRoutes.Tickets.path}?show=all&view=grid&subcategory=${category}&assignee=${resetTarget}`;

            default: return "";
        }
    }
    const getStatsCard = (title: string, key: keyof IUserStatisticsCounts, category: TicketSubcategory) => {

        const value = statistics?.tickets?.counts?.[key] ?? 0;

        return (
            <Card 
                wrapperClass="w-100" 
                color={cardColor} 
                header={
                    <Flex row justify="between" className="w-100">
                        <Typography basedOnThisBackground={cardColor} bold>{title}</Typography>
                        {
                            isPersonal && category && <Icon invertThisColor={cardColor} icon="box-arrow-up-right" />
                        }
                    </Flex>
                }
                onClick={isPersonal && category ? () => navigate(getLink(category)) : undefined}
            >
                {
                    isLoading
                    ? <LoadingSpinner size={50} />
                    : (
                        <Flex row align="end">
                            <Typography size={50} lineHeight={45} basedOnThisBackground={cardColor} >{value}</Typography>
                            <Typography size={20} basedOnThisBackground={cardColor} >{value === 1 ? "Ticket" : "Tickets"}</Typography>
                        </Flex>
                    )
                }
            </Card>
        )
    }

    return (
        <Flex className="w-100">
            <Flex className="w-100" row justify="between">
                <Typography color="primary"  size={20}>{title}</Typography>
                <Flex row>
                    <ResetStatisticsButton 
                        all={currentTab === TicketProductivityTab.Tenant}
                        team={currentTab === TicketProductivityTab.Team ? resetTarget : undefined}
                        user={currentTab === TicketProductivityTab.Personal ? resetTarget : undefined}
                    />
                    {filterComponent}
                </Flex>
            </Flex>
            <div className="w-100 own-statistics-grid">
                { getStatsCard("Unbeantwortete Tickets", "unansweredTickets", TicketSubcategory.Unanswered ) }
                { getStatsCard("Offene Tickets", "openTicketsCurrently", TicketSubcategory.All)}
                { getStatsCard("Geschlossene Tickets letzte 7 Tage", "closedTicketsPastWeek", TicketSubcategory.ClosedPastWeek) }
                { getStatsCard("Tickets in Eskalation", "ticketsInEscalation", TicketSubcategory.Escalated) }
                { getStatsCard("Tickets in Wiedervorlage", "ticketsInResubmission", TicketSubcategory.Resubmission) }
            </div>
        </Flex>
    )
}