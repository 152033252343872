import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IUser } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useClientUsers = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IUser>>(ApiRoutes.Clients.ClientContacts.getValue({param: RouteParam.ClientId, value: id}), { 
        refetchOnWindowFocus: false 
    });
    
    return {
        clientUsers: data,
        loadingClientUsers: isLoading,
        reloadClientUsers: mutate
    }
}