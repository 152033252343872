import React, { useState, useEffect } from "react";
import { isExportAssignment, isReturnStatement, readConfigFile } from "typescript";
import { string } from "yup";
import { generateClassName } from "../../hooks/useAttributes";
import IElementProps from "../../types/ElementProps";
import "./SearchableComboBox.css";
import Typography from "../text/Typography";
import Expandable from "./Expandable";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import InfiniteScroll from "../infiniteScroll/InfiniteScroll";
import ComboBoxItem from "./ComboBoxItem";
import Icon from "../icons/Icon";

interface ISearchableComboBoxProps<T> extends IElementProps {
    values: T[],
    expander?: React.ReactElement,
    bold?: boolean,
    label?: string,
    value?: T | string,
    resetValueAfterClick?: boolean,
    useDefaultValue?: boolean,
    loading?: boolean,
    resetValueOnClick?: boolean,
    dontCloseAfterClick?: boolean,
    readOnly?: boolean,
    disabled?: boolean,
    placeholder?: string,
    renderItemLikeValue?: boolean,
    noValuesPlaceholder?: string,
    renderValue?: (item: T) => React.ReactElement,
    renderItem?: (item: T) => React.ReactElement,
    filterFunction?: (item: T, filter: string) => boolean,
    clearValue?: () => void,
    itemToId: (value: T) => string,
    itemToString: (value: T) => string,
    onItemClick: (value?: T) => void
}

export default function SearchableComboBox<T>({
    className, 
    useDefaultValue = false, 
    renderItemLikeValue = false, 
    values, 
    label, 
    bold, 
    readOnly, 
    value, 
    placeholder = "", 
    filterFunction, 
    disabled, 
    loading,
    itemToId, 
    clearValue, 
    renderItem, 
    renderValue, 
    noValuesPlaceholder, 
    expander,
    resetValueAfterClick, 
    resetValueOnClick = true, 
    dontCloseAfterClick, 
    itemToString, 
    onItemClick
}: ISearchableComboBoxProps<T>) {

    const [filterHasFocus, setFilterHasFocus] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");
    const [availableValues, setAvailableValues] = useState<Array<T>>([]);
    const [filteredValues, setFilteredValues] = useState<Array<T>>([]);
    
    const inputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        setAvailableValues(values);
        if (!useDefaultValue) return;
        if (value) return;
        onItemClick(values[0]);
    }, [values]);

    const filterToUpper = (filter ?? "").toUpperCase().trim();

    const checkItemFilterMatch = (v: T) => {
        if (filterFunction) return filterFunction(v, filterToUpper);
        const itemString = itemToString(v);
        if (!itemString) return false;
        const itemStringUpper = itemString.toUpperCase().trim();
        return itemStringUpper.includes(filterToUpper);
    };
    
    useEffect(() => {
        
        if (!availableValues || !availableValues.length) return;
        
        if (!filter) {
            setFilteredValues(availableValues);
            return;
        }
        
        const filteredItems = values.filter(checkItemFilterMatch);
        setFilteredValues(filteredItems);
        
    }, [filter, availableValues]);
    
    const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setFilter(e.target.value);
    }   

    const onFilterFocus = () => {
        setFilter("");
        setFilterHasFocus(true);
    }

    const onFilterBlur = () => {
        setFilterHasFocus(false);
     }


    const isEmpty = !values || !values.length;
    const noResults = !filteredValues || !filteredValues.length;
    
    const realValue = value ? (typeof value === "string" ? values.find((v: T) => itemToId(v) === value as string) : value) : undefined;

    const showRenderedValue = !!realValue && itemToId(realValue) && !!renderValue;

    const displayNameClass = "searchable-combo-box-value-container form-control";

    const inputClass = generateClassName(displayNameClass, "searchable-combo-box-input", {
        base: "searchable-combo-box-input-",
        value: filteredValues && !!filteredValues.length, 
        onTrue: "expanded",     
        standard: "collapsed"
    }, {
        value: !isEmpty && noResults,
        onTrue: "searchable-combo-box-no-results"
    }, {
        value: showRenderedValue,
        base: "searchable-combo-box-input-",
        onTrue: "hidden",
        standard: "visible"
    });

    const itemsContainerClass = generateClassName("searchable-combo-box-item-container w-100 h-100", {
        value: filteredValues && !!filteredValues.length,
        onTrue: "searchable-combo-box-item-container-with-values"
    });

    const inputContainerClass = generateClassName("d-flex flex-row align-items-center p-2 searchable-combo-box-input-container", {
        base: "searchable-combo-box-input-container-input-",
        value: showRenderedValue,
        onTrue: "hidden",
        standard: "visible"
    })

    const comboBoxContainerClass = generateClassName("position-relative gap-1 d-flex flex-column", className);

    const getRenderedValue = (t: T) => {
        if (!renderValue || !renderItemLikeValue) {
            if (!renderItem) return <Typography className="ps-1 pe-1">{itemToString(t)}</Typography>;
            return renderItem(t);
        }

        return renderValue(t);
    }

    return (
        <div className={comboBoxContainerClass}>
            {
                label && <Typography noLinePadding bold={bold} color="primary">{label}</Typography>
            }
            <Expandable
                keepOpen={filterHasFocus}
                disabled={disabled || isEmpty || readOnly || loading }
                expander={expander ?? (
                    <Button 
                        icon={value ? "check" : "search"} 
                        iconPosition="start" 
                        iconSize={14}
                        variant="subtle"
                        color="primary"
                        loading={loading}
                    >
                        { realValue && itemToString(realValue) || (placeholder || "Auswählen...") }
                    </Button>
                )}
            >
                {
                    close => (
                        <Flex gap={3} className="w-100 h-100">
                            <div className={inputContainerClass}>
                                <input 
                                    ref={inputRef} 
                                    disabled={disabled || isEmpty || readOnly} 
                                    readOnly={readOnly} 
                                    className={inputClass} 
                                    onBlur={onFilterBlur} 
                                    onFocus={onFilterFocus} 
                                    onChange={onFilterChange} 
                                    value={filter} 
                                    placeholder={isEmpty ? (noValuesPlaceholder || "Keine Elemente") : (placeholder || "Suchen...")}
                                />
                                { value && (
                                    <Button 
                                        onClick={async () => { 
                                            if (clearValue) clearValue();
                                            else onItemClick(undefined);
                                            setFilter("");
                                        }} 
                                        icon="x" 
                                        color="error" 
                                        variant="text" 
                                    /> 
                                )}
                            </div>
                            <div className={itemsContainerClass}>
                                <Flex className="searchable-combo-box-items w-100">
                                    <InfiniteScroll>
                                        {
                                            filteredValues && !!filteredValues.length 
                                            ? filteredValues.map((v: T) => (
                                                <ComboBoxItem 
                                                    key={itemToId(v)} 
                                                    renderItem={getRenderedValue} 
                                                    getTitleFromItem={itemToString} 
                                                    item={v} 
                                                    onClick={(value: T) => {
                                                        onItemClick(value);
                                                        setFilter("");
                                                        close();
                                                    }} 
                                                />
                                            ))
                                            : <div className="ps-3">Keine Ergebnisse</div>
                                        }
                                    </InfiniteScroll>
                                </Flex>
                            </div>
                        </Flex>
                    )
                }
            </Expandable>
        </div>
    )
}