import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ticketUpdateAccessHistory } from "../../api/Api";
import Button from "../../components/buttons/Button";
import Flex from "../../components/container/Flex";
import Icon from "../../components/icons/Icon";
import PageHeader from "../../components/label/PageHeader";
import PageLoader from "../../components/loader/PageLoader";
import Typography from "../../components/text/Typography";
import TicketFriendlyIdBanner from "../../components/ticketing/banner/TicketFriendlyIdBanner";
import NextTicketButton from "../../components/ticketing/lists/NextTicketButton";
import TicketChatSidebar from "../../components/ticketing/sidebar/TicketChatSidebar";
import TicketChat from "../../components/ticketing/ticketChat/TicketChat";
import { AppRouteParams } from "../../config/AppRoutes";
import useFilteredTickets from "../../hooks/useFilteredTickets";
import Page from "../../layout/Page";
import { useTicket } from "../../state/swr/tickets/useTicket";
import { useCurrentTenantRole } from "../../state/swr/user/useCurrentTenantRole";

export default function Ticket() {
    
    const ticketId = AppRouteParams.TicketId.getValue(useParams());

    const ticketAccessHistoryInterval = React.useRef<any>();

    const { query } = useFilteredTickets();
    const { ticket, loadingTicket } = useTicket(ticketId || "");
    
    const { role } = useCurrentTenantRole();
    const navigate = useNavigate();

    const backLink = `/?${query.toString()}`;
    const goBack = () => navigate(backLink);

    React.useEffect(() => {
        clearInterval(ticketAccessHistoryInterval.current);

        if (!ticketId) return;
        if (!ticket) return;

        ticketAccessHistoryInterval.current = setInterval(() => ticketUpdateAccessHistory({ticketId: ticket._id}).catch(err => {}), 1000 * 60);

        return () => clearInterval(ticketAccessHistoryInterval.current);
    }, [ticket, ticketId]);
    
    return (
        <Page rightSidebar={(role && role.isClient) || !ticket ? undefined : <TicketChatSidebar ticket={ticket.ticket} />}>
            {
                loadingTicket
                ? <PageLoader />
                : (
                    ticket 
                    ? (
                        <>
                            <Flex row className="w-100" justify="between">
                                <Flex row align="start">
                                    <Icon icon="chevron-left" color="primary" className="mt-2" size={30} onClick={goBack} />
                                    <PageHeader text={ticket.ticket?.subject} />
                                </Flex>
                                <Flex row> 
                                    <TicketFriendlyIdBanner ticket={ticket.ticket} size={14} bold />
                                    <NextTicketButton ticket={ticket.ticket} />
                                </Flex>
                            </Flex>
                            <TicketChat hydratedTicket={ticket} />
                        </>
                    )
                    : (
                        <Flex fullWidth fullHeight align="center">
                            <Typography color="primary" size={24} bold>Ticket nicht verfügbar</Typography>
                            <Typography wrap size={12}>Es ist ein Fehler beim Laden des Tickets aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.</Typography>
                            <Button to={backLink} color="primary" className="mt-3">Zurück zur Übersicht</Button>
                        </Flex>
                    )
                )
            }
        </Page>
    )
}