import React from "react";
import { useAllUserStatistics } from "../../state/swr/statistics/useAllUserStatistics";
import { useTenantUserStatistics } from "../../state/swr/statistics/useTenantUserStatistics";
import Flex from "../container/Flex";
import WithPermissions from "../permissions/WithPermissions";
import { TicketProductivityTab } from "./ProductivityOverview";
import TicketStatsCardGrid from "./TicketStatsCardGrid";
import TicketStatsPieGrid from "./TicketStatsPieGrid";

export default function TenantProductivityOverview() {

    const { loadingTenantUserStatistics, reloadTenantUserStatistics, tenantUserStatistics  } = useTenantUserStatistics();
    const { allUserStatistics, loadingAllUserStatistics } = useAllUserStatistics();
    
    return (
        <WithPermissions permissions={[ "statistics.tenant.read" ]}>
            <Flex className="w-100">
                <TicketStatsCardGrid 
                    currentTab={TicketProductivityTab.Tenant}
                    resetTarget="tenant"
                    title="Performance deines Tenants" 
                    cardColor="secondary" 
                    isLoading={loadingTenantUserStatistics} 
                    statistics={tenantUserStatistics} 
                />
                <TicketStatsPieGrid title="Übersicht nach Bearbeiter" cardColor="secondary" isLoading={loadingAllUserStatistics} statistics={allUserStatistics || []} />
            </Flex>
        </WithPermissions>
    )
}