import React from "react";
import useUserUtil from "../../hooks/useUserUtil";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import { useRoles } from "../../state/swr/roles/useRoles";
import { IAddressee } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import AddresseeListItem from "./AddresseeListItem";
import { useAddresseeRelationships } from "../../state/swr/addressees/useAddresseeRelationships";
import { useUsers } from "../../state/swr/user/useUsers";

export default function AddresseeList() {

    const { addressees, loadingAddressees } = useAddressees();

    const {
        addresseeRelationshipsById
    } = useAddresseeRelationships();

    const {
        usersById
    } = useUsers();

    const {
        getName
    } = useUserUtil();

    return (
        <WithPermissions permissions={["addressees.all.read"]} >
            {
                loadingAddressees
                ? <LoadingSpinner centered={false} />
                :  (
                    !addressees || !addressees.length
                    ? <span>Keine Addressaten.</span>
                    :  (
                        <Table 
                            border 
                            className="w-100" 
                            headers={[
                                { 
                                    label: "Name",
                                    filterItem: (a, filter) => {
                                        if (a.actualName && a.actualName.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (!a.addresses || !a.addresses.length) return false;
                                        
                                        const address = a.addresses[0];
                
                                        if (!address) return false;
                                    
                                        if (address.post_office_box && address.post_office_box.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (address.street && address.street.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (address.postal_code && address.postal_code.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (address.city && address.city.toLowerCase().includes(filter.toLowerCase())) return true;
                
                                        return false;
                                    }
                                }, 
                                {
                                    label: "Beziehungen",
                                    permissions: [ "addresseeRelationships.all.read" ],
                                    filterItem: (a, filter) => {
                                        if (!a.relationships || !a.relationships.length) return false;
                
                                        const relationshipValues = a.relationships.map(id => {
                                            const r = addresseeRelationshipsById[id];

                                            if (!r) return null;
                                            if (r.has_addressee_id !== a.id && r.is_addressee_id !== a.id) return null;
                                            const isHasRelationship = r.has_addressee_id === a.id;
                                            return isHasRelationship ? r.is_addressee_display_name : r.has_addressee_display_name;
                                        });
                
                                        return relationshipValues.some(r => r && r.toLowerCase().includes(filter.toLowerCase()));
                                    }
                                }, 
                                { label: "Mandanten" },  
                                { 
                                    label: "Verknüpfter Nutzer",
                                    filterItem: (a, filter) => {
                                        if (!a.user) return false;
                                        
                                        const u = usersById[a.user];

                                        if (!u) return false;

                                        const name = getName(u);
                
                                        if (name && name.toLowerCase().includes(filter.toLowerCase())) return true;
                                        if (u.mailAddress && u.mailAddress.toLowerCase().includes(filter.toLowerCase())) return true;
                
                                        return false;
                                    }
                                }
                            ]}
                            items={addressees}
                            renderItem={(a: IAddressee) => <AddresseeListItem key={a._id} addressee={a} />}
                        />
                    )
                )
            }
        </WithPermissions>
    )

    
}