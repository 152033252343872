import React from "react";
import { useSearchParams } from "react-router-dom";
import { SortDirection } from "../components/buttons/SortDirectionToggle";
import { SelectItem } from "../components/comboBox/Select";
import { ITabData } from "../components/tabswitcher/TabSwitcher";
import { useTeams } from "../state/swr/teams/useTeams";
import { useCurrentTenantRole } from "../state/swr/user/useCurrentTenantRole";
import { useUser } from "../state/swr/user/useUser";
import { UserRole } from "../types/ApiTypes";
import useFilterQuery from "./useFilterQuery";

export enum TicketSort {
    CreatedAt = "created",
    UpdatedAt = "updated",
    Status = "status",
    Subject = "subject",
    Type = "type"
}

export enum TicketSubcategory {
    All = "all",
    WaitingForClient = "waitingForClient",
    ClosedPastWeek = "closedPastWeek",
    Escalated = "escalated",
    Unanswered = "unanswered",
    Intern = "internal",
    External = "external",
    Resubmission = "resubmission"
}

export enum TicketNotAssignedType {
    Assignee = "assignee",
    ClientContact = "clientContact",
    Client = "client",
    Team = "team"
}

export enum TicketFilter {
    NotAssigned = "na",
    ForMe = "mine",
    MyTeam = "team",
    All = "all",
    Closed = "closed",
    Archive = "archived"
}

export interface ITicketFilter {
    show?: TicketFilter,
    client?: string,
    subcategory?: TicketSubcategory,
    team?: string,
    text?: string,
    assignee?: string,
    sort?: TicketSort,
    notAssignedType?: TicketNotAssignedType,
    direction?: SortDirection,
    max?: number
}

export interface ITicketFilterUpdate extends ITicketFilter {
    view?: TicketView,
}

export enum TicketView {
    List = "list",
    Grid = "grid"
}

export default function useFilteredTickets() {

    const [notAssignedType, setNotAssignedType] = React.useState<TicketNotAssignedType>(TicketNotAssignedType.Assignee);
    const [subcategory, setSubcategory] = React.useState<TicketSubcategory>(TicketSubcategory.All);
    const [view, setView] = React.useState<TicketView>(TicketView.Grid);
    const [team, setTeam] = React.useState<string>();
    const [client, setClient] = React.useState<string>();
    const [assignee, setAssignee] = React.useState<string>();
    const [filterText, setFilterText] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [sort, setSort] = React.useState<TicketSort | undefined>(TicketSort.UpdatedAt)
    const [direction, setDirection] = React.useState<SortDirection>(SortDirection.Descending);
    const [filter, setFilter] = React.useState<TicketFilter>(TicketFilter.ForMe);

    const { updateQueryFromFilter } = useFilterQuery();

    const [query, setQuery] = useSearchParams();

    const {
        user
    } = useUser();

    const { role } = useCurrentTenantRole();

    const isClient = !!role && role.isClient;

    const { loadingTeams } = useTeams();

    const qv = <T>(key: keyof ITicketFilterUpdate, defaultValue: T): T => {
        const v  = query.get(key);
        if (!v) return defaultValue;
        return v as T;
    }

    React.useEffect(() => {
        if (!query) return;

        setSubcategory(qv<TicketSubcategory>("subcategory", TicketSubcategory.All));
        setFilter(qv<TicketFilter>("show", TicketFilter.ForMe));
        setSort(qv<TicketSort>("sort", TicketSort.UpdatedAt));
        setDirection(qv<SortDirection>("direction", SortDirection.Descending));
        setNotAssignedType(qv<TicketNotAssignedType>("notAssignedType", TicketNotAssignedType.Assignee));
        setFilterText(qv<string>("text", ""));
        setAssignee(qv<string>("assignee", ""));
        setTeam(qv<string>("team", ""));
        setView(qv<TicketView>("view", TicketView.Grid));
        setClient(qv<string>("client", ""));

    }, [query]);
    
    React.useEffect(() => {
        if (filter === TicketFilter.MyTeam) return;
        else setTeam(undefined);
    }, [filter]);
    
    React.useEffect(() => {
        setLoading(loadingTeams);
    }, [loadingTeams]);
    
    const updateFilter = (update: ITicketFilterUpdate) => {
        const result: ITicketFilterUpdate = {
            direction: update.direction || SortDirection.Descending,
            show: update.show || filter,
            assignee: update.assignee || "",
            client: update.client || "",
            team: update.team || "",
            sort: update.sort || sort,
            text: update.text !== undefined ? update.text : filterText,
            notAssignedType: update.notAssignedType || notAssignedType,
            view: update.view || view,
            subcategory: update.subcategory || subcategory
        };

        updateQueryFromFilter(result);
    }

    const getStatisticsTabs = (): Array<ITabData> => {

        if (role && role.isClient) return [];

        const result: Array<ITabData> = [];

        result.push({data: TicketSubcategory.All, label: "Alle"});
        result.push({data: TicketSubcategory.Unanswered, label: "Unbeantwortet", permissions: [ "statistics.own.read" ]});
        result.push({data: TicketSubcategory.WaitingForClient, label: "Auf Mandant warten", permissions: [ "statistics.own.read" ]});
        result.push({data: TicketSubcategory.Escalated, label: "Eskaliert", permissions: [ "statistics.own.read" ]});
        
        if (filter !== TicketFilter.ForMe) {
            result.push({data: TicketSubcategory.External, label: "Extern", permissions: [ "tickets.external.read" ]});
            result.push({data: TicketSubcategory.Intern, label: "Intern", permissions: [ "tickets.internal.read" ]});
        }

        result.push({data: TicketSubcategory.Resubmission, label: "In Wiedervorlage", permissions: [ "statistics.own.read" ]});

        if (filter === TicketFilter.ForMe) result.push({data: TicketSubcategory.ClosedPastWeek, label: "Abgeschlossen aktuelle Woche", permissions: [ "statistics.own.read" ]});

        return result;
    }

    const getFilterTabs = (): Array<ITabData> => {
        const result: Array<ITabData> = [];

        result.push({data: TicketFilter.Closed, label: "Geschlossen", priority: 2});
        result.push({data: TicketFilter.Archive, label: "Archiv", priority: 2});
        result.push({data: TicketFilter.NotAssigned, permissions: [ "tickets.unassigned.read" ], label: "Ohne Zuweisung", first: true, priority: 1, hidden: !user?.isSuperAdmin && (!role || (role.displayName === UserRole.Clerk || role.displayName === UserRole.Client))});
        result.push({data: TicketFilter.ForMe, first: true, priority: 1, label: isClient ? "Offen" : "Für Mich"});
        result.push({data: TicketFilter.MyTeam, hidden: isClient, priority: 1, label: "Team"});
        result.push({data: TicketFilter.All, label: "Alle", hidden: isClient, priority: 1});

        return result;
    }

    const getNotAssignedTypeTabs = (): Array<ITabData> => {
        const result: Array<ITabData> = [];

        result.push({data: TicketNotAssignedType.Assignee, permissions: [ "tickets.unassigned.read" ], label: "Kein Bearbeiter"});
        result.push({data: TicketNotAssignedType.ClientContact, permissions: [ "tickets.unassigned.read" ], label: "Kein Mandantenkontakt"});
        result.push({data: TicketNotAssignedType.Client, permissions: [ "tickets.unassigned.read" ], label: "Kein Mandant"});
        result.push({data: TicketNotAssignedType.Team, permissions: [ "tickets.unassigned.read" ], label: "Kein Team"});

        return result;
    }

    const getViewTabs = (): Array<ITabData> => [
        {
            icon: "grid-fill",
            data: TicketView.Grid
        },
        {
            icon: "list",
            data: TicketView.List,
            iconSize: 28
        }
    ]

    const getSortOptions = (): Array<SelectItem<string>> => {
        const result: Array<SelectItem<string>> = [];

        if (!isClient) result.push({ label: "Typ", data: TicketSort.Type });

        result.push({ label: "Erstellt am", data: TicketSort.CreatedAt });
        result.push({ label: "Status", data: TicketSort.Status });
        result.push({ label: "Betreff", data: TicketSort.Subject });
        result.push({ label: "Aktualisiert am", data: TicketSort.UpdatedAt });
        
        return result;
    }

    const getFilterForRoute = (options?: ITicketFilter): ITicketFilter => {
        const result = {
            direction: direction || SortDirection.Descending,
            show: filter,
            client: client,
            notAssignedType: notAssignedType,
            assignee: assignee,
            subcategory: subcategory,
            sort: sort,
            max: options?.max || 100,
            team: team,
            text: filterText
        }

        if (!options || typeof options !== "object") return result;

        return {
            ...result,
            ...options
        };
    }

    return {
        subcategory,
        getStatisticsTabs,
        getSortOptions,
        loading,
        filter,
        client,
        sort,
        assignee,
        team,
        view,
        filterText,
        getViewTabs,
        direction,
        updateFilter,
        getFilterTabs,
        getNotAssignedTypeTabs,
        query,
        isShowingClosedTickets: filter === TicketFilter.Closed || filter === TicketFilter.Archive,
        getFilterForRoute: getFilterForRoute
    }
}
