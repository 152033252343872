import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IDocumentClearanceSettings } from "../../../types/ApiTypes";

export const useClientDocumentClearanceSettings = (id: string) => {
    const { data, isLoading, mutate } = useSWR<IDocumentClearanceSettings>(ApiRoutes.Clients.DocumentClearance.getValue({param: RouteParam.ClientId, value: id}), {
        revalidateOnMount: false
    });

    return {
        documentClearanceSettings: data,
        loadingDocumentClearanceSettings: isLoading,
        reloadDocumentClearanceSettings: mutate
    }
}