import React from "react";
import Page from "../../layout/Page";
import UpdateMailTemplateButton from "../../components/templates/UpdateMailTemplateButton";
import Flex from "../../components/container/Flex";
import PageHeader from "../../components/label/PageHeader";
import useTenantSettings from "../../hooks/useTenantSettings";
import { useUser } from "../../state/swr/user/useUser";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import TenantMailTemplatesList from "../../components/templates/TenantMailTemplatesList";
import GlobalMailTemplatesList from "../../components/templates/GlobalMailTemplatesList";
import WithPermissions from "../../components/permissions/WithPermissions";
import SalutationsList from "../../components/salutations/SalutationsList";
import UpdateSalutationDialog from "../../components/salutations/UpdateSalutationDialog";

export enum MailTemplatePage {
    Templates = "templates",
    Salutations = "salutations"
}

export enum MailTemplateTab {
    Tenant = "tenant",
    Global = "global"
}


export default function MailTemplatesPage() {
    const { titles } = useTenantSettings(true);

    const [page, setPage] = React.useState<MailTemplatePage>(MailTemplatePage.Templates);
    const [tab, setTab] = React.useState<MailTemplateTab>(MailTemplateTab.Tenant);

    const { user } = useUser();
    
    const isGlobal = tab === MailTemplateTab.Global && !!user?.isSuperAdmin;

    const getContent = () => {

        switch (page) {
            case MailTemplatePage.Salutations: return <SalutationsList isGlobal={isGlobal} />
            case MailTemplatePage.Templates: return (
                isGlobal
                ? <GlobalMailTemplatesList />
                : <TenantMailTemplatesList />
            )
        }
    }

    return (
        <Page 
            header="Mailvorlagen & Anreden"
            actionButtons={[
                page === MailTemplatePage.Salutations
                ? <UpdateSalutationDialog key="update-salutation-dialog" isGlobal={isGlobal} />
                : (
                    tab === MailTemplateTab.Tenant && <UpdateMailTemplateButton key="update-mail-template-dialog" isCreate />
                )
            ]}
        >
            <WithPermissions superAdminOnly>
                <Flex gap="2">
                    <TabSwitcher
                        tabQueryParamKey="show"
                        tabs={[
                            {
                                data: MailTemplateTab.Tenant,
                                label: `${titles?.tenantWord || "Tenant"}-Vorlagen`,
                                first: true
                            },
                            {
                                data: MailTemplateTab.Global,
                                label: "Globale Vorlagen"
                            }
                        ]}
                        saveActiveTab={(t) => setTab(t as MailTemplateTab)}
                    />
                    <TabSwitcher
                        size="small"
                        variant="muted"
                        tabQueryParamKey="page"
                        tabs={[
                            {
                                data: MailTemplatePage.Templates,
                                label: "Mails"
                            },
                            {
                                data: MailTemplatePage.Salutations,
                                label: "Anreden"
                            }
                        ]}
                        saveActiveTab={(t) => setPage(t as MailTemplatePage)}
                    />
                </Flex>
            </WithPermissions>
            {
                getContent()
            }
        </Page>
    )
}