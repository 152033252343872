import React from "react";
import { officeAccountsUpdate } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import { useOfficeAccounts } from "../../../state/swr/office/useOfficeAccounts";
import { useTeams } from "../../../state/swr/teams/useTeams";
import { ITeam, IUser } from "../../../types/ApiTypes";
import { IOfficeAccount, IOfficeAccountDocument, NewTicketMailBehaviour } from "../../../types/officeAccount.types";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import SearchableComboBox from "../../comboBox/SearchableComboBox";
import Select from "../../comboBox/Select";
import Flex from "../../container/Flex";
import CheckBox from "../../formik/CheckBox";
import LoadingSpinner from "../../loader/LoadingSpinner";
import ModalForm from "../../modal/ModalForm";
import WithPermissions from "../../permissions/WithPermissions";
import TabSwitcher from "../../tabswitcher/TabSwitcher";
import TeamBanner from "../../teams/TeamBanner";
import Typography from "../../text/Typography";
import UserPicker from "../../user/UserPicker";
import UserSelect from "../../user/UserSelect";
import Pill from "../../pills/Pill";
import Icon from "../../icons/Icon";

export interface IUpdateOfficeAccountFormProps {
    account: IOfficeAccount 
}

export enum UpdateOfficeAccountFormTab {
    Personal = "personal",
    Teams = "teams",
}

export default function UpdateOfficeAccountForm({account}: IUpdateOfficeAccountFormProps) {
    
    const {
        getAccountPermissions
    } = useInboxPermissions();

    const [tab, setTab] = React.useState<UpdateOfficeAccountFormTab>(UpdateOfficeAccountFormTab.Personal);

    const { loadingTeams, teams } = useTeams();
    const { reloadOfficeAccounts } = useOfficeAccounts();

    const callApi = useApi();

    const permissions = getAccountPermissions("update", account);

    return (
        <WithPermissions permissions={permissions}>
            <ModalForm 
                title="Konto ändern" 
                button={
                    <Button icon="pen">Ändern</Button>
                }
                enableReinitialize
                initialValues={{
                    assignments: {
                        team: account.assignments?.team,
                        personalUser: account.assignments?.personalUser
                    },
                    isAvailableForAllUsers: !!account.isAvailableForAllUsers,
                    isMainTenantAccount: !!account.isMainTenantAccount,
                    newTicketMailBehaviour: account.newTicketMailBehaviour ?? NewTicketMailBehaviour.CreateFromKnownMailAddresses,
                    availableForUsers: [],
                    autoAssignTicketMailsToEmployees: !!account.autoAssignTicketMailsToEmployees
                } as IOfficeAccountDocument}
                onSubmit={async (values) => {
                    const res = await callApi(officeAccountsUpdate(values, account._id));

                    if (!res) return false;

                    await reloadOfficeAccounts();
                    return true;
                }}
                sidebar={formik => <Button type="submit" disabled={formik.isSubmitting} icon="save" text="Speichern" loading={formik.isSubmitting}  />}
            >
                {
                    formik => {
                        const setAssignments = (user?: IUser, team?: ITeam) => {
                            formik.setFieldValue("assignments.team", team);
                            formik.setFieldValue("assignments.personalUser", user);
                        }

                        const toggleAssignmentsBasedOnMainAccount = () => {
                            if (formik.values.isMainTenantAccount) return;
                            setAssignments();
                        }

                        return (
                            <Flex gap={3} className={"w-100 " + (!formik.values.isMainTenantAccount ? "mb-5 pb-5" : "")}>
                                <Select
                                    onChange={v => formik.setFieldValue("newTicketMailBehaviour", v)}
                                    value={formik.values.newTicketMailBehaviour}
                                    label="Verhalten bei Mailerhalt"
                                    bold
                                    values={[
                                        {
                                            label: "Erstellen von Tickets aus bekannten Mailadressen",
                                            data: NewTicketMailBehaviour.CreateFromKnownMailAddresses
                                        },
                                        {
                                            label: "Erstellen von Tickets aus allen Mails",
                                            data: NewTicketMailBehaviour.CreateFromAllMailAddresses
                                        },
                                        {
                                            label: "Keine Tickets erstellen",
                                            data: NewTicketMailBehaviour.DoNotCreateTicket
                                        }
                                    ]}
                                />
                                <Flex gap="2">
                                    <CheckBox 
                                        name="autoAssignTicketMailsToEmployees"
                                        label="Tickets automatisch an verantwortlichen Mitarbeiter zuweisen"
                                    />
                                    {
                                        !formik.values.autoAssignTicketMailsToEmployees && (
                                            <Flex row gap="2">
                                                <Icon icon="exclamation-triangle" color="error" />
                                                <Typography size="12">Jedes Ticket muss bei Eingang einmal manuell zugewiesen werden.</Typography>
                                            </Flex>
                                        )
                                    }
                                </Flex>
                                <Flex>
                                    <Typography bold color="primary">Kanzleikonto / Hauptkonto</Typography>
                                    <CheckBox name="isMainTenantAccount" onChange={toggleAssignmentsBasedOnMainAccount} label="Zum Versenden von Kanzlei-Mails und Systemmails für Ihre Kanzlei verwenden" />
                                </Flex>
                                {
                                    !formik.values.isMainTenantAccount && (
                                        <Flex className="w-100" >
                                            <Typography bold color="primary">Zuweisungen</Typography>
                                            <Card
                                                header={
                                                    <TabSwitcher 
                                                        removeOnUnmount
                                                        saveActiveTab={t => setTab(t as any)}
                                                        size="tiny" 
                                                        tabs={[
                                                            {
                                                                data: UpdateOfficeAccountFormTab.Personal,
                                                                label: "Persönlich",
                                                                first: !!formik.values.assignments.personalUser ? true : undefined
                                                            },
                                                            {
                                                                data: UpdateOfficeAccountFormTab.Teams,
                                                                label: "Teams",
                                                                first: !!formik.values.assignments.team ? true : undefined
                                                            }
                                                        ]}
                                                        tabQueryParamKey="assign"
                                                    />
                                                }
                                            >

                                                {
                                                    (
                                                        () => {
                                                            switch (tab) {
                                                                case UpdateOfficeAccountFormTab.Personal: 
                                                                    return (
                                                                        <UserSelect
                                                                            className="w-100"
                                                                            value={formik.values.assignments.personalUser}
                                                                            onChange={u => setAssignments(u)}
                                                                            displayed="employees"
                                                                        />
                                                                    );
                                                                case UpdateOfficeAccountFormTab.Teams: 
                                                                        
                                                                    if (loadingTeams) return <LoadingSpinner />
                                                                    if (!teams) return <div>Keine Teams gefunden</div>
                                                                        
                                                                    return (
                                                                        <SearchableComboBox
                                                                            className="w-100"
                                                                            values={teams}
                                                                            value={formik.values.assignments.team}
                                                                            itemToId={t => t._id}
                                                                            itemToString={t => t.name}
                                                                            onItemClick={t => setAssignments(undefined, t)}
                                                                            renderItem={t => <TeamBanner team={t}/>}
                                                                        />
                                                                    );
                                                            }
                                                        }
                                                    )()
                                                }
                                            </Card>
                                        </Flex>
                                    )
                                }
                                {
                                    (formik.values.isMainTenantAccount || formik.values.assignments?.team) && (
                                        <Flex fullWidth>
                                            <Typography bold color="primary">Zugriff</Typography>
                                            <CheckBox 
                                                name="isAvailableForAllUsers"
                                                label="Für alle Benutzer mit allgemeinem Postfachzugriff verfügbar"
                                            />
                                            {
                                                !formik.values.isAvailableForAllUsers && (
                                                    <UserPicker
                                                        description="Die ausgewählten Nutzer erhalten Zugriff auf das Postfach, sofern die Rolle der Nutzer dies erlaubt."
                                                        bold
                                                        label="Nutzer mit Zugriff"
                                                        displayed="employees"
                                                        values={formik.values.availableForUsers ?? []}
                                                        saveValues={u => formik.setFieldValue("availableForUsers", u)}
                                                    />
                                                )
                                            }
                                        </Flex>
                                    )
                                }
                            </Flex>
                        )
                    }
                }
            </ModalForm>
        </WithPermissions>
    )   
}