import React from "react";
import { useNavigate } from "react-router-dom";
import useFilteredTickets from "../../../hooks/useFilteredTickets";
import useTicketUtil from "../../../hooks/useTicketUtil";
import useUserUtil from "../../../hooks/useUserUtil";
import { useClientContacts } from "../../../state/swr/clientContacts/useClientContacts";
import { useClients } from "../../../state/swr/clients/useClients";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { useUser } from "../../../state/swr/user/useUser";
import { ITicket } from "../../../types/ApiTypes";
import { IHydratedTicket } from "../../../types/ticket.types";
import { formatDateTiggiStyle } from "../../../util/formatter";
import { getDateInXFromNow } from "../../../util/util";
import Button from "../../buttons/Button";
import Card from "../../card/Card";
import ClientTypeBanner from "../../clients/ClientTypeBanner";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import TableCell from "../../tables/TableCell";
import TableRow from "../../tables/TableRow";
import TeamBanner from "../../teams/TeamBanner";
import Typography from "../../text/Typography";
import UserRoleIndicator from "../../user/RoleBanner";
import TicketLastReplyStateIcon from "../banner/TicketLastReplyStateIcon";
import TicketStateBanner from "../banner/TicketStateBanner";
import TicketTypeBanner from "../banner/TicketTypeBanner";
import TicketMessagesCount from "../TicketMessagesCount";
import TicketCardHeader from "./TicketCardHeader";
import "./TicketOverviewItem.css";

export interface ITicketOverviewItem {
    hydratedTicket: IHydratedTicket,
    renderAsTableRow?: boolean,
    isSelected: boolean,
    hasAnySelection: boolean,
    canSelect?: boolean,
    toggleTicketSelected: (ticket: ITicket) => void
}

export default function TicketOverviewItem({hydratedTicket, canSelect = true, hasAnySelection, renderAsTableRow, isSelected, toggleTicketSelected}: ITicketOverviewItem) {

    const {
        ticket,
        assignee,
        client,
        clientContact
    } = hydratedTicket;

    const [openOnClick, setOpenOnClick] = React.useState<boolean>(true);
    const [hover, setHover] = React.useState<boolean>(false);

    const {
        query
    } = useFilteredTickets();

    const {
        clientsById
    } = useClients();

    const {
        clientContactsById
    } = useClientContacts();

    const {
        getName
    } = useUserUtil();

    const {
        getDisplayId
    } = useTicketUtil();

    const { role } = useCurrentTenantRole();
    const { user } = useUser();
    
    const navigate = useNavigate();
    
    if (!ticket) return null;
    
    const isClient = !(role && !role.isClient) && !(!!user && user.isSuperAdmin);

    const select = canSelect && (
        <div className="d-flex flex-column check-box align-items-start p-2" onClick={() => toggleTicketSelected(ticket)}>
            <div className="d-flex flex-row align-items-center" >
                <input readOnly type="checkbox" style={{height: "16px", width: "16px"}} checked={isSelected} className="form-check-input m-0 p-0" />
            </div>
        </div>
    )

    const subject = isClient ? ticket.subject : (ticket.internalSubject ?? ticket.subject);

    const isNew = () => {
        if (!ticket) return false;
        if (!ticket.messages || !ticket.messages.length) return true;
        return new Date(ticket.lastUpdate || "") > getDateInXFromNow({hours: -1});
    }

    const lastMessage = (!!ticket?.messages?.length) ? ticket.messages[ticket.messages.length - 1] : null;
    const lastMessageText = (!!lastMessage?.content?.length) ? lastMessage.content[lastMessage.content.length - 1] : null;

    const openTicket = async () => {
        if (!openOnClick) return;
        const url = `/tickets/${ticket._id}?${query.toString()}`;
        navigate(url);
    }

    const ticketClient = !!client && clientsById[client];
    const ticketClientContact = !!clientContact && clientContactsById[clientContact];

    if (renderAsTableRow) return (
        <TableRow color={isNew() ? "error" : undefined}>
            <TableCell>
                { select }
            </TableCell>
            <TableCell>
                <Typography bold color="primary">{getDisplayId(ticket)}</Typography>
            </TableCell>
            <TableCell>
                <Flex gap={0} className="w-100" style={{maxWidth: "150px"}}>
                    <Typography bold className="w-100">{ subject }</Typography>
                    { lastMessageText && <Typography size={12} className="w-100"> { lastMessageText.text } </Typography>}
                </Flex>
            </TableCell>
            <TableCell>
                <TicketTypeBanner ticket={ticket}/>
            </TableCell>
            <TableCell>
                <TicketLastReplyStateIcon ticket={ticket} />
            </TableCell>
            {
                !isClient && (
                    <TableCell>
                        <TeamBanner team={ticket.team} />
                    </TableCell>
                )
            }
            <TableCell>
                <Flex gap={0}>
                    {
                        ticketClient && <Typography bold >{ticketClient.name}</Typography> 
                    }
                    {
                        ticketClientContact && <Typography size={12}>{getName(ticketClientContact)}</Typography> 
                    }
                </Flex>
            </TableCell>
            <TableCell>
                <Flex align="center">
                    <TicketMessagesCount ticket={ticket} />
                </Flex>
            </TableCell>
            <TableCell>
                <TicketStateBanner hydratedTicket={hydratedTicket} textOnly />
            </TableCell>
            <TableCell>
                <Typography size={12}>{formatDateTiggiStyle(ticket.lastUpdate)}</Typography>
            </TableCell>
            <TableCell>
                <Button variant="text" onClick={openTicket}>Öffnen</Button>
            </TableCell>
        </TableRow>
    );

    return (
        <Card   
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            color={isNew() ? "error" : "muted"}
            noPadding
            strongColor={hover}
            header={(
                <TicketCardHeader 
                    ticket={ticket} 
                    hover={hover} 
                    onHoverOverAction={() => setOpenOnClick(false)}
                    onStopHoverOverAction={() => setOpenOnClick(true)}
                />
            )}
            wrapperClass="gap-2 w-100 h-100" 
            disableHoverStyles
            onClick={openTicket} 
        >
            <Flex className="w-100 p-0" gap={4} >
                <Flex gap={1} className="w-100 p-3 overflow-hidden">
                    <Typography size={20} color="primary" bold>{ subject ? (subject.length > 30 ? subject.substring(0, 30) + "..." : subject) : "" }</Typography> 
                    <Flex row justify="between" className="w-100">
                        <Flex row wrap className="w-100">
                            <Typography size={14} bold color="primary" >{ticketClient ? ticketClient.name : "Kein Mandant"}</Typography> 
                            <Typography size={14} color="primary">{ticketClientContact ? getName(ticketClientContact) : "Kein Kontakt"}</Typography> 
                        </Flex>
                        <Icon size={20} icon="chevron-right" color="primary" />
                    </Flex>
                </Flex>
                <Flex row wrap className="w-100 ps-3 pb-3" style={{height: "fit-content"}}  align="start" justify="between" >
                    {
                        !isClient && (
                            <Flex align="start" justify="end" >
                                {
                                    assignee && <UserRoleIndicator user={assignee} displayNameAsValue showOnEmptyText="Kein Bearbeiter"/>
                                }
                                {
                                    client && <ClientTypeBanner client={client} showClientName textOnEmpty="Kein Mandant" />
                                }
                                <TeamBanner team={ticket.team} textOnEmpty="Kein Team"/>
                            </Flex>

                        )
                    }
                    <Flex justify="between" className="ms-auto" align="end">
                        <TicketMessagesCount ticket={ticket} wrapperClass="pe-3"/>
                        <TicketStateBanner hydratedTicket={hydratedTicket} />
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
}
