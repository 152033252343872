import React from "react";
import { globalMailTemplatesUpdate } from "../../api/Api";
import useApi from "../../hooks/useApi";
import { useGlobalMailTemplates } from "../../state/swr/templates/useGlobalMailTemplates";
import { useUser } from "../../state/swr/user/useUser";
import { IGlobalMailTemplate } from "../../types/globalMailTemplates.schema";
import { TemplatePlaceholder } from "../../types/templatePlaceholder.schema";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import CheckBox from "../formik/CheckBox";
import ModalForm from "../modal/ModalForm";
import Typography from "../text/Typography";
import TemplateElementEdit from "./TemplateElementEdit";
import TemplatePlaceholderChip from "./TemplatePlaceholderChip";
import TemplatePreview from "./TemplatePreview";
import { UpdateMailTemplateTab } from "./UpdateMailTemplateButton";
import TabSwitcher from "../tabswitcher/TabSwitcher";

export interface IUpdateGlobalMailTemplateButtonProps {
    template: IGlobalMailTemplate
}

export default function UpdateGlobalMailTemplateButton({template}: IUpdateGlobalMailTemplateButtonProps) {
    
    const [tab, setTab] = React.useState<UpdateMailTemplateTab>(UpdateMailTemplateTab.subject);
    
    const {
        user
    } = useUser();

    const { reloadGlobalMailTemplates } = useGlobalMailTemplates();

    const callApi = useApi();

    if (!template) return null;

    if (!user || !user.isSuperAdmin) return null;

    return (
        <ModalForm
            initialValues={{
                availablePlaceholders: template.availablePlaceholders || [],
                body: template.body || "",
                canBeCustomized: template.canBeCustomized || false,
                isPublished: template.isPublished || false,
                subject: template.subject || "",
                templateType: template.templateType || "",
                _id: template?._id
            } as IGlobalMailTemplate}
            enableReinitialize
            button={
                <Button text="Bearbeiten" variant="solid" icon="pen" />
            }
            title="Globale Vorlage bearbeiten"
            onSubmit={async (values) => {
                const res = await callApi(globalMailTemplatesUpdate(values));

                if (!res) return false;

                await reloadGlobalMailTemplates();
                return true;
            }}
            sidebar={
                (formik, close) => (
                    <Flex fullHeight fullWidth justify="between">
                        <Flex fullWidth>
                            <TemplatePreview content={formik.values.subject} label="Betreff" />
                            <TemplatePreview content={formik.values.body} label="Inhalt" />
                        </Flex>
                        <Flex fullWidth >
                            {
                                formik.values.availablePlaceholders && !!formik.values.availablePlaceholders.length && (
                                    <Flex>
                                        <Typography>Verfügbare Platzhalter</Typography>
                                        <Flex row wrap>
                                            {
                                                formik.values.availablePlaceholders.map(p => <TemplatePlaceholderChip placeholder={p} />)
                                            }
                                        </Flex>
                                    </Flex>
                                )
                            }
                            <Flex>
                                <Typography>Optionen</Typography>
                                <CheckBox label="Kann vom Tenant angepasst werden" name="canBeCustomized" />
                                <CheckBox label="Ist zur Verwendung veröffentlicht" name="isPublished" />
                            </Flex>
                            <Button type="submit" text="Speichern" icon="save" />
                        </Flex>
                    </Flex>
                )
            }
        >
            {
                formik => {

                    const addToAvailablePlaceholders = (placeholder: TemplatePlaceholder) => {
                        if (!formik.values.availablePlaceholders || !formik.values.availablePlaceholders.length) {
                            formik.setFieldValue("availablePlaceholders", [placeholder]);
                            return;
                        }

                        if (formik.values.availablePlaceholders.includes(placeholder)) return;

                        formik.setFieldValue("availablePlaceholders", [...formik.values.availablePlaceholders, placeholder]);
                    }

                    const removeFromAvailablePlaceholders = (placeholder?: TemplatePlaceholder) => {
                        if (!placeholder) return;
                        if (!formik.values.availablePlaceholders || !formik.values.availablePlaceholders.length) return;
                        formik.setFieldValue("availablePlaceholders", formik.values.availablePlaceholders.filter(p => p !== placeholder));
                    }

                    return (
                        <TemplateElementEdit 
                            name={tab === UpdateMailTemplateTab.subject ? "subject" : "body"}
                            label={(
                                <TabSwitcher
                                    tabQueryParamKey="content"
                                    tabs={[
                                        { data: UpdateMailTemplateTab.subject, label: "Betreff" },
                                        { data: UpdateMailTemplateTab.body, label: "Inhalt" }
                                    ]}
                                    saveActiveTab={setTab as any}
                                />
                            )}
                            onPlaceholderSelect={addToAvailablePlaceholders} 
                            onPlaceholderRemove={removeFromAvailablePlaceholders} 
                        />
                    )
                }
            }
        </ModalForm>
    )
}