import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IDatevEmployee } from "../../../types/employee.schema";
import { ICollection } from "../../../types/collection.types";

export const useDatevEmployees = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<IDatevEmployee>>(ApiRoutes.Employees.All);

    return {
        datevEmployees: data?.items ?? [],
        datevEmployeesById: data?.byId ?? {},
        loadingDatevEmployees: isLoading,
        reloadDatevEmployees: mutate
    }
}