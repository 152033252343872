import React from "react";
import Card from "../card/Card";
import Flex from "../container/Flex";
import CloudGatewaySetUp from "../datev/CloudGatewaySetUp";
import DownloadDatevExporter from "../datev/DownloadDatevExporter";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import Typography from "../text/Typography";
import CurrentClientImports from "./CurrentClientImports";
import ImportClientsForm from "./ImportClientsForm";
import "./ImportClientsForm.css";

export enum ClientImportTab {
    Options = "options",
    CurrentImports = "currentImports"
}

export default function ClientImport() {

    const [tab, setTab] = React.useState<ClientImportTab>(ClientImportTab.Options);

    return (
        <Flex fullWidth> 
            <TabSwitcher
                tabQueryParamKey="details"
                size="tiny"
                tabs={[
                    { data: ClientImportTab.Options, label: "Import-Einstellungen" },
                    { data: ClientImportTab.CurrentImports, label: "Aktuelle Importe" }
                ]}
                saveActiveTab={t => setTab(t as ClientImportTab)}
            />
            {
                tab === ClientImportTab.Options 
                ? (
                    <Flex fullWidth>
                        <Card 
                            color="muted"
                            className="w-100"
                            header={<Typography bold>Option 1: Über CloudGateway-Schnittstelle importieren</Typography>}
                        >
                            <CloudGatewaySetUp />
                        </Card>
                        <Card 
                            color="muted"
                            className="w-100"
                            header={<Typography bold>Option 2: Über Desktop-Client importieren</Typography>}
                        >
                            <DownloadDatevExporter />
                        </Card>
                        <Card 
                            color="muted"
                            className="w-100"
                            header={<Typography bold>Option 3: DATEV-Export direkt einlesen</Typography>}
                        >
                            <ImportClientsForm />
                        </Card>
                    </Flex>
                )
                : <CurrentClientImports />
            }
        </Flex>
    )
}