import React from "react";
import { AppColor } from "../../app/AppStyles";
import { IAddressee, IAddresseeRelationship } from "../../types/ApiTypes";
import Icon from "../icons/Icon";
import Pill from "../pills/Pill";
import AddresseeTypeIcon from "./AddresseeTypeIcon";
import { useAddresseeRelationships } from "../../state/swr/addressees/useAddresseeRelationships";
import { getId } from "../../util/mongoUtil";

export interface IAddresseeRelationshipItemProps {
    addressee: IAddressee, 
    relationshipId: string
}

export default function AddresseeRelationshipItem(props: IAddresseeRelationshipItemProps) {

    const {
        addresseeRelationshipsById
    } = useAddresseeRelationships();

    const {
        addressee, 
        relationshipId
    } = props;

    if (!addressee) return null;
    if (!relationshipId) return null;

    const relationship = addresseeRelationshipsById[getId(relationshipId)];
 
    if (!relationship) return null;
    if (relationship.has_addressee_id !== addressee.id && relationship.is_addressee_id !== addressee.id) return null;

    const isHasRelationship = relationship.has_addressee_id === addressee.id;
    const addresseeDisplayName = isHasRelationship ? relationship.is_addressee_display_name : relationship.has_addressee_display_name;

    const color = isHasRelationship ? "#DFDFDF" : "#D0D0FF";

    return (
        <Pill key={relationship._id} color={color} className="gap-2">
            <div className="d-flex flex-column align-items-center justify-content-center">
                <Icon icon={isHasRelationship ? "arrow-left" : "arrow-right"} size={12} style={{marginBottom: "-6px"}} />
                <Icon icon="link" />
            </div>
            <div className="d-flex flex-column">
                <span>{ addresseeDisplayName }</span>
                {
                    relationship.relationshipType && <span style={{fontSize: "0.9em"}}>{relationship.relationshipType.name}</span>
                }
            </div>
        </Pill>
    )
}