import React from "react";
import { generateClassName } from "../../hooks/useAttributes";
import useClientUtil from "../../hooks/useClientUtil";
import { useClients } from "../../state/swr/clients/useClients";
import { getTextColorFromBackground } from "../../util/util";
import Flex from "../container/Flex";
import Banner from "../pills/Banner";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import { DbId } from "../../types/ApiTypes";
import { IClient } from "../../types/client.schema";
import { getModel } from "../../util/mongoUtil";

export interface IClientTypeBannerProps {
    client?: DbId<IClient>,
    showClientName?: boolean,
    textOnEmpty?: string,
    alwaysShowClientType?: boolean,
    className?: string,
    onClick?: () => void
}

export default function ClientTypeBanner({client, alwaysShowClientType, showClientName, onClick, textOnEmpty, className}: IClientTypeBannerProps) {
    const {
        getTypeNameOfClient,
        getClientTypeColor,
        getClientName,
        getClientIcon
    } = useClientUtil();

    const {
        clientsById,
        loadingClients
    } = useClients();

    const realClient = getModel<IClient>(client, clientsById);

    if (!client) {
        if (textOnEmpty) return <Banner text={textOnEmpty} color="bright" />
        if (!showClientName) return null;
        return <span>-</span>
    }
    
    const clientName = getClientName(realClient);
    const clientType = getTypeNameOfClient(realClient);

    const pillClass = generateClassName(className, "gap-2");
    
    if (!loadingClients && !realClient) return null;

    const color = getClientTypeColor(realClient);

    return (
        <Pill
            icon={getClientIcon(realClient)}
            className={pillClass} 
            color={color} 
            withDivider
            loading={loadingClients}
            onClick={onClick}
        >
            {
                realClient && (
                    <Flex gap="0" fullWidth>
                        { realClient.number && <Typography bold size="11" lineHeight="14" basedOnThisBackground={color}>{realClient.number}</Typography>}
                        <Typography basedOnThisBackground={color} noLinePadding truncate>{showClientName ? clientName ?? "-" : clientType}</Typography>
                    </Flex>
                )
            }
        </Pill>
    )
}