import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITicket, ITicketAssignment } from "../../../types/ApiTypes";

export const useTicketAssignments = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<ITicketAssignment>>(ApiRoutes.Tickets.Assignments.GetAllAssignments.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketAssignments: data,
        loadingTicketAssignments: isLoading,
        reloadTicketAssignments: mutate
    }
} 