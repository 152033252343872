import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IInvoice } from "../../../types/ApiTypes";

export const useInvoices = (tenantId: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IInvoice>>(ApiRoutes.Tenant.GetTenantInvoices.getValue({
        param: RouteParam.TenantId,
        value: tenantId
    }));

    return {
        invoices: data,
        loadingInvoices: isLoading,
        reloadInvoices: mutate
    }
}