import React from "react";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import { useOfficeMailListenerStatus } from "../../../state/swr/office/useOfficeMailListenerStatus";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import WithPermissions from "../../permissions/WithPermissions";
import Pill from "../../pills/Pill";
import { IOfficeAccount } from "../../../types/officeAccount.types";

export default function OfficeMailAccountListenerStatus({officeAccount}: {officeAccount: IOfficeAccount}) {

    const {
        getAccountPermissions
    } = useInboxPermissions();

    const { loadingOfficeMailListenerStatus, officeMailListenerStatus, reloadOfficeMailListenerStatus } = useOfficeMailListenerStatus(officeAccount._id);

    const getText = () => {
        if (!officeMailListenerStatus) return "Inaktiv";
        else return "Aktiv";
    }

    const getColor = () => {
        if (!officeMailListenerStatus) return "#F0F0F0";
        else return "#0DAA5F";
    }

    const permissions = getAccountPermissions("read", officeAccount);

    return (
        <WithPermissions permissions={permissions} >
            <Flex row>
                <Icon 
                    size={18}
                    icon="arrow-clockwise" 
                    loading={loadingOfficeMailListenerStatus}
                    onClick={async () => await reloadOfficeMailListenerStatus()}  
                />
                <Pill 
                    color={getColor()}
                    text={getText()}
                />
            </Flex>
        </WithPermissions>

    )
}