import React from "react";
import TiggySocket from "../api/TiggySocket";
import { AppRoutes } from "../config/AppRoutes";
import { ModalType } from "../config/ModalTypes";
import { SocketEvents } from "../config/TicketEvents";
import { useLatestClientImportLog } from "../state/swr/clients/useLatestClientImportLog";
import { useTicket } from "../state/swr/tickets/useTicket";
import { useTicketMessages } from "../state/swr/tickets/useTicketMessages";
import { useTicketsWithFilter } from "../state/swr/tickets/useTicketsWithFilter";
import { useUser } from "../state/swr/user/useUser";
import { IClientImportLogEntry } from "../types/ApiTypes";
import { ITicketEmit, ITicketMessagEmit, ITicketSocketMessageReadResponse } from "../types/SocketTypes";
import useFilteredTickets, { TicketFilter, TicketSubcategory } from "./useFilteredTickets";
import useFilterQuery from "./useFilterQuery";
import useModalNotifications from "./useModalNotifications";

export default function useTiggySocket() {

    const [lastAffectedTicket, setLastAffectedTicket] = React.useState<string>("");

    const { user } = useUser();
    const { reloadMessages } = useTicketMessages(lastAffectedTicket || "");
    const { reloadAndUpdateAllTickets } = useTicket(lastAffectedTicket || "");
    const { reloadTickets } = useTicketsWithFilter();

    const showNotification = useModalNotifications();
    
    const {
        getFilterForRoute,
    } = useFilteredTickets();

    const {
        getUrlWithFilter
    } = useFilterQuery();

    const { 
        reloadTickets: reloadFilteredTickets,
    } = useTicketsWithFilter(getFilterForRoute());

    const {
        reloadLatestClientImportLog
    } = useLatestClientImportLog();

    const removeTicketSocketListeners = () => {
        TiggySocket.off(SocketEvents.System.Error);
        TiggySocket.off(SocketEvents.System.Message);
        TiggySocket.off(SocketEvents.Tickets.MessageRead);
        TiggySocket.off(SocketEvents.Tickets.MessageNew);
        TiggySocket.off(SocketEvents.Tickets.New);
        TiggySocket.off(SocketEvents.Tickets.Updated);
        TiggySocket.off(SocketEvents.Tickets.RemoveOrDelete);
        TiggySocket.off(SocketEvents.Tickets.UserTyping);
        TiggySocket.off(SocketEvents.Tickets.UserStoppedTyping);
        TiggySocket.off(SocketEvents.Clients.ImportProgress);
    }

    const connectToTicketSocket = () => {
        if (!user || !user._id) {
            TiggySocket.disconnect();
            return;
        }
        
        if (TiggySocket.connected) return;
        
        const host = window.location.href;

        console.log(`Verbinde mit dem Ticketsystem von '${host}'...`);

        TiggySocket.auth = {
            userId: user._id,
            url: host
        };
    
        const updateTicketFromSocket = () => setTimeout(() => reloadAndUpdateAllTickets(), 100);

        TiggySocket.on("connect", () => {
            console.info("WState:", TiggySocket.connected);
            console.info("CM:", TiggySocket.io.engine.transport.name);
        });
        
        TiggySocket.on("close", () => {
            console.info("DWState:", TiggySocket.connected);
            console.info("DCM:", TiggySocket.io.engine.transport.name);
        });

        TiggySocket.on(SocketEvents.System.Error, (err: Error) => {
            console.log("SOCKET ERROR:", err)
        });

        TiggySocket.on(SocketEvents.System.Message, (msg: string) => console.log(msg));
        TiggySocket.on(SocketEvents.Tickets.MessageNew, (msg: ITicketMessagEmit) => {
            console.log("NEW MESSAGE:", msg.messageId);
            if (msg.notForUser && msg.notForUser === user._id) return;
            setLastAffectedTicket(msg.ticketId);
            updateTicketFromSocket();
            reloadMessages();
        });
        
        TiggySocket.on(SocketEvents.Tickets.Escalated, (data: { ticket: string }) => {
            const link = getFilterForRoute({
                subcategory: TicketSubcategory.Escalated,
                show: TicketFilter.ForMe
            });
            
            console.log("ESCALATED TICKET:", data);
            showNotification({
                id: data.ticket,
                text: "Es wurde ein Ticket an Sie eskaliert. Bitte überprüfen Sie Ihre Tickets.",
                type: ModalType.Error,
                title: "Eskalation",
                action: {
                    text: "Tickets überprüfen",
                    to: getUrlWithFilter(AppRoutes.Tickets.path, link)
                }
            })
        });

        TiggySocket.on(SocketEvents.Tickets.New, (ticket: ITicketEmit) => {
            console.log("NEW TICKET:", ticket.ticketId);
            reloadFilteredTickets();
            if (ticket.notForUser && ticket.notForUser === user._id) return;
            setLastAffectedTicket(ticket.ticketId);
            updateTicketFromSocket();
        });
        
        TiggySocket.on(SocketEvents.Tickets.Updated, (ticket: ITicketEmit) => {
            console.log("TICKET UPDATED:", ticket.ticketId);
            setLastAffectedTicket(ticket.ticketId);
            updateTicketFromSocket();
        });

        TiggySocket.on(SocketEvents.Tickets.RemoveOrDelete, (ticket: ITicketEmit) => {
            console.log("TICKET DELETED:", ticket.ticketId);
            reloadFilteredTickets();
            if (ticket.notForUser && ticket.notForUser === user._id) return;
            setLastAffectedTicket(ticket.ticketId);
            reloadTickets();
        });
        
        TiggySocket.on(SocketEvents.Tickets.MessageRead, (readResponse: ITicketSocketMessageReadResponse) => {
        });

        TiggySocket.on(SocketEvents.Clients.ImportProgress, (importProgress: IClientImportLogEntry) => reloadLatestClientImportLog());

        TiggySocket.connect();
    }

   

    const sendTyping = (ticketId: string) => TiggySocket.emit(SocketEvents.Tickets.UserTyping, ticketId);
    const sendStopTyping = (ticketId: string) => TiggySocket.emit(SocketEvents.Tickets.UserStoppedTyping, ticketId);

    const sendRead = (ticketId: string, messageId: string) => TiggySocket.emit(SocketEvents.Tickets.MessageRead, {ticketId: ticketId, messageId: messageId});
    
    return {
        connectToTicketSocket,
        removeTicketSocketListeners,
        sendRead,
        sendTyping,
        sendStopTyping
    }
}