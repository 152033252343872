import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IPlan } from "../../../types/ApiTypes";

export const usePlans = (serviceId: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IPlan>>(ApiRoutes.Subscriptions.PlansGetForService.getValue({
        param: RouteParam.ServiceId,
        value: serviceId
    }));

    return {
        plans: data,
        loadingPlans: isLoading,
        reloadPlans: mutate
    }
}