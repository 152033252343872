import React from "react";
import useClientUtil from "../../hooks/useClientUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { useAnyUserAlias } from "../../state/swr/user/useAnyUserAlias";
import { useAnyUserClients } from "../../state/swr/user/useAnyUserClients";
import { DatevAddresseeType } from "../../types/ApiTypes";
import AddresseeAddress from "../addressee/AddresseeAddress";
import AddresseeDuplicateStatus from "../addressee/AddresseeDuplicateStatus";
import AddresseeTypeIcon from "../addressee/AddresseeTypeIcon";
import Flex from "../container/Flex";
import LoadingSpinner from "../loader/LoadingSpinner";
import Pill from "../pills/Pill";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import Typography from "../text/Typography";
import UserUpdateForm from "../user/UserUpdateForm";
import { ISortedClientContact } from "./ClientContactList";
import ClientTypeBanner from "../clients/ClientTypeBanner";

export default function ClientContactListItem({clientContact}: {clientContact: ISortedClientContact}) {

    const { loadingUserClients, reloadUserClients, userClients } = useAnyUserClients(clientContact.user ? clientContact.user._id : "");
    const { userAlias, loadingUserAlias } = useAnyUserAlias(clientContact.user ? clientContact.user._id : "");

    const {
        getName
    } = useUserUtil();

    const {
        getClientName
    } = useClientUtil();

    return (
        <TableRow>
            <TableCell>
                    {
                        clientContact.user
                        ? (
                            <div className="d-flex flex-column">
                                <strong>{getName(clientContact.user)}</strong>
                                <span>{clientContact.user?.mailAddress}</span>
                            </div>
                        )
                        : (
                            <div className="d-flex flex-column">
                                <strong>Kein Kontakt zugewiesen</strong>
                                <span>Eventuell fehlt die Mailaddresse</span>
                            </div>
                        )
                    }
            </TableCell>
            <TableCell>
                <div className="d-flex flex-row flex-wrap gap-1">
                    {
                        clientContact && clientContact.addressees && !!clientContact.addressees.length
                        ? clientContact.addressees.map(a => (
                            <Pill color={a.type === DatevAddresseeType.LegalPerson ? "#F0FFF0" : "#F0F0FF"} className="gap-2 d-flex flex-column" key={a._id}>
                                <div className="d-flex flex-row gap-2">
                                    <AddresseeTypeIcon addressee={a} />
                                    <span>{a.actualName}</span>
                                </div>
                                <AddresseeDuplicateStatus addressee={a} />
                                <AddresseeAddress addressee={a} />
                            </Pill>
                        ))
                        : <span>-</span>
                    }
                </div>
            </TableCell>
            <TableCell>
                <div className="d-flex flex-row flex-wrap gap-1">
                    {
                        loadingUserClients
                        ? <LoadingSpinner />
                        : (
                            userClients && userClients.clients && !!userClients.clients.length
                            ? userClients.clients.map(c => <ClientTypeBanner client={c} showClientName />)
                            : <span>-</span>
                        )
                    }
                </div>
            </TableCell>
            <TableCell>
                <Flex gap={1}>
                    {
                        loadingUserAlias 
                        ? <LoadingSpinner />
                        : (
                            userAlias && userAlias.length
                            ? userAlias.map(a => (
                                <Typography>{a.aliasMailAddress}</Typography>
                            ))
                            : <span>-</span>
                        )
                    }
                </Flex>
            </TableCell>
            <TableCell>
                {
                    clientContact.user && <UserUpdateForm user={clientContact.user} isClientContact={true} />
                }
            </TableCell>
        </TableRow>
    )
}