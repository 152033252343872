import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IPromotionCode } from "../../../types/ApiTypes";

export const usePromotionCodes = (couponId: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IPromotionCode>>(ApiRoutes.Subscriptions.PromotionCodesGetAll.getValue({
        param: RouteParam.CouponId,
        value: couponId
    }));

    return {
        promotionCodes: data,
        loadingPromotionCodes: isLoading,
        reloadPromotionCodes: mutate
    }
}