import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IDatevImport } from "../../../types/datevImport.schema";

export const useDatevImports = () => {
    const { data, isLoading, mutate } = useSWR<Array<IDatevImport>>(ApiRoutes.Datev.Import.All);

    return {
        datevImports: data,
        loadingDatevImports: isLoading,
        reloadDatevImports: mutate
    }
}