import { QueryFunction, QueryKey } from "@tanstack/react-query";
import axios, { CanceledError } from "axios";
import { StatusCodes } from "http-status-codes";
import { IResetStatisticsButtonProps } from "../components/dashboard/ResetStatisticsButton";
import { Locale } from "../locale/Locale";
import { IDocumentClearanceLog, IDocumentClearanceSettings, IFeedbackDocument, IImapAccount, IRole, ITenant, ITenantOpeningHoursDocument, ITicketEscalationworkflowDocument, IUploadableFile } from "../types/ApiTypes";
import { ISalutation, ISalutationDocument } from "../types/salutation.schema";
import { ITestSocketPayload } from "../types/socket.types";
import { IUsedMailTemplateDocument } from "../types/usedMailTemplates.schema";
import { Cookies, getCookie } from "../util/cookies";
import { ApiUrl } from "../util/envVars";
import * as Requests from "./ApiRequests";
import { ApiRoutes, RouteParam } from "./ApiRoutes";
import { ICouponCreateRequest, ICouponDeleteRequest, IFeatureCreateRequest, IFeatureUpdateRequest, IPlanCreateRequest, IPlanDeleteRequest, IPlanForServiceRequest, IPlanUpdateRequest, IPromotionCodeCreateRequest, IPromotionCodeRequestWithCouponId, IServiceCreateRequest, IServiceUpdateRequest } from "./requests/subscriptions/subscriptionRequests";
import { IGlobalMailTemplate } from "../types/globalMailTemplates.schema";
import { IClient, IClientSettings } from "../types/client.schema";

enum Methods {
    GET = "GET",
    POST = "POST",
    DELETE = "DELETE"
}

interface IDispatchParams<T = Object> {
    body?: T,
    abortController?: AbortController | null,
    abortSignal?: AbortSignal | null
}

export interface IApiResponse<T = any> {
    success: boolean,
    canceled: boolean,
    message: string,
    data: T
}

const createApiError = (message: string = Locale.errors.generic): IApiResponse => ({
    success: false,
    canceled: false,
    message: message,
    data: null
});

const createCanceledResponse = (): IApiResponse => ({
    canceled: true,
    success: false,
    message: "Canceled.",
    data: null
});

function createApiSuccessPayload<T>(payload: T): IApiResponse<T> { 
    return ({
        success: true,
        canceled: false,
        message: "Ok",
        data: payload
    });
}

const apiRequest = async<ResponseT = any, BodyT = any> (method: Methods = Methods.GET, endpoint: string | null = "/", params: IDispatchParams<BodyT> = {}): Promise<IApiResponse<ResponseT>>=> {
    try {

        if (!endpoint) return createApiError("No endpoint specified.");

        const {
            body,
            abortController,
            abortSignal
        } = params;

        const sessionToken = getCookie(Cookies.Session);

        const res = await axios({
            baseURL: ApiUrl,
            method: method,
            url: endpoint,
            headers: {
                'Authorization': `Bearer ${sessionToken}`,
                'Access-Control-Allow-Origin': "*"
            },
            signal: abortController ? abortController.signal : abortSignal || undefined,
            data: body
        });
        
        if (!res) return createApiError(Locale.errors.generic);
        if (res.status >= StatusCodes.OK && res.status < StatusCodes.MULTIPLE_CHOICES) return createApiSuccessPayload<ResponseT>(res.data);

        return createApiError("Something went wrong.");

    }
    catch (err: any) {
        if (err instanceof CanceledError) return createCanceledResponse();

        if (err?.response?.data) {
            const msg = typeof err.response.data === "string" ? err.response.data : err.response.data.message;
            return createApiError(msg);
        }
    }

    return createApiError();
}

export const isErrorApiResponse = (apiResponse: IApiResponse | null | undefined) => {
    if (!apiResponse) return true;
    return !apiResponse.success;
}

export const tanStackFetch: QueryFunction<unknown, QueryKey, never> = async ({ queryKey, signal }) => {
    try {
        if (!queryKey) return null;
        const endpoint = queryKey.toString();
        if (!endpoint) return null;

        const res = await apiRequest(Methods.GET, endpoint, { abortSignal: signal });
        if (!res.success) return null;
        return res.data || true;
    }
    catch (e) {
        throw e;
    }
}

export const swrFetch = async (endpoint: string, options?: RequestInit) => {
    try {
        if (!endpoint) return null;
        const res = await apiRequest(Methods.GET, endpoint, { abortSignal: options?.signal });
        if (!res.success) return null;

        return res.data || true;
    }
    catch {
        return null;
    }
}    

export const tenantsGetCurrent = async (signal: AbortSignal) => await apiRequest<ITenant>(Methods.GET, ApiRoutes.Tenant.Active, {abortSignal: signal});
export const tenantsGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Tenant.All, {abortSignal: signal});
export const tenantsAddNew = async (body: Requests.ITenantCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.Create, {body: body});
export const tenantsUpdate = async (body: Requests.ITenantUpdateSettingsRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.UpdateSettings.getValue({param: RouteParam.TenantId, value: body.tenantId}), {body: body});

export const tenantsDelete = async (req: Requests.ITenantDeleteRequest) => await apiRequest<any, Requests.ITenantDeleteRequest>(Methods.POST, ApiRoutes.Tenant.Delete.getValue({param: RouteParam.TenantId, value: req.tenantId}), {body: req});
export const tenantsDeleteForce = async (req: Requests.IRequestWithId) => await apiRequest<any, Requests.ITenantDeleteRequest>(Methods.DELETE, ApiRoutes.Tenant.DeleteForce.getValue({param: RouteParam.TenantId, value: req.id}));
export const tenantsRequestDelete = async (req: Requests.ITenantRequestDeletionTokenRequest) => apiRequest(Methods.POST, ApiRoutes.Tenant.RequestDeletionToken.getValue({param: RouteParam.TenantId, value: req.tenantId}));
export const tenantsAbortDelete = async (req: Requests.IRequestWithId) => await apiRequest<any, Requests.IRequestWithId>(Methods.POST, ApiRoutes.Tenant.AbortDelete.getValue({param: RouteParam.TenantId, value: req.id}));

export const tenantsOnboardingGetRolesForTenant = async (body: Requests.ITenantOnboardingRequest, ac: AbortController) => await apiRequest<IRole[]>(Methods.POST, ApiRoutes.Tenant.OnboardingGetRoles.getValue({param: RouteParam.TenantId, value: body.tenantId}), { body, abortController: ac });
export const tenantsOnboardingGetCurrentState = async (body: Requests.ITenantVerifyRequest, abortController: AbortController) => await apiRequest<ITenant>(Methods.POST, ApiRoutes.Tenant.Onboarding, {body: body, abortController: abortController});
export const tenantsOnboardingUpdate = async (body: Requests.ITenantOnboardingUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.UpdateDuringRegistration.getValue({param: RouteParam.TenantId, value: body._id}), {body: body});
export const tenantsOnboardingAddToCurrentDomains = async (body: Requests.ITenantOnboardingDomainAddRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.AddDomainDuringRegistration.getValue({param: RouteParam.TenantId, value: body._id}), {body: body});
export const tenantsOnboardingGetCurrentDomainStatus = async (body: Requests.ITenantOnboardingDomainGetStatusRequest, abortController?: AbortController) => await apiRequest(Methods.POST, ApiRoutes.Tenant.GetDomainStatusDuringRegistration.getValue({param: RouteParam.TenantId, value: body.tenantId}, {param: RouteParam.DomainId, value: body.domainId}), {body: body, abortController: abortController});
export const tenantsOnboardingCreateManagingUser = async (body: Requests.ITenantOnboardingCreateInitialUserRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.UpdateTenantAdminFromRegistration.getValue({param: RouteParam.TenantId, value: body._id}), {body: body});
export const tenantsOnboardingValidateTenantSubdomain = async (body: Requests.ITenantOnboardingDomainAddRequest, abortController: AbortController) => await apiRequest(Methods.POST, ApiRoutes.Tenant.ValidateSubdomainDuringRegistration.getValue({param: RouteParam.TenantId, value: body._id}), {body: body, abortController: abortController});
export const tenantsOnboardingGetInitialPassword = async (body: Requests.ITenantOnboardingGetInitialPasswordRequest, abortController?: AbortController) => await apiRequest(Methods.POST, ApiRoutes.Tenant.CreateNewManagerPasswordDuringOnboarding.getValue({param: RouteParam.TenantId, value: body.tenantId}), {body: body, abortController: abortController});
export const tenantsOnboardingSetBillingData = async (body: Requests.ITenantOnboardingUpdateBillingDataRequest, abortController?: AbortController) => await apiRequest(Methods.POST, ApiRoutes.Tenant.UpdateTenantBillingDataFromOnboarding.getValue({param: RouteParam.TenantId, value: body._id}), {body: body, abortController: abortController});
export const tenantsOnboardingSetHasPaymentDetails = async (body: Requests.ITenantOnboardingSetHasTenantDetailsRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.SetTenantHasPaymentDetailsFromOnboarding.getValue({param: RouteParam.TenantId, value: body.tenantId}));
export const tenantsOnboardingGetGlobalService = async (body: Requests.ITenantOnboardingRequest, abortController: AbortController) => await apiRequest(Methods.POST, ApiRoutes.Tenant.SubscriptionsGetBase.getValue({param: RouteParam.TenantId, value: body.tenantId}), {abortController: abortController, body: body});
export const tenantsOnboardingSelectBaseSubscription = async (body: Requests.ITenantOnboardingAddSubscriptionRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.OnboardingSubscribeToBase.getValue({param: RouteParam.TenantId, value: body.tenantId}), {body: body});

export const tenantsGetCurrentDomains = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Tenant.CurrentTenantDomains, {abortSignal: signal});
export const tenantsAddToCurrentDomains = async (body: Requests.IDomainCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.CurrentTenantDomains, {body: body});
export const tenantsUpdateBillingData = async (body: Requests.ITenantUpdateBillingDataRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.UpdateTenantBillingData.getValue({param: RouteParam.TenantId, value: body._id}), {body: body});
export const tenantsDeleteFromCurrentDomains = async (req: Requests.IDomainDeleteRequest) => await apiRequest(Methods.DELETE, ApiRoutes.Tenant.CurrentTenantDomainById.getValue({param: RouteParam.DomainId, value: req.domainId}));

export const tenantsGetCurrentPaymentMethods = async (req: Requests.ITenantByIdRequest, signal: AbortSignal) => await apiRequest(Methods.POST, ApiRoutes.Tenant.GetPaymentMethods.getValue({param: RouteParam.TenantId, value: req._id}), {abortSignal: signal});
export const tenantsAddPaymentMethod = async (req: Requests.ITenantByIdRequest, abortController: AbortController) => await apiRequest(Methods.POST, ApiRoutes.Tenant.AddPaymentMethod.getValue({param: RouteParam.TenantId, value: req._id}), {abortController: abortController});
export const tenantsSetHasPaymentMethod = async (body: Requests.ITenantByIdRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.SetTenantHasPaymentDetails.getValue({param: RouteParam.TenantId, value: body._id}));
export const tenantsSetDefaultPaymentMethod = async (body: Requests.ITenantDefaultPaymentMethodRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.SetPaymentMethodAsDefault.getValue({param: RouteParam.TenantId, value: body.id}), {body: body});
export const tenantsDeletePaymentMethod = async (body: Requests.ITenantDefaultPaymentMethodRequest) => await apiRequest(Methods.DELETE, ApiRoutes.Tenant.DeletePaymentMethod.getValue({param: RouteParam.TenantId, value: body.id}), {body: body});

export const tenantsGetInvoices = async (body: Requests.ITenantByIdRequest, signal: AbortSignal) => await apiRequest(Methods.POST, ApiRoutes.Tenant.GetTenantInvoices.getValue({param: RouteParam.TenantId, value: body._id}), {abortSignal: signal});

export const tenantsAddSubscription = async (body: Requests.ITenantSubscriptionAddRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.AddSubscription.getValue({param: RouteParam.TenantId, value: body.tenantId}, {param: RouteParam.PlanId, value: body.planId}), {body: body});
export const tenantsCancelSubscription = async (body: Requests.ITenantSubscriptionRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.CancelSubscription.getValue({param: RouteParam.TenantId, value: body.tenantId}, {param: RouteParam.PlanId, value: body.planId}));

export const tenantsUpdateMailAccount = async (body: Requests.ITenantUpdateMailAccountRequest) => await apiRequest(Methods.POST, ApiRoutes.Tenant.UpdateTenantMailSettings.getValue({param: RouteParam.TenantId, value: body.id}), {body: body});

export const domainsGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Domains.All, {abortSignal: signal});
export const domainsRefresh = async (req: Requests.IDomainRefreshRequest) => await apiRequest(Methods.GET, ApiRoutes.Domains.CheckStatus.getValue({param: RouteParam.DomainId, value: req.domainId}));

export const usersGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.User.All, {abortSignal: signal});
export const usersGetClientContacts = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.User.ClientContacts, {abortSignal: signal});
export const usersGetEmployees = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.User.Employees, {abortSignal: signal});
export const usersGetCurrent = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.User.Active, {abortSignal: signal});
export const usersGetCurrentTenantAuthority = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.User.CurrentTenantAuthority, {abortSignal: signal});

export const usersUpdateSettings = async (body: Requests.IUserUpdateSettingsRequest) => await apiRequest(Methods.POST, ApiRoutes.User.UpdateSettings.getValue({ param: RouteParam.UserId, value: body.id }), {body: body});

export const usersUpdateClientAccess = async (body: Requests.IUpdateUserClientsRequest) => await apiRequest(Methods.POST, ApiRoutes.User.Authority.AnyUserClients.getValue({param: RouteParam.UserId, value: body.id }), { body });

export const usersAddAlias = async (body: Requests.ICreateAliasRequest) => await apiRequest(Methods.POST, ApiRoutes.User.Alias.getValue({param: RouteParam.UserId, value: body.id}), { body });

export const usersUpdateDefaultTeam = async (body: Requests.IUserUpdateDefaultTeamRequest) => await apiRequest(Methods.POST, ApiRoutes.User.Teams.Default.getValue({ param: RouteParam.UserId, value: body.id }), { body });

export const usersRequestLogIn = async (body: Requests.IUserLogInRequest) => await apiRequest(Methods.POST, ApiRoutes.User.RequestLogIn, {body: body});
export const userLogOut = async () => await apiRequest(Methods.POST, ApiRoutes.User.LogOut);
export const usersRequestPasswordResetTokenByMail = async (body: Requests.IUserResetPasswordWithMailRequest) => await apiRequest(Methods.POST, ApiRoutes.User.RequestPasswordResetByMail, {body: body});
export const usersRequestPasswordResetToken = async () => await apiRequest(Methods.GET, ApiRoutes.User.RequestPasswordReset);

export const usersSetNewPassword = async (body: Requests.IUserNewPasswordRequest) => await apiRequest(Methods.POST, ApiRoutes.User.SetNewPassword, {body: body});
export const usersVerify = async (body: Requests.IUserVerificationRequest) => await apiRequest(Methods.POST, ApiRoutes.User.VerifyUser, {body: body});
export const usersUpdate = async (body: Requests.IUserUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.User.Update.getValue({param: RouteParam.UserId, value: body._id}), {body: body});
export const usersVerifyResetToken = async (body: Requests.IUserVerifyResetTokenRequest, abortController: AbortController) => await apiRequest(Methods.POST, ApiRoutes.User.ValidateResetToken, {body: body, abortController: abortController});

export const usersCreateNew = async (body: Requests.IUserRegistratationRequest) => await apiRequest(Methods.POST, ApiRoutes.User.CreateNewUser, {body: body});
export const usersDelete = async (req: Requests.IUserDeleteRequest) => await apiRequest(Methods.DELETE, ApiRoutes.User.Delete.getValue({param: RouteParam.UserId, value: req.userId}));
export const usersVerifyLogIn = async (body: Requests.ISessionVerificationRequest) => await apiRequest(Methods.POST, ApiRoutes.User.VerifyLogIn, {body: body});
export const usersRequestNewVerificationCode = async (abortController?: AbortController) => await apiRequest(Methods.POST, ApiRoutes.User.ResendSessionOtp, {abortController: abortController});

export const usersMassImportEmployees = async (body: Requests.IEmployeesImportRequest) => await apiRequest(Methods.POST, ApiRoutes.User.ImportEmployees, {body: body});
export const usersDeleteAllDatevEmployees = async () => await apiRequest(Methods.DELETE, ApiRoutes.User.DeleteAllDatevEmployees);
export const usersDeleteAllDatevClients = async () => await apiRequest(Methods.DELETE, ApiRoutes.User.DeleteAllDatevClients);

export const userRequestPhoneNumberAccess = async (abortController?: AbortController) => await apiRequest(Methods.POST, ApiRoutes.User.RequestAccessToPhoneNumber, {abortController: abortController});
export const userVerifyPhoneNumberAccess = async (body: Requests.IUserPhoneNumberVerifyRequest) => await apiRequest(Methods.POST, ApiRoutes.User.VerifyAccessToPhoneNumber, {body: body});
export const userUpdatePhoneNumber = async (body: Requests.IUserPhoneNumberUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.User.UpdatePhoneNumber, {body: body});
export const userVerifyPhoneNumberUpdate = async (body: Requests.IUserPhoneNumberVerifyRequest) => await apiRequest(Methods.POST, ApiRoutes.User.VerifyPhoneNumberUpdate, {body: body});

export const absenceAdd = async (body: Requests.IUserCreateAbsenceRequest) => await apiRequest(Methods.POST, ApiRoutes.User.Absence.ForUser.getValue({ param: RouteParam.UserId, value: body.userId }), {body: body});
export const absenceUpdate = async (body: Requests.IUserUpdateAbsenceRequest) => await apiRequest(Methods.POST, ApiRoutes.User.Absence.ById.getValue({ param: RouteParam.AbsenceId, value: body.id }), { body });
export const absenceRemove = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.User.Absence.ById.getValue({param: RouteParam.AbsenceId, value: body.id}));

export const sessionGetCurrent = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Session.Active, {abortSignal: signal});
export const sessionEndImposterSession = async () => await apiRequest(Methods.GET, ApiRoutes.Session.EndImposterSession);
export const sessionCreateImposter = async (body: Requests.ISessionCreateImposterRequest) => await apiRequest(Methods.POST, ApiRoutes.Session.CreateImposterSession, { body });
export const sessionSetVerificationMethod = async (body: Requests.ISessionUpdateVerificationMethodRequest) => await apiRequest(Methods.POST, ApiRoutes.Session.SetSelectedVerificationMethod, {body: body});
export const sessionGetAvailableVerificationMethods = async (abortController: AbortController) => await apiRequest(Methods.GET, ApiRoutes.Session.GetAvailableVerificationMethods, {abortController: abortController});

export const teamsGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Teams.All, {abortSignal: signal});
export const teamsCreate = async (body: Requests.ITeamCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Teams.Create, {body: body});
export const teamsUpdate = async (body: Requests.ITeamUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Teams.Update.getValue({param: RouteParam.TeamId, value: body.id}), {body: body});
export const teamsCreateForAreasOfResponsibility = async () => await apiRequest(Methods.POST, ApiRoutes.Teams.CreateTeamsForDatevAreasOfResponsibility);
export const teamsUpdateMembersFromAreaOfResponsibility = async (body: Requests.IRequestWithId) => await apiRequest(Methods.POST, ApiRoutes.Teams.UpdateTeamMembersFromAreaOfResponsibility.getValue({param: RouteParam.TeamId, value: body.id}));
export const teamsDelete = async (body: Requests.ITeamDeleteRequest) => await apiRequest(Methods.DELETE, ApiRoutes.Teams.Delete.getValue({param: RouteParam.TeamId, value: body.teamId}));

export const ticketsAddMessage = async (body: Requests.ITicketMessageAddRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.AddMessage.getValue({param: RouteParam.TicketId, value: body.id}), {body: body});
export const ticketsGetRelevant = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Tickets.Relevant, {abortSignal: signal});
export const ticketsGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Tickets.All, {abortSignal: signal});
export const ticketCategoriesGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Tickets.AllCategories, {abortSignal: signal});
export const ticketPrioritiesGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Tickets.AllPriorities, {abortSignal: signal});
export const ticketUpdateAccessHistory = async (req: Requests.ITicketUpdateLastAccessRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.Access.ById.getValue({param: RouteParam.TicketId, value: req.ticketId}));
export const ticketAddNote = async (req: Requests.ITicketAddNoteRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.AddNote.getValue({param: RouteParam.TicketId, value: req.ticketId}), {body: {text: req.text}});
export const ticketEscalate = async (req: Requests.ITicketEscalateRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.EscalateTickets.getValue({param: RouteParam.TicketId, value: req.ticketId}), {body: req});
export const ticketAssignToTeam = async (req: Requests.ITicketAssignToTeamRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.AssignToTeam.getValue({param: RouteParam.TicketId, value: req.ticketId}), {body: req});
export const ticketSetInternalSubject = async (req: Requests.ITicketSetInternalSubjectRequest) => await apiRequest<any, Requests.ITicketSetInternalSubjectRequest>(Methods.POST, ApiRoutes.Tickets.SetInternalSubject.getValue({param: RouteParam.TicketId, value: req.ticketId}), {body: req});
export const ticketsCreateAlias = async (body: Requests.IAssignAliasFromTicketRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.AssignAlias.getValue({param: RouteParam.TicketId, value: body.id}), { body });

export const ticketsCreate = async (body: Requests.ITicketCreationRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.Create, {body: body});
export const ticketsAssign = async (body: Requests.ITicketAssignRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.AssignTicket.getValue({param: RouteParam.TicketId, value: body.ticketId}), {body: body});
export const ticketsMerge = async (body: Requests.ITicketMergeRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.MergeTickets, { body: body });

export const ticketsUpdateStatus = async (body: Requests.ITicketsUpdateStatusRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.UpdateTicketStatus, {body: body});
export const ticketsDelete = async (body: Requests.ITicketDeleteRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.DeleteTickets, {body: body});

export const ticketMessagesUpdate = async (body: Requests.ITicketMessageUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.TicketMessages.ById.getValue({param: RouteParam.TicketMessageId, value: body.id}), {body: body});
export const ticketMessagesDownloadAttachments = async (body: Requests.ITicketMessageDownloadAttachmentRequest) => await apiRequest<IUploadableFile>(Methods.POST, ApiRoutes.TicketMessages.DownloadAttachments.getValue({param: RouteParam.TicketMessageId, value: body.id}), {body: body});

export const ticketsEscalateManually = async (body: Requests.ITicketEscalateManuallyRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.EscalationWorkflow.StartManually.getValue({param: RouteParam.TicketId, value: body.ticketId}, {param: RouteParam.TicketEscalationWorkflow, value: body.escalationWorkflowId}));

export const tasksCreate = async (body: Requests.ICreateTaskRequest) => await apiRequest(Methods.POST, ApiRoutes.Tasks.All, { body });
export const tasksUpdate = async (body: Requests.IUpdateTaskRequest) => await apiRequest(Methods.POST, ApiRoutes.Tasks.ById.getValue({param: RouteParam.Task, value: body.id}), { body });
export const tasksComplete = async (body: Requests.IRequestWithId) => await apiRequest(Methods.POST, ApiRoutes.Tasks.MarkAsCompleted.getValue({ param: RouteParam.Task, value: body.id }));
export const tasksDelete = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.Tasks.ById.getValue({ param: RouteParam.Task, value: body.id }));

export const rolesCreate = async (body: Requests.IRoleCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Roles.All, { body });
export const rolesUpdate = async (body: Requests.IRoleUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Roles.ById.getValue({ param: RouteParam.Role, value: body.id }), { body });
export const rolesDelete = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.Roles.ById.getValue({ param: RouteParam.Role, value: body.id }));

export const clientImportLogsGetAll = async (abortController: AbortController) => await apiRequest(Methods.GET, ApiRoutes.ClientsImportLog.All, {abortController: abortController});
export const clientImportLogsDeleteById = async (body: Requests.IClientImportLogRequest) => await apiRequest(Methods.DELETE, ApiRoutes.ClientsImportLog.GetById.getValue({param: RouteParam.ImportLogId, value: body.importId}));

export const clientsGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Clients.All, {abortSignal: signal});
export const clientsCreateNew = async (body: Requests.IClientCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Clients.Create, {body: body});
export const clientsUpdateSettings = async (body: IClient) => await apiRequest(Methods.POST, ApiRoutes.Clients.Settings.getValue({param: RouteParam.ClientId, value: body._id}), {body: body});
export const clientsUpdateManager = async (body: IClient) => await apiRequest(Methods.POST, ApiRoutes.Clients.UpdateClientManager.getValue({param: RouteParam.ClientId, value: body._id}), {body: body});

export const clientsCreateAlias = async (body: Requests.ICreateAliasRequest) => await apiRequest(Methods.POST, ApiRoutes.Clients.Alias.getValue({param: RouteParam.ClientId, value: body.id}), {body: body});

export const clientsImportFromDatev = async (body: Requests.IClientsImportFromDatevRequest, abortController?: AbortController) => await apiRequest(Methods.POST, ApiRoutes.Clients.ImportFromDatevJson, {body: body, abortController: abortController});
export const clientsDelete = async (body: Requests.IClientDeleteRequest) => await apiRequest(Methods.DELETE, ApiRoutes.Clients.ById.getValue({param: RouteParam.ClientId, value: body.id}));
export const clientsDeleteAll = async () => await apiRequest(Methods.DELETE, ApiRoutes.Clients.All);

export const clientsGetDocumentClearanceSettings = async (clientId: string, abortController: AbortController) => await apiRequest<IDocumentClearanceSettings>(Methods.GET, ApiRoutes.Clients.DocumentClearance.getValue({param: RouteParam.ClientId, value: clientId}), {abortController: abortController});
export const clientsAddOrUpdateDocumentClearanceSettings = async (clientId: string, body: Requests.ICreateDocumentClearanceSettingsBody) => await apiRequest(Methods.POST, ApiRoutes.Clients.DocumentClearance.getValue({param: RouteParam.ClientId, value: clientId}), {body: body});
export const clientsDeleteDocumentClearanceSettings = async (clientId: string) => await apiRequest(Methods.DELETE, ApiRoutes.Clients.DocumentClearance.getValue({param: RouteParam.ClientId, value: clientId}));
export const clientsGetDocumentClearanceLog = async (clientId: string, ac?: AbortController) => await apiRequest<Array<IDocumentClearanceLog>>(Methods.GET, ApiRoutes.Clients.DocumentClearanceLog.getValue({param: RouteParam.ClientId, value: clientId}), {abortController: ac});
export const clientsSendSingleDocumentClearanceReminder = async (clientId: string) => await apiRequest(Methods.POST, ApiRoutes.Clients.DocumentClearanceRemindManually.getValue({param: RouteParam.ClientId, value: clientId}));

export const clientsGetEmployeeResponsibilities = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Clients.GetEmployeeResponsibilities, {abortSignal: signal});

export const documentClearanceSetDone = async (documentClearanceId: string) => await apiRequest(Methods.POST, ApiRoutes.DocumentClearance.ById.getValue({param: RouteParam.DocumentClearanceReminder, value: documentClearanceId}));
export const documentClearanceGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.DocumentClearance.All, {abortSignal: signal});
export const documentClearanceRemindAll = async () => await apiRequest(Methods.POST, ApiRoutes.DocumentClearance.RemindAllManually);

export const employeesGetAllDatevEmployees = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Employees.All, {abortSignal: signal});
export const employeesGetAllAreasOfResponsibility = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Employees.AreasOfResponsibility, {abortSignal: signal});

export const addresseesDeleteAll = async () => await apiRequest(Methods.DELETE, ApiRoutes.Addressees.All);
export const addresseesGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Addressees.All, {abortSignal: signal});

export const settingsGet = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Settings, {abortSignal: signal});
export const settingsUpdate = async (body: Requests.ISettingsUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Settings, {body: body});

export const legalFormsGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.LegalForms, {abortSignal: signal});

export const logsGetAll = async (abortController: AbortController) => await apiRequest(Methods.GET, ApiRoutes.Logs, {abortController: abortController});
export const logsDelete = async () => await apiRequest(Methods.DELETE, ApiRoutes.Logs);

export const servicesCreate = async (body: IServiceCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.ServicesCreate, {body: body});
export const servicesUpdate = async (body: IServiceUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.ServicesUpdate.getValue({param: RouteParam.ServiceId, value: body.id}), {body: body});
export const servicesGetAll = async (signal: AbortSignal) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.ServicesGetAll, {abortSignal: signal});
export const servicesDelete = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.Subscriptions.ServicesDelete.getValue({param: RouteParam.ServiceId, value: body.id}));

export const plansGetForService = async (body: IPlanForServiceRequest, signal: AbortSignal) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.PlansGetForService.getValue({param: RouteParam.ServiceId, value: body.serviceId}), {abortSignal: signal});
export const plansCreate = async (body: IPlanCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.PlansCreate.getValue({param: RouteParam.ServiceId, value: body.serviceId}), {body: body});
export const plansUpdate = async (body: IPlanUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.PlansUpdate.getValue({param: RouteParam.ServiceId, value: body.serviceId}, {param: RouteParam.PlanId, value: body.planId}), {body: body});
export const plansDelete = async (body: IPlanDeleteRequest) => await apiRequest(Methods.DELETE, ApiRoutes.Subscriptions.PlansDelete.getValue({param: RouteParam.ServiceId, value: body.serviceId}, {param: RouteParam.PlanId, value: body.planId}));

export const featuresGetAll = async (signal: AbortSignal) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.FeaturesGetAll, {abortSignal: signal});
export const featureCreate = async (body: IFeatureCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.FeaturesCreate, {body: body});
export const featuresUpdate = async (body: IFeatureUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.FeaturesUpdate.getValue({param: RouteParam.FeatureId, value: body.id}), {body: body});

export const couponsGetAll = async (signal: AbortSignal) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.CouponsGetAll, {abortSignal: signal}); 
export const couponsCreate = async (body: ICouponCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.CouponsCreate, {body: body});
export const couponsDelete = async (body: ICouponDeleteRequest) => await apiRequest(Methods.DELETE, ApiRoutes.Subscriptions.CouponsDelete.getValue({param: RouteParam.CouponId, value: body.id}));

export const promotionCodesGetAllForCoupon = async (body: IPromotionCodeRequestWithCouponId, signal: AbortSignal) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.PromotionCodesGetAll.getValue({param: RouteParam.CouponId, value: body.couponId}), {abortSignal: signal});
export const promotionCodesCreate = async (body: IPromotionCodeCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.Subscriptions.PromotionCodesCreate.getValue({param: RouteParam.CouponId, value: body.couponId}), {body: body});

export const versionGet = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.Status.Version, { abortSignal: signal });

export const imapAccountsCreate = async (body: IImapAccount) => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.Create, {body: body});
export const imapAccountsUpdate = async (body: IImapAccount) => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.ById.getValue({param: RouteParam.ImapAccountId, value: body._id}), {body: body});
export const imapAccountsDelete = async (id: string) => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.ById.getValue({param: RouteParam.ImapAccountId, value: id}));
export const imapAccountsFetchMessages = async (id: string) => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.Messages.getValue({param: RouteParam.ImapAccountId, value: id}));
export const imapAccountsGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.ImapAccounts.GetAll, {abortSignal: signal});
export const imapAccountsGetListenerStatus = async (id: string, signal?: AbortSignal) => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.ServiceStatus.getValue({param: RouteParam.ImapAccountId, value: id}), {abortSignal: signal});
export const imapAccountsStopListener = async (id: string) => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.ServiceStopSingle.getValue({param: RouteParam.ImapAccountId, value: id}));
export const imapAccountsStartListener = async (id: string) => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.ServiceStartSingle.getValue({param: RouteParam.ImapAccountId, value: id}));
export const imapAccountsSendTestMail = async (body: Requests.ISendTestMailOverImapRequest) => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.ServiceSendTestMail.getValue({param: RouteParam.ImapAccountId, value: body.id}), {body: body});
export const imapAccountsStartAllListeners = async () => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.ServicesStartAll);
export const imapAccountsStopAllListeners = async () => await apiRequest(Methods.POST, ApiRoutes.ImapAccounts.ServicesStopAll);

export const datevDesktopClientDownload = async () => await apiRequest(Methods.GET, ApiRoutes.Datev.DesktopClientDownload);

export const cloudGatewayGetSettings = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.CloudGateway.GetCurrentTenantSettings, {abortSignal: signal});
export const cloudGatewayCreateOrUpdateSettings = async (body: Requests.ICreateOrUpdateCloudGatewayLogIn) => await apiRequest(Methods.POST, ApiRoutes.CloudGateway.CreateOrUpdateCurrentTenantSettings, {body: body});
export const cloudGatewayUpdateQuerySettings = async (body: Requests.IUpdateCloudGatewayQuerySettings) => await apiRequest(Methods.POST, ApiRoutes.CloudGateway.UpdateImportSettings.getValue({param: RouteParam.CloudGatewayId, value: body.id}), {body: body});
export const cloudGatewayRunImport = async (body: Requests.IRequestWithId) => await apiRequest(Methods.POST, ApiRoutes.CloudGateway.RunImport.getValue({param: RouteParam.CloudGatewayId, value: body.id}));
export const cloudGatewayDeleteSettings = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.CloudGateway.ById.getValue({param: RouteParam.CloudGatewayId, value: body.id}));

export const mailLogGetAll = async (signal: AbortSignal) => await apiRequest(Methods.GET, ApiRoutes.MailLog.GetAll, {abortSignal: signal});

export const azureGetSignInUrl = async (body: Requests.IAzureGetSignInUrlRequest) => await apiRequest<Requests.IAzureWithUrlResponse>(Methods.POST, ApiRoutes.Azure.SignIn, { body: body});

export const officeAccountStartMailService = async (body: Requests.IRequestWithId) => await apiRequest(Methods.POST, ApiRoutes.OfficeAccounts.StartListenerForAccount.getValue({param: RouteParam.OfficeAccountId, value: body.id}));
export const officeAccountCheckMailServiceStatus = async (body: Requests.IRequestWithId) => await apiRequest(Methods.GET, ApiRoutes.OfficeAccounts.CheckListenerStatusForAccount.getValue({param: RouteParam.OfficeAccountId, value: body.id}));
export const officeAccountStopMailService = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.OfficeAccounts.StopListenerForAccount.getValue({param: RouteParam.OfficeAccountId, value: body.id}));

export const officeAccountsUpdate = async (body: Requests.IOfficeAccountUpdateRequest, id: string) => await apiRequest(Methods.POST, ApiRoutes.OfficeAccounts.ById.getValue({param: RouteParam.OfficeAccountId, value: id}), { body: body });

export const officeAccountsSendMailForAccount = async (body: Requests.IOfficeSendMailForAccountRequest) => await apiRequest(Methods.POST, ApiRoutes.OfficeAccounts.SendMailForAccount.getValue({param: RouteParam.OfficeAccountId, value: body.id}), {body: body});
export const officeAccountsUpdateMail= async (body: Requests.IOfficeAccountUpdateMailRequest) => await apiRequest(Methods.POST, ApiRoutes.OfficeAccounts.UpdateMail.getValue({param: RouteParam.OfficeAccountId, value: body.id}), {body: body});
export const officeAccountsDeleteMail = async (body: Requests.IOfficeAccountMailByIdRequest) => await apiRequest(Methods.POST, ApiRoutes.OfficeAccounts.DeleteMessage.getValue({param: RouteParam.OfficeAccountId, value: body.id}), {body: body});

export const officeAccountsMoveMailToTicketFolder = async (body: Requests.IOfficeAccountMailByIdRequest) => await apiRequest(Methods.POST, ApiRoutes.OfficeAccounts.MoveMailToTicketFolderManually.getValue({param: RouteParam.OfficeAccountId, value: body.id}, {param: RouteParam.MailId, value: body.messageId}));
export const officeAccountsDownloadAllAttachments = async (body: Requests.IOfficeAccountMailByIdRequest) => await apiRequest<IUploadableFile>(Methods.GET, ApiRoutes.OfficeAccounts.DownloadAllAttachmentsForMail.getValue({param: RouteParam.OfficeAccountId, value: body.id}, {param: RouteParam.MailId, value: body.messageId})); 
export const officeAccountsGetAttachmentPreview = async (body: Requests.IOfficeAccountAttachmentPreviewRequest) => await apiRequest(Methods.GET, ApiRoutes.OfficeAccounts.GetAttachmentPreview.getValue({param: RouteParam.OfficeAccountId, value: body.id}, {param: RouteParam.MailId, value: body.mailId}));
export const officeAccountsGetAttachmentContent = async (body: Requests.IOfficeAccountAttachmentContentRequest) => await apiRequest(Methods.GET, ApiRoutes.OfficeAccounts.GetAttachmentContent.getValue({param: RouteParam.OfficeAccountId, value: body.id}, {param: RouteParam.MailId, value: body.mailId}, {param: RouteParam.AttachmentId, value: body.attachmentId}));

export const officeAccountsTransformMailToTicket = async (body: Requests.IOfficeAccountMailByIdRequest) => await apiRequest(Methods.POST, ApiRoutes.OfficeAccounts.TransformMailToTicket.getValue({param: RouteParam.OfficeAccountId, value: body.id}, {param: RouteParam.MailId, value: body.messageId}));
export const officeAccountsTransformMailToTicketMessage = async (body: Requests.IOfficeAccountAddMailAsMessageToTicketRequest) => await apiRequest(Methods.POST, ApiRoutes.OfficeAccounts.TransformMailToTicketMessage.getValue({param: RouteParam.OfficeAccountId, value: body.id}, {param: RouteParam.MailId, value: body.mailId}), {body: body});

export const officeAccountsGetNextMails = async (body: Requests.IOfficeAccountInboxRequest) => await apiRequest(Methods.POST, ApiRoutes.OfficeAccounts.GetNextMails.getValue({param: RouteParam.OfficeAccountId, value: body.id}), {body: body});

export const officeAccountDelete = async (body: Requests.IRequestWithId) => await apiRequest<Requests.IAzureWithUrlResponse>(Methods.DELETE, ApiRoutes.OfficeAccounts.ById.getValue({param: RouteParam.OfficeAccountId, value: body.id}));

export const feedbackAddNew = async (body: IFeedbackDocument) => await apiRequest(Methods.POST, ApiRoutes.Feedback.Post, {body:body});

export const pointChampCreateNewApiKey = async () => await apiRequest(Methods.POST, ApiRoutes.Integrations.PointChamp.CreateNewPointChampApiKey);

export const openingHoursUpdate = async (body: ITenantOpeningHoursDocument) => await apiRequest(Methods.POST, ApiRoutes.OpeningHours.All, {body: body});

export const ticketEscalationWorkflowCreate = async (body: ITicketEscalationworkflowDocument) => await apiRequest(Methods.POST, ApiRoutes.Tickets.EscalationWorkflow.All, {body: body});
export const ticketEscalationWorkflowUpdate = async (body: ITicketEscalationworkflowDocument, id: string) => await apiRequest(Methods.POST, ApiRoutes.Tickets.EscalationWorkflow.ById.getValue({ param: RouteParam.TicketEscalationWorkflow, value: id }), {body: body});
export const ticketEscalationWorkflowDelete = async (id: string) => await apiRequest(Methods.DELETE, ApiRoutes.Tickets.EscalationWorkflow.ById.getValue({ param: RouteParam.TicketEscalationWorkflow, value: id }));

export const aliasDelete = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.Alias.ById.getValue({ param: RouteParam.Alias, value: body.id }));

export const mailTemplatesCreate = async (body: Requests.IMailTemplateCreateRequest) => await apiRequest(Methods.POST, ApiRoutes.MailTemplates.Tenant.All, { body });
export const mailTemplatesUpdate = async (body: Requests.IMailTemplateUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.MailTemplates.Tenant.ById.getValue({ param: RouteParam.MailTemplate, value: body.id }), { body });
export const mailTemplatesDelete = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.MailTemplates.Tenant.ById.getValue({ param: RouteParam.MailTemplate, value: body.id }));

export const globalMailTemplatesUpdate = async (body: IGlobalMailTemplate) => await apiRequest(Methods.POST, ApiRoutes.MailTemplates.Global.ById.getValue({param: RouteParam.GlobalMailTemplate, value: body._id }), { body });

export const testMailTemplates = async (body: Requests.ITestMailTemplateRequest) => await apiRequest(Methods.POST, ApiRoutes.MailTemplates.TestSending, { body });

export const tenantSignaturesUpdate = async (body: Requests.IUpdateSignatureRequest) => await apiRequest(Methods.POST, ApiRoutes.Signatures.All, { body });

export const roundMailsSend = async (body: Requests.ISendRoundMailRequest) => await apiRequest(Methods.POST, ApiRoutes.RoundMail, { body });

export const cdpGrantConsent = async (body: Requests.ICdpGrantConsentRequest) => await apiRequest(Methods.POST, ApiRoutes.ContractForDataPrivacy.Current, { body });

export const ticketAssignmentsAdd = async (body: Requests.ITicketAssignmentAddRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.Assignments.AddAssignment.getValue({ param: RouteParam.TicketId, value: body.ticketId }), { body });
export const ticketAssignmentsDeactivate = async (body: Requests.ITicketAssignmentUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.Assignments.UpdateAssignment.getValue({ param: RouteParam.TicketId, value: body.ticketId }, { param: RouteParam.TicketAssignment, value: body.id }), { body });
export const ticketAssignmentsActivate = async (body: Requests.ITicketAssignmentUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.Assignments.UpdateAssignment.getValue({ param: RouteParam.TicketId, value: body.ticketId }, { param: RouteParam.TicketAssignment, value: body.id }), { body });
export const ticketAssignmentsUpdate = async (body: Requests.ITicketAssignmentUpdateRequest) => await apiRequest(Methods.POST, ApiRoutes.Tickets.Assignments.UpdateAssignment.getValue({ param: RouteParam.TicketId, value: body.ticketId }, { param: RouteParam.TicketAssignment, value: body.id }), { body });
export const ticketAssignmentsRemove = async (body: Requests.ITicketAssignmentDeleteRequest) => await apiRequest(Methods.DELETE, ApiRoutes.Tickets.Assignments.ById.getValue({ param: RouteParam.TicketAssignment, value: body.id }, { param: RouteParam.TicketId, value: body.ticketId }));

export const statisticsReset = async (body: IResetStatisticsButtonProps) => await apiRequest(Methods.POST, ApiRoutes.Statistics.Reset, { body });

export const importValidationErrorCheck = async (body: Requests.IRequestWithId) => await apiRequest(Methods.POST, ApiRoutes.ImportValidationError.CheckError.getValue({ param: RouteParam.ImportValidationError, value: body.id }));

export const usedMailTemplatesUpdateAssignment = async (body: IUsedMailTemplateDocument) => await apiRequest(Methods.POST, ApiRoutes.MailTemplates.Used.All, { body });
export const usedMailTemplatesResetToGlobal = async (body: IUsedMailTemplateDocument) => await apiRequest(Methods.POST, ApiRoutes.MailTemplates.Used.Reset, { body });

export const testingSocket = async (body: ITestSocketPayload) => await apiRequest(Methods.POST, ApiRoutes.Testing.Socket, { body });
export const testingWorker = async () => await apiRequest(Methods.POST, ApiRoutes.Testing.Worker.Result);
export const testingQueue = async () => await apiRequest(Methods.POST, ApiRoutes.Testing.Queue);
export const testingWorkerResponsiveness = async () => await apiRequest(Methods.POST, ApiRoutes.Testing.Worker.Responsiveness);

export const datevImportCancel = async (body: Requests.IRequestWithId) => await apiRequest(Methods.POST, ApiRoutes.Datev.Import.Cancel.getValue({ param: RouteParam.DatevImportId, value: body.id }));
export const datevImportsResume = async (body: Requests.IRequestWithId) => await apiRequest(Methods.POST, ApiRoutes.Datev.Import.ResumeRestart.getValue({ param: RouteParam.DatevImportId, value: body.id }));

export const salutationsCreate = async (body: ISalutationDocument) => await apiRequest(Methods.POST, ApiRoutes.Salutations.All, { body });
export const salutationsUpdate = async (body: ISalutation) => await apiRequest(Methods.POST, ApiRoutes.Salutations.ById.getValue({ param: RouteParam.Salutation, value: body._id }), { body });
export const salutationsDelete = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.Salutations.ById.getValue({ param: RouteParam.Salutation, value: body.id }));

export const salutationsCreateGlobal = async (body: ISalutationDocument) => await apiRequest(Methods.POST, ApiRoutes.Salutations.Global.All, { body });
export const salutationsUpdateGlobal = async (body: ISalutation) => await apiRequest(Methods.POST, ApiRoutes.Salutations.Global.ById.getValue({ param: RouteParam.Salutation, value: body._id }), { body });
export const salutationsDeleteGlobal = async (body: Requests.IRequestWithId) => await apiRequest(Methods.DELETE, ApiRoutes.Salutations.Global.ById.getValue({ param: RouteParam.Salutation, value: body.id }));
