import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { ITicket } from "../../../types/ApiTypes";
import { ICollection } from "../../../types/collection.types";
import { ITicketActivity } from "../../../types/ticketActivity.schema";

export const useTicketActivity = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useSWR<ICollection<ITicketActivity, string>>(ApiRoutes.Tickets.TicketActivity.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketActivity: data?.items ?? [],
        ticketActivityById: data?.byId ?? {} as { [key: string]: ITicketActivity },
        loadingTicketActivity: isLoading,
        reloadTicketActivity: mutate
    }
}