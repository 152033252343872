import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IUser } from "../../../types/ApiTypes";
import { ICollection } from "../../../types/collection.types";

export const useEmployees = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<IUser>>(ApiRoutes.User.Employees);

    return {
        employees: data?.items ?? [],
        employeesById: data?.byId ?? {},
        loadingEmployees: isLoading,
        reloadEmployees: mutate
    }
}