import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IUserStatistics } from "../../../types/ApiTypes";

export const useAllUserStatistics = () => {
    const { data, isLoading, mutate } = useSWR<Array<IUserStatistics>>(ApiRoutes.Statistics.AllUsers, { revalidateOnFocus: false, revalidateIfStale: false });

    return {
        allUserStatistics: data,
        loadingAllUserStatistics: isLoading,
        reloadAllUserStatistics: mutate
    }
}