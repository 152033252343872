import React from "react";
import { formatDate } from "../../util/formatter";
import Button from "../buttons/Button";
import CopyToClipboardButton from "../buttons/CopyToClipboardButton";
import Flex from "../container/Flex";
import ModalDialog from "../modal/ModalDialog";
import LogEntryEventTypeBanner from "./LogEntryEventTypeBanner";
import LogEntrySystemBanner from "./LogEntrySystemBanner";
import LogEntryTypeBanner from "./LogEntryTypeBanner";
import { ExternalService, ILogEntry } from "../../types/log.schema";
import { useTenants } from "../../state/swr/tenant/useTenants";
import { getModel } from "../../util/mongoUtil";
import ExternalServiceBanner from "../services/ExternalServiceBanner";

export default function LogEntryForm({log}: {log: ILogEntry}) {

    const {
        tenantsById
    } = useTenants();

    const tenant = getModel(log.tenant, tenantsById);
    
    return (
        <ModalDialog 
            title={`Log-Eintrag vom ${formatDate(log.updatedAt, true)}`}  
            button={<Button variant="icon" icon="eye" />}
        >
            {
                close => (
                    <Flex gap={3} className="w-100">
                        <Flex row>
                            <LogEntrySystemBanner log={log} />
                            <LogEntryTypeBanner log={log} />
                            <LogEntryEventTypeBanner log={log} />
                            <ExternalServiceBanner service={log.service} />
                        </Flex>
                        {
                            tenant && (
                                <Flex gap={1} className="w-100">
                                    <strong>Tenant</strong>
                                    <span>{tenant.name}</span>
                                </Flex>
                            )
                        }
                        <Flex gap={1} className="w-100">
                            <Flex row justify="between" fullWidth fullHeight>
                                <strong>Inhalt</strong>
                                <CopyToClipboardButton value={log.content} />
                            </Flex>
                            <span className="w-100 h-100 text-wrap text-break">{log.content}</span>
                        </Flex>
                        {
                            log.additionalInfo && (
                                <Flex gap={1} className="w-100">
                                    <Flex row justify="between" className="w-100">
                                        <strong>Weitere Informationen</strong>
                                        <CopyToClipboardButton value={log.additionalInfo} />
                                    </Flex>
                                    <span className="w-100 text-wrap text-break">{log.additionalInfo}</span>
                                </Flex>
                            )
                        }
                        {
                            log.eventId && (
                                <Flex gap={1} className="w-100">
                                    <Flex row justify="between" className="w-100">
                                        <strong>Event-ID</strong>
                                        <CopyToClipboardButton value={log.eventId} />
                                    </Flex>
                                    <span className="w-100 text-wrap text-break">{log.eventId}</span>
                                </Flex>
                            )
                        }
                        {
                            log.methodName && (
                                <Flex gap={1} className="w-100">
                                    <strong>Aufruf</strong>
                                    <span className="w-100 text-wrap text-break">{log.methodName}</span>
                                </Flex>
                            )
                        }
                        <Button className="align-self-end" onClick={async () => close()} text="Schließen" icon="x" />
                    </Flex>
                )
            }
        </ModalDialog>
    )
}