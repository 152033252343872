import useSWR from "swr";
import { IPointChampApiKey, ITenant, ITenantOpeningHours } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { ICollection } from "../../../types/collection.types";

export const useTenants = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<ITenant>>(ApiRoutes.Tenant.All);

    return {
        tenants: data?.items ?? [],
        tenantsById: data?.byId ?? {},
        loadingTenants: isLoading,
        reloadTenants: mutate
    }
}