import React from "react";
import Button from "./Button";

export interface IEditButtonProps {
    readOnly?: boolean,
    isCreate?: boolean
}

export default function EditButton(props: IEditButtonProps) {
    const {
        isCreate,
        readOnly
    } = props;

    
    const getEditButtonIcon = () => {
        if (readOnly) return "eye";
        if (isCreate) return "plus";
        return "pen";
    }

    const getEditButtonColor = () => {
        if (readOnly) return "secondary";
        if (isCreate) return "success";
        return "primary";
    }

    const getEditButtonText = () => {
        if (readOnly) return "Ansehen";
        if (isCreate) return "Erstellen";
        return "Bearbeiten";
    }


    return (
        <Button
            text={getEditButtonText()}
            icon={getEditButtonIcon()}
            variant={readOnly ? "subtle" : "solid"}
            color={getEditButtonColor()}
        />
    )
}
