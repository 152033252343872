import React from "react";
import useAddresseeUtil from "../../hooks/useAddresseeUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import { useAnyUserClients } from "../../state/swr/user/useAnyUserClients";
import { IAddressee } from "../../types/ApiTypes";
import { getId } from "../../util/mongoUtil";
import ClientTypeBanner from "../clients/ClientTypeBanner";
import Flex from "../container/Flex";
import Icon from "../icons/Icon";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Pill from "../pills/Pill";
import TableCell from "../tables/TableCell";
import TableRow from "../tables/TableRow";
import AddresseeAddress from "./AddresseeAddress";
import AddresseeDuplicateStatus from "./AddresseeDuplicateStatus";
import AddresseeRelationshipItem from "./AddresseeRelationshipItem";
import AddresseeTypeIcon from "./AddresseeTypeIcon";

export default function AddresseeListItem({addressee}: {addressee: IAddressee}) {
    const { loadingUserClients, userClients } = useAnyUserClients(getId(addressee.user));
    const { addressees } = useAddressees();

    const {
        getName,
        getMail
    } = useUserUtil();

    const {
        getAddresseeTypeColor,
    } = useAddresseeUtil();

    const addresseeUserExists = (user: string): number => {
        if (!user) return 0;
        const exists = addressees && addressees.filter(a => a.user && getId(a.user) === getId(user));

        return exists && exists.length ? exists.length - 1 : 0;
    }

    const isAddresseeUserAssignedToOthers = addresseeUserExists(addressee.user);

    return (
        <TableRow>
            <TableCell>
                <Flex gap={2}>
                    <Flex gap={0}>
                        <strong>{addressee.actualName}</strong>
                        <AddresseeAddress addressee={addressee} />
                        <div className="d-flex flex-column gap-1">
                            <Pill color={getAddresseeTypeColor(addressee)} className="gap-2">
                                <AddresseeTypeIcon addressee={addressee} showText />
                            </Pill>
                            {
                                addressee.isMissingMail
                                ? <Pill color="#FEDD00">Keine Email</Pill>
                                : addressee.originalMail && (
                                    <Pill 
                                        color="#FFF5FF" 
                                        className="gap-2" 
                                        icon="at" 
                                        iconColor="error" 
                                        text={addressee.originalMail}
                                    />
                                )
                            }
                        </div>
                    </Flex>
                    <AddresseeDuplicateStatus addressee={addressee} />
                </Flex>
            </TableCell>
            <WithPermissions permissions={[ "addresseeRelationships.all.read" ]}>
                <TableCell>
                    <Flex row gap={1} wrap>
                        {
                            addressee && addressee.relationships && !!addressee.relationships.length && addressee.relationships.map(r => <AddresseeRelationshipItem addressee={addressee} relationshipId={r} key={r} />)
                        }
                    </Flex>
                </TableCell>
            </WithPermissions> 
            <TableCell>
                <Flex row wrap className="w-100">
                    {
                        loadingUserClients 
                        ? <LoadingSpinner />
                        : (
                            userClients && userClients.clients && userClients.clients.length
                            ? userClients.clients.map(c => <ClientTypeBanner client={c} showClientName />)
                            : <span>-</span>
                        )
                    }
                </Flex>
            </TableCell>
            <TableCell>
                {
                    addressee.user 
                    ? (
                        <Pill className="gap-3 justify-content-start" color={isAddresseeUserAssignedToOthers ? "#FFF0F0" : "#F0F0F0"}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <Icon icon="person-fill" style={{marginBottom: "-5px"}} size={14} />
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <Icon icon="link" color={isAddresseeUserAssignedToOthers ? "error" : "success"} size={18} />
                                    {
                                        !!isAddresseeUserAssignedToOthers && <em style={{fontSize: "0.8em"}}>+{isAddresseeUserAssignedToOthers} weitere</em>
                                    }
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <strong>{getName(addressee.user)}</strong>
                                <span style={{fontSize: "0.9em"}}>{getMail(addressee.user)}</span>
                            </div>
                        </Pill>
                    )
                    : <span>-</span>
                }
            </TableCell>
        </TableRow>
    )
}