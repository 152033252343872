import { FormikProps } from "formik";
import React from "react";
import { clientsCreateAlias, clientsUpdateManager, clientsUpdateSettings, IApiResponse } from "../../api/Api";
import { ICreateAliasRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import useUserUtil from "../../hooks/useUserUtil";
import { useAnyClientAlias } from "../../state/swr/clients/useAnyClientAlias";
import { useClients } from "../../state/swr/clients/useClients";
import { useEmployees } from "../../state/swr/employees/useEmployees";
import { IClient } from "../../types/client.schema";
import AliasForm from "../alias/AliasForm";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import ModalForm from "../modal/ModalForm";
import WithPermissions from "../permissions/WithPermissions";
import SalutationSelect from "../salutations/SalutationSelect";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import Typography from "../text/Typography";
import "./ImportClientsForm.css";

export enum UpdateClientTab {
    General = "general",
    Alias = "alias",
    Settings = "settings"
}

export default function UpdateClientForm({client}: {client: IClient}) {

    const { reloadClients } = useClients();
    const { employees, reloadEmployees, loadingEmployees } = useEmployees();

    const { clientAlias, loadingClientAlias, reloadClientAlias } = useAnyClientAlias(client._id);

    const [tab, setTab] = React.useState<UpdateClientTab>(UpdateClientTab.General);

    const callApi = useApi();

    const {
        getName
    } = useUserUtil();

    const getInitialValues = () => {
        switch (tab) {
            case UpdateClientTab.Settings: return client;
            case UpdateClientTab.General: return client;

            case UpdateClientTab.Alias: return {
                aliasMailAddress: "",
                id: client._id
            } as ICreateAliasRequest 
        }
    }

    const getSubmitText = () => {
        switch (tab) {
            case UpdateClientTab.General: return "Verantwortlichen Mitarbeiter anpassen";
            case UpdateClientTab.Alias: return "Alias hinzufügen";
            case UpdateClientTab.Settings: return "Einstellungen speichern";
        }
    }

    return (
        <WithPermissions permissions={[ "clients.all.update" ]} >
            <ModalForm 
                button={<Button text="Mandat bearbeiten" icon="pen" />}
                title="Mandant anpassen"
                initialValues={getInitialValues()}
                enableReinitialize
                closeAfterSubmit={tab !== UpdateClientTab.Alias}
                onSubmit={async (values) => {

                    let res: IApiResponse | null = null;

                    if (tab === UpdateClientTab.General) res = await callApi(clientsUpdateManager(values as IClient));
                    else if (tab === UpdateClientTab.Alias) res = await callApi(clientsCreateAlias(values as ICreateAliasRequest));
                    else if (tab === UpdateClientTab.Settings) res = await callApi(clientsUpdateSettings(values as IClient));

                    if (!res || !res.success) return false;
                    
                    await reloadClients();
                    await reloadClientAlias();
                    await reloadEmployees();

                    return true;
                }}
                sidebar={formik => <Button type="submit" loading={formik.isSubmitting} text={getSubmitText()} color="success" />}
            >
                {
                    (formik: FormikProps<any>) => {
                        const getContent = () => {
                            switch (tab) {
                                case UpdateClientTab.Settings:

                                    return (
                                        <Flex fullWidth>
                                            <Typography bold>Mails</Typography>
                                            <SalutationSelect 
                                                value={formik.values.settings?.mails?.defaultSalutation ?? ""}
                                                onChange={e => formik.setFieldValue("settings.mails.defaultSalutation", e)}
                                            />
                                        </Flex>
                                    )
                                    
                                case UpdateClientTab.General:

                                    return (
                                        <SearchableComboBox
                                            loading={loadingEmployees}
                                            values={employees ?? []}
                                            disabled={!employees || !employees.length}
                                            itemToId={e => e ? e._id : "client-manager"} 
                                            itemToString={e => getName(e)}
                                            value={formik.values.clientManager}
                                            onItemClick={e => formik.setFieldValue("clientManager", e?._id)}
                                            label="Verantwortlicher Mitarbeiter"
                                            
                                        />
                                    );

                                case UpdateClientTab.Alias: 
                                
                                    return (
                                        <AliasForm 
                                            data={clientAlias} 
                                            isLoading={loadingClientAlias} 
                                            mutate={reloadClientAlias} 
                                        />
                                    )
                            }
                        }

                        return (
                            <Flex className="w-100">
                                <TabSwitcher
                                    tabQueryParamKey="view"
                                    removeOnUnmount
                                    tabs={[
                                        {data: UpdateClientTab.General, label: "Allgemein"},
                                        {data: UpdateClientTab.Settings, label: "Einstellungen"},
                                        {data: UpdateClientTab.Alias, label: "Alias"},
                                    ]}
                                    saveActiveTab={t => setTab(t as UpdateClientTab)}
                                />
                                { getContent() }
                            </Flex>
                        )
                    }
                }
            </ModalForm>
        </WithPermissions>
    )
}