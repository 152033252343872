import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IUserStatisticsForTeam } from "../../../types/ApiTypes";

export const useAnyTeamUserStatistics = (id: string) => {
    const { data, isLoading, mutate } = useSWR<IUserStatisticsForTeam>(ApiRoutes.Statistics.AnyTeam.getValue({ 
        param: RouteParam.TeamId, 
        value: id
    }), { revalidateOnFocus: false, revalidateIfStale: false });

    return {
        teamUserStatistics: data,
        loadingTeamUserStatistics: isLoading,
        reloadTeamUserStatistics: mutate
    }
}