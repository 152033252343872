import React from "react";
import { IAddressee, IImportValidationError, ImportValidationErrorType } from "../../types/ApiTypes";
import ModalForm from "../modal/ModalForm";
import Pill from "../pills/Pill";
import Typography from "../text/Typography";
import { IRequestWithId } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import { importValidationErrorCheck } from "../../api/Api";
import Flex from "../container/Flex";
import useImportValidationUtil from "../../hooks/useImportValidationUtil";
import Icon from "../icons/Icon";
import WithPermissions from "../permissions/WithPermissions";
import Card from "../card/Card";
import RoleBanner from "../user/RoleBanner";
import { formatDateTiggiStyle } from "../../util/formatter";
import { useImportValidationError } from "../../state/swr/importValidation/useImportValidationError";
import Button from "../buttons/Button";
import useAddresseeUtil from "../../hooks/useAddresseeUtil";
import AddresseeTypeIcon from "../addressee/AddresseeTypeIcon";

export interface IImportValidationChipProps {
    addressee: IAddressee,
    importValidationError: IImportValidationError
}

export default function ImportValidationChip(props: IImportValidationChipProps) {
    
    const {
        addressee,
        importValidationError
    } = props;

    const {
        reloadImportValidationErrors
    } = useImportValidationError({ addressee: importValidationError.addressee, client: importValidationError.client, employee: importValidationError.employee });

    const {
        color,
        description,
        text
    } = useImportValidationUtil(importValidationError);

    const {
        checked
    } = importValidationError;

    const callApi = useApi();

    const {
        getAddresseeDuplicates,
        getAddresseeTypeColor,
    } = useAddresseeUtil();

    if (!importValidationError) return null

    const duplicates = getAddresseeDuplicates(addressee);

    return (
        <WithPermissions permissions={[ "interfaces.datev.read" ]}>
            <Pill 
                color={color} 
                className="gap-1" 
                style={{fontSize: "0.9em"}}
            >
                <Icon 
                    tooltipAction={
                        hide => (
                            <ModalForm
                                button={<Button icon="check" color="success" text="Überprüfen und erledigen" />}
                                initialValues={{
                                    id: importValidationError._id
                                } as IRequestWithId}    
                                onSubmit={async (values) => {
                                    const res = await callApi(importValidationErrorCheck(values));
                                    await reloadImportValidationErrors();
                                    return !!res && !!res.success;
                                }}
                                onClose={hide}
                                title="Import-Fehler"
                                submitText="Als Geprüft markieren"
                                submitIcon="check"
                                submitDisabled={importValidationError.checked}
                            >
                                {
                                    formik => (
                                        <Flex fullWidth gap={3}>
                                            <Flex>
                                                <Typography bold color="primary">Fehler</Typography>
                                                <Typography wrap className="w-100">{description}</Typography>
                                            </Flex>
                                            <Flex>
                                                <Typography color="primary" bold>Duplikats-Datensätze</Typography>
                                                {
                                                    !!duplicates && !!duplicates.length && (
                                                        duplicates.map(d => (
                                                            <Pill key={d._id} color={getAddresseeTypeColor(d)} className="gap-2">
                                                                <AddresseeTypeIcon addressee={d} />
                                                                <span>{d.actualName}</span>
                                                            </Pill>
                                                        ))                
                                                    )
                                                }
                                            </Flex>
                                            {
                                                importValidationError.checked && (
                                                    <Card 
                                                    color="success"
                                                    header={
                                                        <Flex row justify="between" fullWidth> 
                                                            <Typography bold>Geprüft</Typography>
                                                            <Typography bold>{formatDateTiggiStyle(importValidationError.checkedAt)}</Typography>
                                                        </Flex>
                                                    }
                                                    >
                                                        <Flex>
                                                            <Typography>Der Fehler wurde geprüft von:</Typography>
                                                            <RoleBanner user={importValidationError.checkedBy} displayNameAsValue showMail />
                                                        </Flex>
                                                    </Card>
                                                )
                                            }
                                        </Flex>
                                    )
                                }
                            </ModalForm>
                        )
                    }
                    icon={
                        checked 
                        ? "check" 
                        : "info-circle"
                    }
                    tooltipTitle={checked ? undefined : "Was bedeutet das?"}
                    color="bright" 
                    tooltip={checked ? undefined : description}
                />
                <Typography basedOnThisBackground={color} bold>{text}</Typography>
            </Pill>
        </WithPermissions>
    )
}