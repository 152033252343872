import React from "react";
import { DbId, IAddressee } from "../../types/ApiTypes";
import { getId, getModel } from "../../util/mongoUtil";
import AddresseeAddress from "../addressee/AddresseeAddress";
import AddresseeLegalForm from "../addressee/AddresseeLegalForm";
import AddresseeRelationshipItem from "../addressee/AddresseeRelationshipItem";
import AddresseeTypeIcon from "../addressee/AddresseeTypeIcon";
import Pill from "../pills/Pill";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import { IClient } from "../../types/client.schema";
import { IWithClientProps } from "./ClientDocumentClearanceReminderForm";

export interface IClientAddresseeItemProps extends IWithClientProps {
    addresseeId: DbId<IAddressee>
}

export default function ClientAddresseeItem(props: IClientAddresseeItemProps) {

    const {
        client,
        addresseeId
    } = props;

    const {
        addresseesById
    } = useAddressees();

    const addressee = getModel(addresseeId, addresseesById);
    
    if (!addressee) return null;

    return (
        <div className="d-flex flex-column w-100 gap-2">
            <Pill color="#F0F0F0" className="gap-3 w-100">
                <AddresseeTypeIcon addressee={addressee} />
                <div className="d-flex flex-column gap-1 w-100">
                    <strong className="text-nowrap">{addressee.actualName}</strong>
                    <AddresseeLegalForm addressee={addressee} />
                    <AddresseeAddress addressee={addressee} />
                </div>
            </Pill>
            <div className="d-flex flex-row align-items-center flex-wrap gap-1">
                {
                    !!client && !!addressee && !!client.relationships && !!client.relationships.length 
                    ? client.relationships.map(r => <AddresseeRelationshipItem addressee={addressee} relationshipId={getId(r)} key={getId(r)} />)
                    : null
                }
            </div>
        </div>
    )
}