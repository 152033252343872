import React from "react";
import Page from "../../layout/Page";
import StartAzureLogInButton from "../../components/mail/account/LinkOfficeAccountButton";
import LinkedAccountsOverview from "../../components/mail/account/LinkedAccountsOverview";
import { OfficeAccountType } from "../../types/officeAccount.types";

export default function MailAddressManagement() {
    return (
        <Page 
            header="Integrierte Postfächer verwalten" 
            actionButtons={[<StartAzureLogInButton key="start-azure-log-in-button" type={OfficeAccountType.Tenant} />]}
        >
            <LinkedAccountsOverview />
        </Page>
    )
}