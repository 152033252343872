import { Formik, Form } from "formik";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { Status } from "../../config/Status";
import LoadingSpinner from "../loader/LoadingSpinner";
import FieldWithLabel from "../formik/FormikField";
import Accordion from "../accordion/Accordion";
import Button from "../buttons/Button";
import useApi from "../../hooks/useApi";
import { cloudGatewayCreateOrUpdateSettings, cloudGatewayDeleteSettings, cloudGatewayRunImport, cloudGatewayUpdateQuerySettings } from "../../api/Api";
import { ICloudGatewayQueryOptions } from "../../types/ApiTypes";
import { IUpdateCloudGatewayQuerySettings } from "../../api/ApiRequests";
import CheckBox from "../formik/CheckBox";
import { AppColor } from "../../app/AppStyles";
import { QueryParams } from "../../config/AppRoutes";
import { useSearchParams } from "react-router-dom";
import { useCloudGateway } from "../../state/swr/cloudGateway/useCloudGateway";
import WithPermissions from "../permissions/WithPermissions";
import Flex from "../container/Flex";

export default function CloudGatewaySetUp({afterComplete}: {afterComplete?: () => void}) {
    
    const callApi = useApi();
    
    const [searchParams, setSearchParams] = useSearchParams();

    const { cloudGatewaySettings, reloadCloudGatewaySettings, loadingCloudGatewaySettings } = useCloudGateway();

    if (loadingCloudGatewaySettings) return <LoadingSpinner centered={false} />;

    const loginDataForm = (
        <Formik
            initialValues={{
                username: cloudGatewaySettings?.username || "",
                password: ""
            }}
            onSubmit={async (values) => {
                const res = await callApi(cloudGatewayCreateOrUpdateSettings(values));
                if (!res || !res.success) return;
                await reloadCloudGatewaySettings();
            }}
        >
            {
                formik => (
                    <Form className="d-flex flex-column gap-2 card p-2">
                        <FieldWithLabel name="username" label="Cloud Gateway Nutzername" placeholder="Ihr CloudGateway-Nutzername (sehr lange Zeichenfolge)" />
                        <FieldWithLabel name="password" type="password" label="Cloud Gateway Passwort" placeholder="Ihr CloudGateway-Passwort (sehr lange Zeichenfolge)" />
                        <Button type="submit" text="Speichern" disabled={!formik.dirty} loading={formik.isSubmitting} />
                    </Form>
                )
            }
        </Formik>
    );

    const loginDataContainer = cloudGatewaySettings ? (
        <Accordion text="Zugangsdaten" className="w-100" isExpandedInitially={false} icon="chevron-down" iconExpanded="chevron-up" iconFirst>
            {loginDataForm}
        </Accordion>
    ) : loginDataForm;

    return (
        <WithPermissions permissions={[ "interfaces.cloudGateway.update" ]}>
            <Flex gap="4" fullWidth>
                {loginDataContainer}
                {
                    cloudGatewaySettings && (
                        <Accordion className="w-100" text="Abfrage-Einstellungen" isExpandedInitially icon="chevron-down" iconExpanded="chevron-up" iconFirst>
                            <Formik
                                initialValues={{
                                    id: cloudGatewaySettings?._id,
                                    clientNumberFrom: cloudGatewaySettings?.options?.clientNumberFrom || "",
                                    clientNumberTo: cloudGatewaySettings?.options?.clientNumberTo || "",
                                    consultantIds: cloudGatewaySettings?.options?.consultantIds || "",
                                    importInactiveData: cloudGatewaySettings?.options?.importInactiveData || false
                                } as IUpdateCloudGatewayQuerySettings}
                                onSubmit={async (values) => {
                                    const res = await callApi(cloudGatewayUpdateQuerySettings({...values, id: cloudGatewaySettings._id}));
                                    if (!res || !res.success) return;
                                    await reloadCloudGatewaySettings();
                                }}
                            >
                                {
                                    formik => (
                                        <Form className="d-flex flex-column gap-2 card p-2">
                                            <div className="d-flex flex-row align-items-center gap-3 w-100">
                                                <FieldWithLabel name="clientNumberFrom" className="w-100" label="Mandantennummer Von" />
                                                <FieldWithLabel name="clientNumberTo" className="w-100" label="Mandantennummer Bis" />
                                            </div>
                                            <FieldWithLabel name="consultantIds" label="Beraternummern (mit Semikolon getrennt)" />
                                            <CheckBox name="importInactiveData" label="Inaktive Daten auch importieren" />
                                            <Button type="submit" text="Speichern" disabled={!formik.dirty} loading={formik.isSubmitting} />
                                        </Form>
                                    )
                                }
                            </Formik>
                        </Accordion>
                    )
                }
                {
                    cloudGatewaySettings && (
                        <Flex row justify="between" fullWidth>
                            <Button 
                                onClick={async () => {
                                    const res = await callApi(cloudGatewayRunImport({id: cloudGatewaySettings._id}), true);
                                    if (!res || !res.success) return;
                                    
                                    setSearchParams(QueryParams.ImportId.createNewValue(res.data.id, searchParams));
                                    if (afterComplete) afterComplete();
                                    
                                }} 
                                text="Abruf manuell starten" 
                                icon="play" 
                                color="success" 
                            />
                            <Button 
                                onClick={async () => {
                                    const res = await callApi(cloudGatewayDeleteSettings({id: cloudGatewaySettings._id}), true);
                                    if (!res || !res.success) return;
                                }} 
                                secondStepQuestion="Sicher trennen?"
                                text="Verbindung trennen" 
                                icon="trash" 
                                color="error" 
                            />
                        </Flex>
                    )
                }
            </Flex>
        </WithPermissions>
    )
}