import useSWR from "swr";
import { IAddressee, IAddresseeRelationship, IAlias } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { ICollection } from "../../../types/collection.types";

export const useAddresseeRelationships = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<IAddresseeRelationship>>(ApiRoutes.Clients.Relationships);

    return {
        addresseeRelationshipsById: data?.byId ?? {},
        addresseeRelationships: data?.items ?? [],
        loadingAddresseeRelationships: isLoading,
        reloadAddresseeRelationships: mutate
    }
} 