import useSWR from "swr";
import { IAddressee, IAlias, IDatevAreaOfResponsibility } from "../../../types/ApiTypes";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { ICollection } from "../../../types/collection.types";

export const useAreasOfResponsibility = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<IDatevAreaOfResponsibility>>(ApiRoutes.Employees.AreasOfResponsibility);

    return {
        areasOfResponsibility: data?.items ?? [],
        areasOfResponsibilityById: data?.byId ?? {},
        loadingAreasOfResponsibility: isLoading,
        reloadAreasOfResponsibilities: mutate
    }
}