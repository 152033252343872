import React from "react";
import useClientUtil from "../../hooks/useClientUtil";
import useUserUtil from "../../hooks/useUserUtil";
import { useAddressees } from "../../state/swr/addressees/useAddressees";
import { useClients } from "../../state/swr/clients/useClients";
import { IClient } from "../../types/client.schema";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import Table from "../tables/Table";
import ClientImportProgress from "./ClientImportProgress";
import ClientListItem from "./ClientListItem";
import { getModel } from "../../util/mongoUtil";

export default function ClientList() {

    const { clients, loadingClients } = useClients();

    const {
        addresseesById
    } = useAddressees();

    const {
        getTypeNameOfClient,
    } = useClientUtil();

    const {
        getName,
        getMail
    } = useUserUtil();

    return (
        <WithPermissions permissions={[ "clients.all.read" ]}>
            {
                loadingClients 
                ? <LoadingSpinner centered={false} />
                : (
                    <>
                        
                        {
                            clients
                            ? (
                                <Table 
                                    border 
                                    className="w-100" 
                                    headers={[
                                        {
                                            label: "Mandant",
                                            filterItem: (c, filter) => {
                                                if (c.name.toLowerCase().includes(filter.toLowerCase())) return true;
                                                if (c.number.toString().includes(filter)) return true;
                                                return getTypeNameOfClient(c)?.toLowerCase().includes(filter.toLowerCase())
                                            }
                                        },
                                        {
                                            label: "Addressaten",
                                            filterItem: (c, filter) => {
                                                if (!c.naturalPerson && !c.legalPerson) return false;

                                                const naturalPerson = getModel(c.naturalPerson, addresseesById);
                                                const legalPerson = getModel(c.legalPerson, addresseesById);

                                                if (naturalPerson && naturalPerson.actualName && naturalPerson.actualName.toLowerCase().includes(filter.toLowerCase())) return true;
                                                if (legalPerson && legalPerson.actualName && legalPerson.actualName.toLowerCase().includes(filter.toLowerCase())) return true;

                                                return false;
                                            }
                                        }, 
                                        {
                                            label: "Ansprechpartner",
                                            filterItem: (c, filter) => {
                                                if (!c.clientManager) return false;

                                                const name = getName(c.clientManager);
                                                const mail = getMail(c.clientManager);

                                                if (name && name.toLowerCase().includes(filter.toLowerCase())) return true;
                                                if (mail && mail.toLowerCase().includes(filter.toLowerCase())) return true;

                                                return false;
                                            }
                                        }, 
                                        "Verantwortungen",
                                        "Alias",
                                        "Aktionen"
                                    ]}
                                    renderItem={(c: IClient) => <ClientListItem key={c._id} client={c} />}
                                    items={clients}
                                />
                            )
                            : <span>Keine Mandate</span>
                        }
                    </>
                )
            }
        </WithPermissions>
        
    )
}