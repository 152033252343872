import Stripe from "stripe"
import { TypedSchema } from "yup/lib/util/types";
import { IWithSupervisor } from "./user.types";
import { IClient } from "./client.schema";
import { IGlobalMailTemplate, ITemplateElement, MailTemplate } from "./globalMailTemplates.schema";
import { Gender } from "./salutation.schema";

export type DbId<T> = T | string | undefined | null;

export interface IMongooseDocument {
    _id: string,
    createdAt?: string | number | Date,
    updatedAt?: string | number | Date,
}


export interface ISessionState {
    sessionToken: string | null,
    session: ISession | null
}

export enum MailLogType {
    Incoming,
    Outgoing
}

export enum MailSource {
    SendGrid,
    IMAP,
    MsOffice
}

export enum MailLogSourceCreator {
    Schedule,
    Manual
}

export interface IMailLogImapNotifier {
    notifierId: string;
    createdOn: Date;
    createdBy: MailLogSourceCreator;
    accountId: string;
}

export interface IAdditionalMailLogData {
    runningNotifierCount: number,
    runningNotifiers: Array<IMailLogImapNotifier>,
    sourceCreatedOn: Date,
    sourceCreator: MailLogSourceCreator,
    queueLength: number,
}

export enum MailRejectReason {
    ExistsInLog,
    ExistsInQueue,
    BlacklistedSender,
    BlacklistedRecipient,
    NoSubject,
    NoRecipient,
    NoContent,
    NoSender,
    HigherPrioritySystemRecipientsExist,
    SentToDuplicateNotifier,
    ErrorWhileSending,
    MailSenderDisconnected,
    Other
}

export interface IMailLog extends IMongooseDocument {
    tenant: ITenant,
    mailAccount: IImapAccount,
    rejectMessage: string,
    subject: string,
    fromMail: string,
    fromName: string,
    cc: Array<string>,
    toMail: string,
    sentAt: Date,
    ticket: ITicket,
    content: string,
    source: MailSource,
    type: MailLogType,
    rejected: boolean,
    rejectReason: MailRejectReason,
    alreadyProcessedItem: IMailLog,
    eventId: string,
    sourceId: string,
    additonalData: IAdditionalMailLogData
}

export interface ICloudGatewayQueryOptions {
    clientNumberFrom: string,
    clientNumberTo: string,
    consultantIds: string,
    importInactiveData: boolean
}

export interface ICloudGatewayData extends IMongooseDocument, ICloudGatewayLogIn {
    options: ICloudGatewayQueryOptions,
    tenant: ITenant
}

export interface ICloudGatewayLogIn {
    username: string,
    password: string
}

export enum AutoTlsSetting {
    Always = "always",
    Required = "required",
    Never = "never"
}

export interface IMailAccountData {
    username: string,
    password: string,
    XOAuth?: string,
    xOAuth2?: string,
    host: string,
    port: number,
    tls?: boolean,
    tlsOptions?: Object,
    autotls?: AutoTlsSetting,
    connectionTimeout?: number,
    authTimeout?: number,
    socketTimeout?: number,
    preventServerIdleInterval?: number,
    sendClientIdleInterval?: number,
    forceNoOp?: boolean,
}

export interface IImapAccount extends IMongooseDocument {
    tenant?: ITenant,
    createdBy?: IUser,
    mailAddress: string,
    incomingServer: IMailAccountData,
    outgoingServer?: IMailAccountData
}

export interface IDocumentClearanceReminder extends IMongooseDocument {
    isDone: boolean,
    doneOn: Date,
    settings: IDocumentClearanceSettings,
    year: number,
    month: number,
    nextReminderOn: Date,
    previousReminderOn: Date,
    lastReminderOn: Date,
    timesReminded: number
}

export interface IDocumentClearanceLog extends IMongooseDocument {
    reminder: IDocumentClearanceReminder,
    sentOn: Date,
    success: boolean,
    message?: string,
    sentTo: string
}

export enum DocumentClearanceReminderInterval {
    Monthly = "monthly",
    Quarterly = "quarterly",
    Yearly = "yearly"
}

export interface IDocumentClearanceSettings extends IMongooseDocument {
    reminderMailCount: number,
    reminderMailDaysInterval: number,
    remindOnDayOfMonth: number,
    interval: DocumentClearanceReminderInterval,
    tenant: ITenant,
    client: IClient,
    clearingUser: IUser
}

export interface IDomainRequiredDnsEntries {
    type: string,
    name: string
    value: string,
}


export interface ITenantAuthority extends IWithSupervisor {
    _id?: string,
    tenant: ITenant,
    teams?: ITeam[],
    defaultTeam?: ITeam,
    clients?: IClient[],
    defaultClient?: IClient,
    isTenantAdmin: boolean,
    role?: IRole,
    vacations: IVacation[]
}


export interface IInvoice extends IMongooseDocument {
    tenant: ITenant,
    url: string,
    status: Stripe.Invoice.Status,
    amount: number,
    currency: string,
    periodStart: Date,
    periodEnd: Date
}

export enum UserRole {
    Client = "Mandant",
    Secretary = "Sekretariat",
    Clerk = "Sachbearbeiter",
    TeamLead = "Teamleiter",
    Partner = "Partner"
}

export interface IDomain extends IMongooseDocument {
    domain: string,
    protocol: string,
    usePort: boolean,
    isBaseDomain: boolean,
    tenant: ITenant,
    portNumber: number,
    isVerified: boolean,
    verificationExpires: Date,
    flaggedForDeletion: boolean,
    proxyId: string,
    dnsEntries: IDomainRequiredDnsEntries[]
}

export interface IFileMetadata {
    size: number,
    name: string,
    type: string,
}

export interface IAttachment extends IFileMetadata {
    googleCloudFileId: string
}

export interface ITenantTheme {
    primaryColor: string,
    logo: string,
    icon: string
}

export enum TenantLogoStyle {
    LogoAndText,
    TextOnly,
    LogoOnly
}

export enum DocumentClearanceReminderType {
    TextOnly = "textOnly",
    LinkAndText = "linkAndText"
}

export interface ITenantInterfaceSettings {
    datev: {
        canCreateClientContactsManually: boolean,
        overwriteUserDataOnImport: boolean,
        defaultResponsibilityForClientManager: string
    }
}

export interface ITenantSettings {
    options: {
        usersReceiveUnsafeAttachmentsPerMail: boolean,
        sendMailsPreferrablyWithPersonalAccounts: boolean,
        dontSendTicketErrorMailsWithPersonalAccount: boolean,
        dontSendTicketErrorMails: boolean,
        sendTicketMailsToClients: boolean,
        documentClearance: {
            setAsClearedOnLinkClick: boolean,
            defaultReminderDayOfMonth: number,
            defaultReminderCount: number,
            defaultReminderInterval: number,
            reminderType: DocumentClearanceReminderType
        },
        interfaces: ITenantInterfaceSettings
    },
    mails: {
        sendTicketOpenedMail: boolean,
        defaultSalutation: string
    },
    tickets: {
        maxMinutesToFirstResponse: number,
        sendMessageHistoryWithEveryTicketMail: boolean,
        escalationSupervisor: string
    },
    ui: {
        sidebar: {
            logoStyle: TenantLogoStyle
        }
    },
    titles: {
        siteHeader: string,
        tenantWord: string,
        tenantsWord: string,
        documentClearanceWord: string
    },
    links: {
        privacyPolicy: string,
        imprint: string
    },
    theme: ITenantTheme
}

export interface IDatevDocument {
    id: string
}

export enum DatevAddresseeGender {
    Male = "male",
    Female = "female",
    Diverse = "diverse"
}

export enum DatevAddresseeType {
    NaturalPerson = "natural_person",
    LegalPerson = "legal_person"
}

export enum DatevAddresseeNationalRight {
    Germany = "DE",
    Austria = "AT"
}

export enum DatevAddresseeWindingUpType {
    InAdministrationShort = "IA",
    InLiquidationShort = "IL",
    InAdministration = "INABW",
    InLiquidation = "INLIQ"
}

export enum DatevAddresseeAddressType {
    Street = "street",
    PostOfficeBox = "post_office_box",
    CorporateClient = "corporate_client"
}

export enum DatevAddresseeCommunicationType {
    Phone = "phone",
    Email = "email",
    Url = "url",
    Fax = "fax",
    Other = "other"
}

export interface IDatevAddresseeAddress {
    _id: string,
    type: DatevAddresseeAddressType,
    city: string,
    country_code: string,
    postal_code: string,
    post_office_box: string,
    street: string,
    additional_correspondence_title: string,
    additional_delivery_text1: string,
    additional_delivery_text2: string,
    additional_shipping_information: string,
    address_appendix: string,
    address_manually_edited: string,
    is_address_manually_edited: string,
    note: string,
    valid_from: string,
    valid_to: string,
    currently_valid: boolean,
    is_correspondence_address: boolean,
    is_debitor_address: boolean,
    is_main_post_office_box_address: boolean,
    is_main_street_address: boolean,
    is_management_address: boolean
}

export interface IDatevAddresseeDetail {
    complimentary_close: string,
    correspondence_title: string,
    national_right: DatevAddresseeNationalRight,
    note: string,
    salutation: string,
    all_first_names: string,
    birth_name: string,
    current_consideration: string,
    country_of_birth: string,
    date_of_death: string,
    date_of_expiry: string,
    date_of_issue: string,
    degree: string,
    current_denomination: string,
    current_federal_state_of_natural_person: string,
    identification_number: string,
    issuing_authority: string,
    current_job_title: string,
    current_marital_status: string,
    name_prefix: string,
    nationality: string,
    paper_of_identity: string,
    pension_insurance_institute: string,
    place_of_birth: string,
    register_of_births_number: string,
    register_office_of_birth: string,
    social_security_number: string,
    title_of_nobility: string,
    current_code_of_classification_of_economic_activities_2008: string,
    current_description_of_classification_of_economic_activities_2008: string,
    current_mad_code_of_classification_of_economic_activities_2008: string,
    current_code_of_classification_of_economic_activities_2003: string,
    current_description_of_classification_of_economic_activities_2003: string,
    current_mad_code_of_classification_of_economic_activities_2003: string,
    current_country_of_head_office: string,
    date_of_memorandum_of_association: string,
    current_distribution_of_profit: string,
    current_enterprise_purpose: string,
    current_federal_state_mad_of_legal_person: string,
    current_federal_state_of_legal_person: string,
    current_fiscal_year: string,
    current_kind_of_register_court: string,
    current_location_of_head_office: string,
    current_name_of_register_court: string,
    current_registered_company_name: string,
    registration_date: string,
    current_registration_number: string,
    current_three_lined_company_name_first_line: string,
    current_three_lined_company_name_second_line: string,
    current_three_lined_company_name_third_line: string,
    current_two_lined_company_name_first_line: string,
    current_two_lined_company_name_second_line: string,
    winding_up_date: string,
    winding_up_proceedings: DatevAddresseeWindingUpType
}

export interface IDatevAddresseeCommunication {
    _id: string,
    type: DatevAddresseeCommunicationType,
    data_content: string,
    number_standardized: string,
    note: string,
    is_main_communication: boolean,
    is_management_phone: boolean
}

export interface IDatevAddresseeBankAccount {
    _id: string,
    bank_account_number: string,
    bank_code: string,
    bank_name: string,
    bic: string,
    country_code: string,
    differing_account_holder: string,
    iban: string,
    note: string,
    valid_from: string,
    valid_to: string,
    currently_valid: boolean,
    is_main_bank_account: boolean
}

export interface IDatevAddresseeTaxOffice {
    _id: string,
    country_code: string,
    note: string,
    tax_number: string,
    tax_number_certificated: string,
    tax_number_standardized: string,
    tax_office_name: string,
    tax_office_number: string,
    valid_from: string,
    valid_to: string,
    currently_valid: boolean,
    is_competent_for_operational_income_tax: boolean,
    is_competent_for_turnover_tax: boolean,
    is_competent_for_wage_tax: boolean
}

export interface IDatevAddresseeContactPerson {
    _id: string,
    addressee_id: string,
    department: string,
    display_name: string,
    function: string,
    note: string
}

export enum DatevDuplicateReason {
    Mail = "Email",
    Name = "Name"
}

export enum DatevDuplicateType {
    None,
    PossibleDuplicate,
    Duplicate
}

export interface IAddressee extends IMongooseDocument, IDatevDocument { 
    user: string,
    eu_vat_id_country_code: string,
    eu_vat_id_number: string,
    current_short_name: string,
    status: DatevStatus,
    surrogate_name: string,
    timestamp: string,
    type: DatevAddresseeType,
    date_of_birth: string,
    etin: string,
    firstname: string,
    sex: DatevAddresseeGender,
    current_surname: string,
    tax_identification_number: string,
    current_company_name: string,
    date_of_foundation: string,
    current_legal_form_id: string,
    detail: IDatevAddresseeDetail,
    addresses: Array<IDatevAddresseeAddress>,
    communications: Array<IDatevAddresseeCommunication>,
    bank_accounts: Array<IDatevAddresseeBankAccount>,
    tax_offices: Array<IDatevAddresseeTaxOffice>,
    contact_persons: Array<IDatevAddresseeContactPerson>,
    legalForm: string,
    relationships: Array<string>,
    isDuplicate: boolean,
    duplicateReason?: DatevDuplicateReason,
    originalMail?: string,
    actualName: string,
    isMissingMail: boolean,
    duplicateType?: DatevDuplicateType
}

export interface IAddresseeRelationship extends IMongooseDocument, IDatevDocument { 
    abbreviation: string,
    name: string,
    standard: boolean,
    type_id: string,
    has_addressee_id: string,
    has_addressee_display_name: string,
    has_addressee_type: DatevAddresseeType,
    is_addressee_id: string,
    is_addressee_display_name: string,
    is_addressee_type: DatevAddresseeType
    tenant: ITenant,
    relationshipType?: IDatevAddresseeRelationshipType
}

export interface IDatevAddresseeRelationshipType extends IMongooseDocument, IDatevDocument {
    abbreviation: string,
    name: string,
    standard: boolean,
    type: number
}

export enum DatevStatus {
    Active = "active",
    Inactive = "inactive"
}

export interface ICountryCode extends IMongooseDocument, IDatevDocument {     
    name: string
}

export enum DatevLegalFormType {
    IndividualEnterprise = 1,
    Partnership = 2,
    Limited = 3,
    Special = 4
}

export interface ILegalForm extends IMongooseDocument, IDatevDocument { 
    display_name: string,
    long_name: string,
    short_name: string,
    nation: string,
    type: DatevLegalFormType
    friendlyName: string
}

export interface IClientImportError {
    errorMessage: string,
    errorId: string
}

export interface IClientImportLogEntry extends IMongooseDocument {
    completedAt: Date,
    message: string,
    allErrors: Array<IClientImportError>,
    importingUser: IUser,
    tenant: ITenant,
    failed: boolean
}

export enum TenantStatus {
    Unverified,
    Onboarding,
    Active,
    AboutToBeDeleted,
    Blocked,
    OnboardingFinished
}

export enum TenantBlockReason {
    None,
    BlockedByAdmin,
    PaymentIssues
}

export interface ITenantAddress {
    firstLine: string,
    secondLine: string,
    postalCode: string,
    city: string,
    state: string,
    country: string,
    countryCode: string
}


export interface ITenantSubscription {
    id: string,
    status: string,
    name: string,
    current_period_end: Date,
    discount: string,
    ended_at: string,
    start: Date,
    trial_end: string
}

export enum TenantSubscriptionStatus {
    Active = "active"
}

export enum TenantDeleteReason {
    Manual,
    VerificationNotCompleted,
    OnboardingNotFinished,
    NoSubscription,
    Blocked
}

export interface ITenant extends IMongooseDocument {
    name: string,
    mailAccount: IImapAccount,
    address: ITenantAddress,
    company: string,
    subscriptions: Array<ITenantSubscription>,
    blockReason: TenantBlockReason,
    isGlobalBaseTenant: boolean,
    creationToken: string,
    willBeDeletedOn: Date,
    tenantCanBeDeleted: boolean,
    mailAddress: string,
    creationTokenValidUntil: Date,
    registrationPossibleUntil: Date,
    createdBy: IUser,
    onboardedBy: IUser,
    status: TenantStatus,
    domains: Array<IDomain | string>,
    settings: ITenantSettings
    hasPaymentDetails: boolean,
    activePlans: Array<IPlan>,
    deleteReason: TenantDeleteReason
}

export interface IPermission {
    create: boolean,
    read: boolean,
    update: boolean,
    delete: boolean,
}

export enum BaseRole {
    Secretary = "IR_SKR",
    Clerk = "IR_SBR",
    TeamLead = "IR_TLD",
    Partner = "IR_PTR",
    ClientContact = "IR_MDK"
}

// adjusting here requires adjusting validation methods as well
export interface IPermissions {
    tickets?: {
        unassigned?: IPermission,
        own?: IPermission,
        all?: IPermission,
        external?: IPermission,
        internal?: IPermission
    },
    domains?: {
        tenant?: IPermission,
    },
    users?: {
        employees?: IPermission,
        clientContacts?: IPermission
    },
    employeeResponsibilities?: {
        all?: IPermission,
    },
    absence?: {
        own?: IPermission,
        all?: IPermission
    },
    interfaces?: {
        datev?: IPermission,
        pointchamp?: IPermission,
        cloudGateway?: IPermission
    },
    inboxes?: {
        own?: IPermission,
        teams?: IPermission,
        tenant?: IPermission,
        all?: IPermission
    },
    templates?: {
        mails?: IPermission
    },
    clients?: {
        all?: IPermission,
        own?: IPermission
    },
    clientResponsibilities?: {
        all?: IPermission,
    },
    alias?: {
        all?: IPermission,
        own?: IPermission
    },
    documentClearance?: {
        all?: IPermission
    },
    addressees?: {
        all?: IPermission,
    },
    addresseeRelationships?: {
        all?: IPermission
    },
    countryCodes?: {
        all?: IPermission
    },
    legalForms?: {
        all?: IPermission
    },
    teams?: {
        own?: IPermission,
        all?: IPermission
    },
    statistics?: {
        own?: IPermission,
        team?: IPermission,
        tenant?: IPermission
    },
    tenant?: {
        own?: IPermission,
    },
    billing?: {
        tenant?: IPermission
    },
    roles?: {
        all?: IPermission
    },
    logs?: {
        api?: IPermission,
        email?: IPermission
    },
    tasks?: {
        own?: IPermission,
        team?: IPermission,
        all?: IPermission
    }
}

type Join<K, P> = K extends string | number ?
    P extends string | number ?
    `${K}${"" extends P ? "" : "."}${P}`
    : never : never;

type Paths<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: K extends string | number ?
// @ts-ignore
        `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never
    }[keyof T] : ""

type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
// @ts-ignore
    { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";

export type Permission = Leaves<IPermissions>;

export interface IRoleDocument {
    displayName: string,
    accessLevel: number,
    isClient: boolean,
    permissions: IPermissions,
    color?: string,
    isInitialRole?: boolean,
    initialRoleId?: BaseRole
}

export interface IRole extends IRoleDocument, IMongooseDocument {
}

export interface IVacation {
    from: Date,
    to: Date,
    deputy: IUser,
    _id?: string
}


export interface IUserSettings {
    mails: {
        defaultSalutation: string,
        receiveSystemNotifications: boolean,
        receivesAnyMail: boolean
    },
    absence: {
        sendAbsenceNotifications: boolean,
        absenceNotificationMail: string,
        assignTicketsToDeputy: boolean
    }
}

export interface IUser extends IMongooseDocument {
    mailNamePrefix: string,
    title: string,
    gender: Gender,
    mailAddress: string,
    phoneNumber: string,
    isPhoneNumberVerified: boolean,
    updatedBy: IUser,
    nextRequestForOtp?: Date,
    lastSeen: Date
    firstName: string,
    lastName: string,
    authority: [ITenantAuthority],
    isBlocked: boolean,
    isDeveloper: boolean,
    isSuperAdmin: boolean,
    isTestingUser: boolean,
    isVerified: boolean,
    settings: IUserSettings
}

export interface ITicketCategory extends IMongooseDocument {
    title: string
}

export interface ITicketMessageText {
    text: string,
    createdAt: Date
}

export enum TicketMessageSource {
    HTTP = "http",
    Mail = "mail",
}

export interface ITicketMessage extends IMongooseDocument {
    sentBy: IUser | string,
    senderMailAddress: string,
    isSystemMessage: boolean,
    ticketActivity: string,
    mentions: Array<string>,
    senderName: string,
    source: TicketMessageSource,
    attachments?: Array<IAttachment>,
    isRead?: boolean,
    isReadAt?: Date,
    isReadBy?: IUser,
    isSolution?: boolean
    isEdited: boolean,
    content: Array<ITicketMessageText>,
    ticket: string
}

export interface ITicketPriority extends IMongooseDocument {
    title: string,
    priority: number
}

export interface ITicketNoteItem {
    _id?: string,
    text: string,
    user: IUser,
    createdAt: string,
}

export interface IVersion {
    version: string
}

export interface ITicket extends IMongooseDocument {
    lastIncrementOfMinutesOpen: Date,
    isDeveloperTicket: boolean,
    minutesWithoutMessageFromCurrentAssignee: number,
    lastAssignmentChange: Date,
    isLinked: boolean,
    resubmitAt: Date,
    linkedWith: ITicket,
    linkedTickets: Array<ITicket>,
    hasMessageFromCurrentAssignee: boolean,
    currentEscalationWorkflow: ITicketEscalationWorkflow,
    closedAt: Date,
    friendlyId: string,
    minutesOpen: number,
    isArchived: boolean,
    tenant: ITenant | string,
    isDocked: boolean,
    typingUser?: IUser
    subject: string,
    internalSubject: string,
    openedBecause?: TicketOpeningReason,
    categories: ITicketCategory[],
    notes: ITicketNoteItem[],
    priority: ITicketPriority,
    openedBy: IUser,
    team: string,
    lastUpdate: Date,
    lastUpdateBy: IUser,
    waitingForClientResponse: boolean,
    messages: ITicketMessage[],
    state: TicketState,
    type: TicketType,
    source: TicketSource
}


export enum TicketSource {
    Web = "Web",
    Mail = "Mail",
    ManualFromMail = "ManualFromMail",
    iOS = "iOS",
    Android = "Android"
}

export enum TicketType {
    InternalTicket,
    TicketByClient,
    TicketToClient,
    ExternalTicket
}

export enum TicketOpeningReason {
    SentByClientContactMail = "SentByClientContactMail",
    SentByClientMail = "SentByClientMail"
}

export enum TicketState {
    Open = "Offen",
    Closed = "Geschlossen",
    Solved = "Gelöst",
    Archived = "Archiviert",
    Resubmission = "Wiedervorlage"
}

export enum SessionVerificationMethod {
    Phone = "phone",
    Mail = "mail",
    None = "none"
}

export interface ISession extends IMongooseDocument {
    isImposterSession: boolean,
    verificationMethod: SessionVerificationMethod,
    accessGranted: boolean
}

export interface IDatevAreaOfResponsibility extends IMongooseDocument {
    name: string
    id: string,
    description: string,
    standard: boolean,
    status: DatevStatus
}

export interface ITeamBase extends IWithSupervisor  {
    name: string,
    color: string,
}

export interface ITeam extends IMongooseDocument, ITeamBase {
    tenant: ITenant,
    mailAccount: IImapAccount,
    areaOfResponsibility: string,
    members: string[]
}

export interface IDatevEmployeeResponsibility extends IMongooseDocument {
    id: string,
    area_of_responsibility_id: string
    area_of_responsibility_name: string,
    employee_id: string,
    employee_display_name: string,
    employee_number: string,
    employee_status: DatevStatus,
    client_id: string,
    client_name: string,
    client_number: number,
    client_status: DatevStatus,
    tenant: ITenant,
    employee: string
}

export interface IAddress extends IMongooseDocument {
    street: string,
    postalCode: string,
    city: string,
    region: string,
    country: string
}

export interface ILegalForm extends IMongooseDocument {
    name: string
}

export interface ISettings extends IMongooseDocument {
    links: {
        faq: string
    }
}

export enum TiggiMode {
    Testing = "TST",
    Staging = "STG",
    Production = "PRD",
    Development = "DEV"
}

export enum CouponType {
    PercentOff,
    AmountOff
}
export interface ICoupon extends IMongooseDocument{
    name: string,
    type: CouponType,
    amount: number,
    duration: Stripe.Coupon.Duration,
    repeatsForMonths: number,
    maxRedemptions: number,
    validUntil: Date,
    canBeAppliedToAnything: boolean,
    appliesTo: Array<IPlan>
    stripeCouponId: string,
}


export interface IPromotionCode extends IMongooseDocument {
    coupon: ICoupon,
    stripePromotionCodeId: string,
    onlyForFirstTimeTransactions: boolean,
    minimumAmount: number,
    code: string,
    maximumRedemptions: number,
    limitToTenant: ITenant
}


export interface IPlanFeature {
    feature: IFeature,
    limit?: string,
    remarks?: string
}

export interface IPlan extends IMongooseDocument {
    name: string,
    description: string,
    price: number,
    billingInterval: Stripe.Price.Recurring.Interval,
    service: IService
    features: Array<IPlanFeature>
}

export enum TiggiFeature {
    CustomDomains,
    CustomMailTemplates,
}

export interface IFeature extends IMongooseDocument {
    name: string,
    feature: TiggiFeature
}

export interface IService extends IMongooseDocument {
    name: string,
    description?: string,
    isGlobalBaseSubscription: boolean
}

export interface IUploadableFile extends IFileMetadata {
    content: string
}

export interface IMailData {
    to: string,
    text?: string,
    html?: string,
    replyTo?: string,
    subject: string,
    fromName?: string,
    fromMail: string,
    attachments?: Array<IUploadableFile>,
}

export enum ExternalAccount {
    Office = "office",
    IMAP = "imap"
}

export interface IFeedbackAppliesTo {
    ticket?: string
}

export interface IFeedbackSource {
    user?: string,
    mail: string
    firstName: string,
    lastName: string,
    company: string,
    phone: string
}

export interface IFeedbackDocument {
    givenBy: IFeedbackSource,
    ratings: IFeedbackRatings,
    type: FeedbackType,
    comment: string,
    appliesTo: IFeedbackAppliesTo
}

export interface IFeedbackRatings {
    overall: number
}

export enum FeedbackType {
    General = "review", 
    Error = "error"
}

export interface IPointChampApiKey extends IMongooseDocument {
    lastFour: string,
    domain: string,
    tenant: ITenant,
    isVerified: boolean,
    lastUsed: Date,
    totalUses: number
}

export interface IPointChampVacation extends IMongooseDocument {
    dateFrom: Date,
    dateTo: Date,
    employeeMail: string,
    deputyMails: string[],
    vacation: IUserAbsence,
    tenant: ITenant
}
export enum AbsenceType {
    Vacation = "vacation",
    Sick = "sick",
}

export enum AbsenceSource {
    Web = "web",
    PointChamp = "pointchamp"
}

export interface IUserAbsence extends IMongooseDocument {
    user: IUser,
    createdBy?: IUser,
    from: Date,
    to: Date,
    type: AbsenceType,
    source: AbsenceSource,
    deputy?: IUser
}

export interface IUserClientsDocument {
    clients?: IClient[],
    defaultClient?: IClient,
}

export interface IUserClients extends IUserClientsDocument, IMongooseDocument {
    tenant: ITenant,
    user: IUser,
}

export interface IUserDefaultTeamsDocument {
    defaultTeam?: ITeam
}

export interface IUserDefaultTeams extends IMongooseDocument, IUserDefaultTeamsDocument {
    user: IUser,
}

export interface IUserProfilePictureDocument {
    small: string,
}

export interface IUserProfilePicture extends IMongooseDocument, IUserProfilePictureDocument {
    user: IUser,
    tenant: ITenant
}

export interface IOpeningHours {
    fromHour: number,
    fromMinute: number,
    toHour: number,
    toMinute: number,
}

export interface ITenantOpeningHoursDocument {
    onVacation: boolean,
    onVacationUntil: Date,
    sendOnVacationMail: boolean,
    monday: IOpeningHours[],
    tuesday: IOpeningHours[],
    wednesday: IOpeningHours[],
    thursday: IOpeningHours[],
    friday: IOpeningHours[],
    saturday: IOpeningHours[],
    sunday: IOpeningHours[]
}

export interface ITenantOpeningHours extends IMongooseDocument, ITenantOpeningHoursDocument { 
    tenant: ITenant
}

export enum TicketEscalationTrigger {
    MinutesWithoutAssigneeMessage = "minutesWithoutAssigneeMessage",
    MinutesWithoutClientResponse = "minutesWithoutClientResponse",
    MinutesOpen = "minutesOpen",
}

export enum TicketEscalationAction {
    MailToSupervisor = "mailToSupervisor",
    EscalateTicket = "escalateTicket",
    MailToClient = "mailToClient",
    CloseTicket = "closeTicket"
}

export interface ITicketEscalationAction {
    action: TicketEscalationAction,
}

export interface ITicketEscalationworkflowDocument {
    trigger: TicketEscalationTrigger,
    threshhold: number,
    marksTicketAsEscalated: boolean,
    actions: Array<ITicketEscalationAction>,
    mailTemplate: IMailTemplate
}

export interface ITicketEscalationWorkflow extends IMongooseDocument, ITicketEscalationworkflowDocument {
    tenant: ITenant
}

export interface IUserTicketAccess {
    start: Date,
    wasAssignedToTicketAtStart: boolean,
    minutesLength: number
}

export interface ITicketAccessHistoryItem {
    user: IUser,
    history: IUserTicketAccess[],
    totalTimeAccessed: number
}

export interface ITicketAccessHistoryDocument {
    totalTimeAccessed: number,
    access: ITicketAccessHistoryItem[]
}

export interface ITicketAccessHistory extends ITicketAccessHistoryDocument, IMongooseDocument {
    ticket: ITicket,
    tenant: ITenant
}

export interface ITicketAssignmentDocument {
    user?: IUser,
    client?: IClient,
    mailAddress?: string,
    isCc?: boolean,
    isActive?: boolean,
    isMainClientContact?: boolean,
    isMainAssignee?: boolean
}

export interface ITicketAssignment extends IMongooseDocument, ITicketAssignmentDocument {
    mailAddressInvariant: string,
    deputy: IUser,
    ticket: ITicket,
    tenant: ITenant
}

export interface ITicketEscalationLog extends IMongooseDocument {
    escalationWorkflow: ITicketEscalationWorkflow,
    currentTicketMinutes: number,
    status: TicketEscalationLogStatus,
    message: string,
    source: TicketEscalationSource
}

export enum TicketEscalationSource {
    Manual = "manual",
    System = "system"
}

export enum TicketEscalationLogStatus {
    Success = "success",
    Failure = "failure"
}

export interface ITicketStatisticsDocument {
    durations: {
        minutesUntilFirstMessage: number
    },
    states: {
        reOpenedAfterCloseCount: {
            byClient: number,
            byClerk: number
        }
    }
}

export interface ITicketStatistics extends IMongooseDocument, ITicketStatisticsDocument {
    ticket: ITicket,
    tenant: ITenant,
    user: IUser
}

export interface IUserStatisticsForTeam  {
    statistics: IUserStatisticsDocument,
    team: ITeam
}

export interface IUserStatisticsCounts {
    closedTicketsPastWeek: number,
    openTicketsCurrently: number,
    unansweredTickets: number,
    ticketsInResubmission: number,
    ticketsInEscalation: number
}

export interface IUserStatisticsDocument {
    tickets: {
        counts: IUserStatisticsCounts
    }
}

export interface IUserStatistics extends IMongooseDocument, IUserStatisticsDocument {
    user: IUser
}

export enum AliasType {
    User = "user",
    Client = "client"
}

export interface IAliasContent {
    aliasMailAddress: string
}

export interface IAliasDocument extends IAliasContent {
    aliasMailAddressInvariant: string,
    type: AliasType,
    user?: IUser,
    client?: IClient
}

export interface IAlias extends IMongooseDocument, IAliasDocument { }

export interface IMailTemplateContent {
    subject: Array<ITemplateElement>,
    body: Array<ITemplateElement>,
    templateType: MailTemplate
}

export interface IMailTemplateDocument extends IMailTemplateContent {
    baseTemplate?: IGlobalMailTemplate
}

export interface IMailTemplate extends IMongooseDocument, IMailTemplateDocument { }

export interface ISignatureDocument {
    contentOnNewMail: string,
    contentOnReply: string,
    useSameSignatureOnReply: boolean,
    useSignatureOnForward: boolean,
    isActive: boolean
}

export interface ISignature extends IMongooseDocument, ISignatureDocument {
}

export enum CdpAgreement {
    First = "27-09-2023"
}

export interface ICdpAgreement extends IMongooseDocument  {
    consentedByName: string,
    consentedByEmail: string,
    consentedByUser: IUser,
    consentedAt: Date,
    consentedVersion: CdpAgreement
}

export interface ITaskDocument {
    subject: string,
    description?: string,
    dueDate: Date,
    ticket: ITicket,
    assignedTo: Array<IUser>,
    sendReminder: boolean
}

export interface ITask extends ITaskDocument, IMongooseDocument {
    createdBy: IUser,
    completed: boolean,
    completedDate: Date,
    completedBy: IUser
}


export enum ImportValidationErrorType {
    DuplicateMail = "duplicateMail",
    PossibleDuplicateMail = "possibleDuplicateMail",
    DuplicateName = "duplicateName",
}

export interface IValidationErrorEntities {
    client?: string,
    employee?: string,
    addressee?: string
}

export interface IValidationErrorDocument extends IValidationErrorEntities {
    checked: boolean,
    reason: ImportValidationErrorType
    checkedBy: IUser,
    checkedAt: Date
}

export interface IImportValidationError extends IValidationErrorDocument, IMongooseDocument {
}
