import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IClientImportLogEntry } from "../../../types/ApiTypes";

export const useClientImportLog = (id: string) => {
    const { data, isLoading, mutate } = useSWR<IClientImportLogEntry>(ApiRoutes.ClientsImportLog.GetById.getValue({
        param: RouteParam.ImportLogId,
        value: id
    }));

    return {
        clientImportLog: data,
        loadingClientImportLog: isLoading,
        reloadClientImportLog: mutate
    }
}