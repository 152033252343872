import { ErrorMessage, Field, FieldAttributes } from "formik";
import { generateClassName } from "../../hooks/useAttributes";
import React from "react";
import IElementProps from "../../types/ElementProps";
import Typography from "../text/Typography";
import "./FormikField.css";
import { AppColor } from "../../app/AppStyles";

interface IFormikFieldProps extends IElementProps {
    label?: string,
    placeholder?: string,
    inputClass?: string,
    min?: string,
    max?: string,
    step?: string,
    rounded?: boolean,
    name: string,
    noMargin?: boolean,
    labelColor?: AppColor,
    labelColorBasedOnBackground?: AppColor,
    as?: string,
    input?: React.ReactNode,
    readOnly?: boolean,
    bold?: boolean,
    type?: string,
    inline?: boolean,
    noHover?: boolean,
    onBlur?: () => void,
    onFocus?: () => void
}

export default function FormikField(props: IFormikFieldProps) {
    
    const {
        label, 
        rounded, 
        labelColor = "primary", 
        labelColorBasedOnBackground, 
        inline, 
        noHover,
        readOnly, 
        input, 
        min, 
        max, 
        onFocus, 
        onBlur, 
        step, 
        as, 
        type, 
        placeholder, 
        className, 
        inputClass, 
        name, 
        bold, 
        style,
        children
    } = props;

    const fieldId = `${name}field`;

    const controlClass = generateClassName(inputClass, "form-control w-100 field-with-label", {
        value: rounded,
        onTrue: "field-with-label-rounded"
    }, {
        value: noHover || readOnly,
        onTrue: "field-no-hover"
    });

    const containerClass = generateClassName(className, "d-flex", {
        value: inline,
        standard: "flex-column align-items-start gap-1",
        onTrue: "flex-row align-items-center gap-3"
    });

    return (
        <div className={containerClass}>
            {
                label && <Typography bold={bold} truncate={false} color={labelColor} basedOnThisBackground={labelColorBasedOnBackground} >{label}</Typography>
            }
            <div className="position-relative w-100">
                {
                    input || (
                        <Field 
                            style={style}
                            onBlur={onBlur} 
                            onFocus={onFocus} 
                            as={as} 
                            disabled={readOnly} 
                            min={min} 
                            max={max} 
                            step={step} 
                            id={fieldId} 
                            name={name} 
                            type={type} 
                            placeholder={placeholder || label} 
                            className={controlClass} 
                        />
                    )
                }
                { children }
            </div>
            <ErrorMessage name={name} render={(msg) => <span className="mt-2" style={{color: "#FF0000"}}>{msg}</span>} />
        </div>
    )
}