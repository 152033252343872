
export enum NavGroup {
    None,
    PersonalSpace,
    TheOffice,
    MyPortal,
    Administration,
    API
}

export enum NavTab {
    Dashboard,
    Inbox,
    Tickets,
    Clients,
    Addressees,
    DocumentClearanceOverview,
    ClientContacts,
    Employees,
    MailTemplates,
    Teams,
    ClientResponsibility,
    TenantDomains,
    Billing,
    RoundMail,
    AllUsers,
    MailManagement,
    AllDomains,
    Tenants,
    Services,
    Log,
    MailLog,
    Help,
    Settings,
    RoleManagement
}
