import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { ITicketActivity } from "../../../types/ticketActivity.schema";

export const useNotifications = () => {
    const { data, isLoading, mutate } = useSWR<Array<ITicketActivity>>(ApiRoutes.User.Notifications.All, {
        revalidateOnFocus: false
    });

    return {
        notifications: data,
        loadingNotifications: isLoading,
        reloadNotifications: mutate
    }
}