import React from "react";
import ClientList from "../../components/clients/ClientList";
import DeleteClientsButton from "../../components/clients/DeleteClientsButton";
import DatevImportDialog from "../../components/datev/DatevImportDialog";
import WithPermissions from "../../components/permissions/WithPermissions";
import Page from "../../layout/Page";
import { Locale } from "../../locale/Locale";
import TabSwitcher from "../../components/tabswitcher/TabSwitcher";
import ClientImport from "../../components/clients/ClientImport";
import ClientImportProgress from "../../components/clients/ClientImportProgress";

export enum ClientManagementTab {
    Clients = "clients",
    Import = "import"
}

export default function ClientManagement() {

    const [tab, setTab] = React.useState<ClientManagementTab>(ClientManagementTab.Clients);

    return (
        <WithPermissions permissions={[ "clients.all.read" ]}>
            <Page header={Locale.titles.clientManagement} actionButtons={[<DeleteClientsButton key="delete-clients-button" />]}>
                <WithPermissions permissions={[ "interfaces.datev.update", "interfaces.cloudGateway.update" ]} >
                    <TabSwitcher 
                        tabQueryParamKey="show"
                        tabs={[
                            { data: ClientManagementTab.Clients, label: "Alle Mandate" },
                            { data: ClientManagementTab.Import, label: "Import & Schnittstellen" }
                        ]}
                        saveActiveTab={t => setTab(t as ClientManagementTab)}
                    />
                </WithPermissions>
                <ClientImportProgress />
                {
                    tab === ClientManagementTab.Clients
                    ? <ClientList />
                    : <ClientImport />
                }
            </Page>
        </WithPermissions>
    )
}