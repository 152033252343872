import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IMailLog } from "../../../types/ApiTypes";

export const useMailLog = () => {
    const { data, isLoading, mutate } = useSWR<Array<IMailLog>>(ApiRoutes.MailLog.GetAll);

    return {
        mailLogs: data,
        loadingMailLogs: isLoading,
        reloadMailLogs: mutate
    }
}