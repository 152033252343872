import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IAddressee } from "../../../types/ApiTypes";
import { ICollection } from "../../../types/collection.types";

export const useAddressees = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<IAddressee>>(ApiRoutes.Addressees.All);

    return {
        addressees: data?.items ?? [],
        addresseesById: data?.byId ?? {},
        loadingAddressees: isLoading,
        reloadAddressees: mutate
    }
} 