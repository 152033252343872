import React from "react";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { ITicket, TicketState } from "../../../types/ApiTypes";
import { formatDateTiggiStyle } from "../../../util/formatter";
import Flex from "../../container/Flex";
import LoadingSpinner from "../../loader/LoadingSpinner";
import Typography from "../../text/Typography";
import TicketFriendlyIdBanner from "../banner/TicketFriendlyIdBanner";
import TicketLastReplyStateIcon from "../banner/TicketLastReplyStateIcon";
import TicketTypeBanner from "../banner/TicketTypeBanner";
import UpdateTicketStatusButton from "../status/UpdateTicketStatusButton";

export interface ITicketCardHeaderProps {
    ticket: ITicket,
    hover?: boolean,
    onHoverOverAction?: () => void,
    onStopHoverOverAction?: () => void,
    loading?: boolean
}

export default function TicketCardHeader(props: ITicketCardHeaderProps) {

    const {
        ticket, 
        onHoverOverAction,
        onStopHoverOverAction,
        loading, 
        hover
    } = props;

    // const { ticketCurrentUserAssignment } = useTicketCurrentUserAssignment(ticket);
    const { role } = useCurrentTenantRole();

    const isClient = !!role && role.isClient;

    return (
        <Flex row justify="between" wrap className="w-100" style={{minHeight: "28px"}}>
            <Flex row>
                <Flex row gap={1}>
                    { loading && <LoadingSpinner color="secondary" />}
                    {/* {
                        ticketCurrentUserAssignment && ticketCurrentUserAssignment.isCc && (
                            <>
                                <Pill color="#A31200" className="pt-0 pb-0 ps-1 pe-1"><Typography color="bright" size={12}>CC</Typography></Pill>
                                <Typography bold>|</Typography>
                            </>
                        )
                    } */}
                    <TicketFriendlyIdBanner ticket={ticket} bold size={12} />
                    {
                        !isClient && (
                            <>
                                <Typography bold>|</Typography>
                                <TicketTypeBanner ticket={ticket} />
                            </>
                        )
                    }
                </Flex>
                <TicketLastReplyStateIcon ticket={ticket} />
            </Flex>
            <div 
                className="d-flex flex-row align-items-center ms-auto gap-1"
                onMouseEnter={() => onHoverOverAction && onHoverOverAction()}
                onMouseLeave={() => onStopHoverOverAction && onStopHoverOverAction()}
            >
                {
                    hover
                    ? (
                        <>
                            <UpdateTicketStatusButton size="tiny" tickets={[ticket]} isSingleTicket state={TicketState.Open} variant="text" />
                            <UpdateTicketStatusButton size="tiny" tickets={[ticket]} isSingleTicket state={TicketState.Resubmission} variant="text" />
                            <UpdateTicketStatusButton size="tiny" tickets={[ticket]} isSingleTicket state={TicketState.Solved} variant="text" />
                            <UpdateTicketStatusButton size="tiny" tickets={[ticket]} isSingleTicket isDelete variant="text" />
                        </>
                    )
                    : <Typography size={12}>{formatDateTiggiStyle(ticket.lastUpdate)}</Typography>
                }
            </div>
        </Flex>
    )
}
