import React from "react";
import { ticketAssignmentsAdd, ticketAssignmentsUpdate } from "../../../api/Api";
import { ITicketAssignmentAddRequest, ITicketAssignmentUpdateRequest } from "../../../api/ApiRequests";
import { AppColor } from "../../../app/AppStyles";
import useApi from "../../../hooks/useApi";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import useUserUtil from "../../../hooks/useUserUtil";
import { useTicketActivity } from "../../../state/swr/tickets/useTicketActivity";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";
import { useTicketAssignments } from "../../../state/swr/tickets/useTicketAssignments";
import { useTicketClientContact } from "../../../state/swr/tickets/useTicketClientContact";
import { useTicketMessages } from "../../../state/swr/tickets/useTicketMessages";
import { useUsers } from "../../../state/swr/user/useUsers";
import { ITicket, ITicketAssignment, IUser } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import ClientSelect from "../../clients/ClientSelect";
import Flex from "../../container/Flex";
import CheckBox from "../../formik/CheckBox";
import FormikField from "../../formik/FormikField";
import Icon from "../../icons/Icon";
import ModalForm from "../../modal/ModalForm";
import WithPermissions from "../../permissions/WithPermissions";
import Pill from "../../pills/Pill";
import Typography from "../../text/Typography";
import UserSelect from "../../user/UserSelect";

export interface IUpdateTicketAssignmentFormProps {
    ticket: ITicket,
    assignment?: ITicketAssignment
}

export default function UpdateTicketAssignmentForm({ ticket, assignment }: IUpdateTicketAssignmentFormProps) {

    const permissions = useTicketPermission("update", ticket);

    const { reloadTicketAssignee } = useTicketAssignee(ticket);
    const { reloadTicketClientContact } = useTicketClientContact(ticket);
    const { reloadTicketAssignments } = useTicketAssignments(ticket);
    const { reloadMessages } = useTicketMessages(ticket._id);   
    const { reloadTicketActivity } = useTicketActivity(ticket);

    const {
        getCurrentTenantRole
    } = useUserUtil();
    
    const callApi = useApi();

    const text = assignment ? "Zuweisung bearbeiten" : "Neue Zuweisung";
    const buttonText = assignment ? "Bearbeiten" : "Neue Zuweisung";
    const buttonIcon = assignment ? "pen" : "plus";
    const buttonColor: AppColor = assignment ? "primary" : "success";

    if (assignment && !assignment.isActive) return null;
    
    return (
        <WithPermissions permissions={permissions}>
            <ModalForm 
                initialValues={{
                    id: assignment?._id ?? "",
                    clientId: assignment?.client?._id ?? "",
                    isCc: assignment?.isCc ?? true,
                    isMainAssignee: assignment?.isMainAssignee ?? false,
                    isMainClientContact: assignment?.isMainClientContact ?? false,
                    ticketId: ticket._id,
                    userId: assignment?.user?._id ?? "",
                    mailAddress: assignment?.mailAddress ?? "",
                    isActive: assignment?.isActive ?? true
                } as ITicketAssignmentUpdateRequest}
                title={text}
                button={<Button variant="text" icon={buttonIcon} text={buttonText} color={buttonColor} />}
                onSubmit={async (values) => {
                    const res = assignment 
                    ? await callApi(ticketAssignmentsUpdate(values as ITicketAssignmentUpdateRequest))
                    : await callApi(ticketAssignmentsAdd(values as ITicketAssignmentAddRequest));

                    if (!res || !res.success) return false;
                    
                    await Promise.all([
                        reloadTicketAssignee(),
                        reloadTicketAssignments(),
                        reloadTicketClientContact(),
                        reloadMessages(),
                        reloadTicketActivity()
                    ]);
                    
                    return true;
                }}
            >
                {
                    formik => {

                        const changeUser = (u: IUser | undefined) => {
                            formik.setFieldValue("userId", u ? u._id : undefined);
                            formik.setFieldValue("clientId", undefined);
                            formik.setFieldValue("mailAddress", u ? u.mailAddress : undefined);
                        }

                        const role = getCurrentTenantRole(formik.values.userId);

                        const isClient = role && role.isClient;

                        return (
                            <Flex fullWidth>
                                {
                                    !formik.values.userId && (
                                        <FormikField 
                                            label="E-Mail"
                                            name="mailAddress"
                                            className="w-100"
                                        />
                                    )
                                }
                                <UserSelect 
                                    displayed={formik.values.isMainAssignee ? "employees" : "all"}
                                    onChange={changeUser}
                                    value={formik.values.userId}
                                    label="Nutzer"
                                    className="w-100"
                                />
                                <Flex row gap={4}>
                                    {
                                        (assignment?.isMainClientContact || (formik.values.userId && isClient)) && <CheckBox name="isMainClientContact" readOnly={assignment?.isMainClientContact} label="Mandantenkontakt des Tickets" className="w-100" />
                                    }
                                    {
                                        (assignment?.isMainAssignee || (formik.values.userId && !isClient)) && <CheckBox name="isMainAssignee" readOnly={assignment?.isMainAssignee} label="Bearbeiter des Tickets" className="w-100" />
                                    }
                                    {
                                        !formik.values.isMainAssignee && !formik.values.isMainClientContact && (
                                            <Pill color="#F0F0F0">
                                                <Icon color="primary" icon="info-circle" />
                                                <Typography italic>Zuweisung wird als CC-Zuweisung erstellt.</Typography>
                                            </Pill>
                                        )
                                    }
                                </Flex>
                                {
                                    !formik.values.isMainAssignee && formik.values.userId && isClient && (
                                        <ClientSelect 
                                            clientContactId={formik.values.userId}
                                            hideIfEmpty
                                            clientId={formik.values.clientId}
                                            saveClient={(client) => formik.setFieldValue("clientId", client?._id)}
                                        />
                                    )
                                }
                            </Flex>
                        )
                    }
                }
            </ModalForm>
        </WithPermissions>
    )
}