import React from "react";
import { AppColor } from "../../app/AppStyles";
import Icon from "../icons/Icon";
import "./CopyToClipboardButton.css";
import { generateStyle } from "../../hooks/useAttributes";
import Button from "./Button";

interface ICopyToClipboardButtonProps {
    value: string
}

export default function CopyToClipboardButton({value}: ICopyToClipboardButtonProps) {
    
    const [copied, setCopied] = React.useState<boolean>(false);

    const cooldownTimer = React.useRef<any>(null);

    React.useEffect(() => {
        return () => clearTimeout(cooldownTimer.current);
    }, []);

    const copyToClipboard = () => {

        if (copied) return;

        clearTimeout(cooldownTimer.current);

        navigator.clipboard.writeText(value);
        setCopied(true);
        cooldownTimer.current = setTimeout(() => setCopied(false), 1000);
    }

    return (
        <Button 
            onClick={copyToClipboard}
            readOnly={copied}
            icon={copied ? "check" : "layers"}
            iconPosition="start"
            color={copied ? "success" : "dark"}
            variant={copied ? "solid" : "subtle"}
            size="small"
            text={copied ? "Kopiert" : "Kopieren"}
        />
    )
}