import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { ISettings } from "../../../types/ApiTypes";

export const useSettings = () => {
    const { data, isLoading, mutate } = useSWR<ISettings>(ApiRoutes.Settings);

    return {
        settings: data,
        loadingSettings: isLoading,
        reloadSettings: mutate
    }
}