import React from "react";
import useUserUtil from "../../hooks/useUserUtil";
import { useClientEmployeeResponsibilities } from "../../state/swr/clients/useClientEmployeeResponsibilities";
import { useUsers } from "../../state/swr/user/useUsers";
import { IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import WithPermissions from "../permissions/WithPermissions";
import UserRoleIndicator from "../user/RoleBanner";
import { IDatevEmployee } from "../../types/employee.schema";
import { useDatevEmployees } from "../../state/swr/employees/useDatevEmployees";
import { IWithClientProps } from "./ClientDocumentClearanceReminderForm";
import { getModeForFileReference } from "typescript";
import { getModel } from "../../util/mongoUtil";

export interface ISortedClientResponsibility {
    areaOfResponsibility: {
        _id: string,
        id: string,
        name: string
    },
    employees: IUser[]
}

export interface IClientEmployeeResponsibilityListProps extends IWithClientProps {
    onUserClick?: (user: IUser) => Promise<any>,
    messageOnEmpty?: string
}

export default function ClientEmployeeResponsibilityList({client, messageOnEmpty = "-", onUserClick}: IClientEmployeeResponsibilityListProps) {

    const [sortedResponsibilities, setSortedResponsibilities] = React.useState<Array<ISortedClientResponsibility>>();
    
    const {
        datevEmployeesById
    } = useDatevEmployees();

    const { 
        clientEmployeeResponsibilities, 
        loadingClientEmployeeResponsibilities, 
        reloadClientEmployeeResponsibilities 
    } = useClientEmployeeResponsibilities(client._id);

    const {
        getCurrentTenantAuthority
    } = useUserUtil();

    const { 
        usersById,
        users,
        loadingUsers, 
    } = useUsers();

    const findUserForEmployee = (employee: IDatevEmployee): IUser | null | undefined => {
        if (loadingUsers) return null;

        const existingUser = usersById[employee.user];

        if (existingUser) return existingUser;
        if (!users || !users.length) return null;

        return users.find(u => {
            if (!u.mailAddress) return false;
            if (!employee.email) return false;
            const auth = getCurrentTenantAuthority(u);
            if (!auth) return null;
            return u.mailAddress.toUpperCase().trim() === employee.email.toUpperCase().trim();
        });
    }

    React.useEffect(() => {
        const result: {[key: string]: ISortedClientResponsibility} = {};

        if (!clientEmployeeResponsibilities || !clientEmployeeResponsibilities.length) return setSortedResponsibilities([]);

        for (const r of clientEmployeeResponsibilities) {
            if (!r.employee) continue;

            const employee = getModel(r.employee, datevEmployeesById);

            if (!employee) continue;

            const user = findUserForEmployee(employee);

            if (!user) continue;
            
            if (!result[r.area_of_responsibility_name]) result[r.area_of_responsibility_name] = {
                areaOfResponsibility: {
                    _id: r._id,
                    id: r.area_of_responsibility_id,
                    name: r.area_of_responsibility_name
                },
                employees: []
            }

            result[r.area_of_responsibility_name].employees.push(user);
        }

        setSortedResponsibilities(Object.values(result));
    }, [clientEmployeeResponsibilities]);

    return (
        <WithPermissions permissions={["employeeResponsibilities.all.read"]}>
            {
                loadingClientEmployeeResponsibilities || loadingUsers
                ? <LoadingSpinner centered={false} text="Verantwortung wird geladen..." />
                : (
                    sortedResponsibilities && sortedResponsibilities.length
                    ? (
                        <div className="d-flex flex-column gap-2">
                            {
                                sortedResponsibilities.map(e => {
                                    if (!e.employees || !e.employees.length) return null;

                                    return (
                                        <div className="d-flex flex-column" key={e.areaOfResponsibility._id}>
                                            <span>{e.areaOfResponsibility.name}</span>
                                            <div className="d-flex flex-row align-items-center justify-content-start flex-wrap">
                                                {
                                                    e.employees && e.employees.length
                                                    ? e.employees.map(u => <UserRoleIndicator key={u._id} user={u} onClick={onUserClick ? async () => await onUserClick(u) : undefined} displayNameAsValue />)
                                                    : <span>Keine Mitarbeiter</span>
                                                }
                                            </div>
                                        </div>
                                    )
                                })            
                            }
                        </div>
                    )
                    : <span>{messageOnEmpty}</span>

                )
            }
        </WithPermissions>
    )
}