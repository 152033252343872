import React from "react";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";
import { useCurrentTenantRole } from "../../../state/swr/user/useCurrentTenantRole";
import { ITicket, TicketState } from "../../../types/ApiTypes";
import { formatDate } from "../../../util/formatter";
import { getTextColorFromBackground } from "../../../util/util";
import Typography from "../../text/Typography";
import "./TicketStateBanner.css";
import { IHydratedTicket } from "../../../types/ticket.types";

export interface ITicketStateBannerProps {
    hydratedTicket: IHydratedTicket,
    align?: "left" | "right",
    className?: string,
    textOnly?: boolean
}

export default function TicketStateBanner({hydratedTicket, className, align = "right", textOnly}: ITicketStateBannerProps) {

    const {
        ticket,
        assignee,
        deputy
    } = hydratedTicket;

    const { role } = useCurrentTenantRole();

    if (!ticket || !ticket.state) return null;

    const state = ticket.state || TicketState.Open;

    const isWaitingForClientResponse = ticket && ticket.waitingForClientResponse;

    const isClient = role && role.isClient;

    const getTicketColor = (): string => {
        switch (state) {
            case TicketState.Solved: return "#88DC8E";
            case TicketState.Archived: return "#BADFF8";
            case TicketState.Closed: return "#F55E53";
            case TicketState.Resubmission: return "#5E53F5";
        }

        if (isWaitingForClientResponse && !isClient) return "#002E7F";

        if (state === TicketState.Open) return "#E9846A";

        if (!!deputy) return "#F0E2F5";

        return "#FFFFFF"
    }

    const getText = (): string => {
        
        if (state !== TicketState.Open) return state;

        if (isWaitingForClientResponse && !isClient) return "Auf Mandant warten";
        if (!!deputy) return "In Vertretung";

        return state;
    }

    if (textOnly) return <strong style={{color: getTicketColor(), fontSize: "12px", textTransform: "uppercase"}} >{getText()}</strong>

    const style = generateStyle({
        name: "backgroundColor",
        value: getTicketColor()
    });

    const realClass = generateClassName(className, "ticket-state-banner pe-3 ps-3 d-flex flex-column gap-0 justify-content-center align-items-start", {
        value: align,
        base: "ticket-state-banner-start-"
    });

    const color = getTicketColor();

    return (
        <div style={style} className={realClass}>
            <span className="text-nowrap" style={generateStyle({
                name: "color",
                value: getTextColorFromBackground(color)
            }, {
                name: "lineHeight",
                value: "1em"
            })}>
                { getText() }                
            </span>
            <Typography
                size="9"
                basedOnThisBackground={color}
            >
                { state === TicketState.Resubmission && ticket.resubmitAt && formatDate(ticket.resubmitAt) }
            </Typography>
        </div>
    )
}