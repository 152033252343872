import React from "react";
import IElementProps from "../../types/ElementProps";
import { IMultiStepWizardPageProps } from "./MultiStepWizardPage";
import "./MultiStepWizard.css";
import { generateClassName } from "../../hooks/useAttributes";

interface IMultiStepWizardProps {
    initialIndex: number,
    className?: string,
    hideProgressBar?: boolean,
    updateIndex?: (n: number) => void,
    children: (nextPage: () => Promise<void>, prevPage: () => Promise<void>, index?: number) => React.ReactElement<IMultiStepWizardPageProps>[];
}

export default function MultiStepWizard({children, className, updateIndex, initialIndex, hideProgressBar = false}: IMultiStepWizardProps) {
    const [index, setIndex] = React.useState<number>(initialIndex);

    React.useEffect(() => setIndex(initialIndex), [initialIndex]);

    let elementCount = 0;

    const updateIndexTo = (next: (old: number) => number) => setIndex(prev => {
        const newNum = next(prev);
        if (updateIndex) updateIndex(newNum);
        return newNum;
    });

    const prevPage = async () => updateIndexTo(curr => curr == 0 ? 0 : curr - 1);
    const nextPage = async () => updateIndexTo(curr => (curr + 1) >= elementCount ? curr : curr + 1);

    const childElements = children(nextPage, prevPage, index);
    
    if (!childElements || !childElements.length) return null;
    
    elementCount = childElements.length;

    const percentageComplete = (index + 1) == elementCount ? 1 : (index + 1) / elementCount;

    const content = childElements.map((c, i) => React.cloneElement(c, {key: `multi-step-wizard-page-${i}`, active: index === i}));

    return (
        <div className={generateClassName(className, "w-100 position-relative")} >
            {
                content[index]                
            }
            {
                !hideProgressBar && (
                    <div className="multi-step-wizard-progress-container position-sticky w-100">
                        <div className="h-100 w-100" style={{transition: "all 0.3s ease", maxWidth: `calc(${percentageComplete} * 100%)`, backgroundColor: percentageComplete === 1 ? "var(--success)" : "var(--primaryAccent)", opacity: "0.75"}} />
                    </div>
                )
            }
        </div>
    )
}