import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IDatevEmployeeResponsibility } from "../../../types/ApiTypes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";

export const useClientEmployeeResponsibilities = (id: string) => {
    const { data, isLoading, mutate } = useTiggiQuery<Array<IDatevEmployeeResponsibility>>(ApiRoutes.Clients.Responsibilities.ById.getValue({param: RouteParam.ClientId, value: id}), {
        refetchOnWindowFocus: false
    });

    return {
        clientEmployeeResponsibilities: data,
        loadingClientEmployeeResponsibilities: isLoading,
        reloadClientEmployeeResponsibilities: mutate
    }
}