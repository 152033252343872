import { UndefinedInitialDataOptions } from "@tanstack/react-query";
import { ApiRoutes } from "../../../api/ApiRoutes";
import useFilteredTickets, { ITicketFilter } from "../../../hooks/useFilteredTickets";
import useFilterQuery from "../../../hooks/useFilterQuery";
import useTiggiQuery, { TiggiQueryOpts } from "../../../hooks/useTiggiQuery";
import { IHydratedTicket } from "../../../types/ticket.types";

export const useTicketsWithFilter = (filter?: ITicketFilter, opts?: TiggiQueryOpts) => {

    const {
        getFilterForRoute
    } = useFilteredTickets();

    const usedFilter = filter ?? getFilterForRoute();

    const {
        getUrlWithFilter
    } = useFilterQuery();

    const { data, isLoading, mutate } = useTiggiQuery<Array<IHydratedTicket>>(
        getUrlWithFilter(ApiRoutes.Tickets.Filtered, usedFilter), 
        opts
    );

    return {
        tickets: data,
        loadingTickets: isLoading,
        validatingTickets: isLoading,
        reloadTickets: mutate
    }
}