import React from "react";
import WithPermissions from "../permissions/WithPermissions";
import useApi from "../../hooks/useApi";
import Button from "../buttons/Button";
import { statisticsReset } from "../../api/Api";
import { useAnyTeamTicketStatistics } from "../../state/swr/tickets/useAnyTeamTicketStatistics";
import { useAnyUserTicketStatistics } from "../../state/swr/tickets/useAnyUserTicketStatistics";
import { useTenantUserStatistics } from "../../state/swr/statistics/useTenantUserStatistics";

export interface IResetStatisticsButtonProps {
    user?: string,
    team?: string,
    all?: boolean
}

export default function ResetStatisticsButton(props: IResetStatisticsButtonProps) {

    const {
        all,
        team,
        user
    } = props;

    const callApi = useApi();

    const { reloadUserTicketStatistics  } = useAnyUserTicketStatistics(user ?? "");
    const { reloadTeamTicketStatistics } = useAnyTeamTicketStatistics(team ?? "");
    const { reloadTenantUserStatistics } = useTenantUserStatistics();

    const resetStatistics = async () => {
        const res = await callApi(statisticsReset(props));

        if (!res || !res.success) return;

        await Promise.all([
            reloadUserTicketStatistics(),
            reloadTeamTicketStatistics(),
            reloadTenantUserStatistics()
        ]);
    }

    const getText = () => {
        if (all) return "Alle Statistiken zurücksetzen";
        else if (!!team) return "Team-Statistik zurücksetzen";
        else if (!!user) return "Nutzer-Statistik zurücksetzen";
    }

    if (!all && !team && !user) return null;

    return (
        <WithPermissions permissions={["statistics.tenant.delete" ]}>
            <Button 
                secondStepQuestion="Statistik wirklich zurücksetzen?"
                onClick={resetStatistics}
                variant="subtle"
                color="error"
                icon="trash"
                text={getText()}
            />
        </WithPermissions>
    )
}