import React from "react";
import Pill from "../pills/Pill";
import { ILogEntry, LogEntryEventType } from "../../types/log.schema";

export default function LogEntryEventTypeBanner({log}: {log: ILogEntry}) {

    if (!log.event || log.event === LogEntryEventType.None) return null;

    const getText = () => {
        switch (log.event) {
            case LogEntryEventType.GCloud: return "GCloud";
            case LogEntryEventType.Imap: return "Imap";
            case LogEntryEventType.MsOfficeEvent: return "Office365";
            case LogEntryEventType.MsOfficeLifecycle: return "Office365 Lifecycle";
            case LogEntryEventType.SendGrid: return "SendGrid";
            case LogEntryEventType.Stripe: return "Stripe";
        }
    }

    const getColor = () => {
        switch (log.event) {
            case LogEntryEventType.GCloud: return "#922B3E";
            case LogEntryEventType.Imap: return "#45322E";
            case LogEntryEventType.MsOfficeEvent: return "#2271B3";
            case LogEntryEventType.MsOfficeLifecycle: return "#252850";
            case LogEntryEventType.SendGrid: return "#F39F18";
            case LogEntryEventType.Stripe: return "#20603D";
            case LogEntryEventType.None: return "#000000";
        }

        return "";
    }

    return (
        <Pill text={getText()} color={getColor()} />
    )
}