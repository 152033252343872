import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import { officeAccountsGetNextMails } from "../../../api/Api";
import useFilteredOfficeMails from "../../../hooks/useFilteredOfficeMails";
import { useOfficeAcountFolderMails } from "../../../state/swr/office/useOfficeAccountFolderMails";
import { useOfficeAcountFolders } from "../../../state/swr/office/useOfficeAccountFolders";
import LinkOfficeAccountButton from "../account/LinkOfficeAccountButton";
import LabelButton from "../../buttons/LabelButton";
import Flex from "../../container/Flex";
import Icon from "../../icons/Icon";
import LoadingSpinner from "../../loader/LoadingSpinner";
import OfficeInboxSortToggle from "./filter/OfficeInboxSortToggle";
import OfficeInboxUnreadSwitch from "./filter/OfficeInboxUnreadSwitch";
import OfficeInboxFolders from "./folders/OfficeInboxFolders";
import { IWithSelectedMailProps } from "./OfficeInbox";
import OfficeInboxItem from "./OfficeInboxItem";
import "./OfficeInboxMails.css";
import { IOfficeAccount, OfficeAccountStatus, OfficeAccountType } from "../../../types/officeAccount.types";

export interface IOfficeInboxMailsProps extends IWithSelectedMailProps {
    account: IOfficeAccount	
}

export default function OfficeInboxMails({account, setSelectedFolderTitle, setSelectedFolder, selectedFolder, selectedMail, setSelectedMail}: IOfficeInboxMailsProps) {
    const [hasMore, setHasMore] = React.useState<boolean>(true);
    
    const { accountFolders } = useOfficeAcountFolders(account ? account._id : "");
    const { filter } = useFilteredOfficeMails();

    const { folderMails, loadingFolderMails, reloadFolderMails } = useOfficeAcountFolderMails(account ? account._id : "", selectedFolder || "", filter);
    
    React.useEffect(() => {
        if (!folderMails) return;
        setHasMore(!!folderMails["@odata.nextLink"]);
    }, [accountFolders, selectedFolder, filter]); 

    const mailDisplayRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!mailDisplayRef.current) return;
        mailDisplayRef.current.scrollTo({top: 0});
    }, [selectedFolder]);
    
    const loadMore = async () => {
        const nextMails = await officeAccountsGetNextMails({id: account._id, nextUrl: folderMails && folderMails["@odata.nextLink"]});
        
        if (!nextMails || !nextMails.data) return setHasMore(false);
        
        const newMails = folderMails ? [...folderMails.value, ...nextMails.data.value] : nextMails.data.value;
        
        if (!newMails) return setHasMore(false);

        await reloadFolderMails({value: newMails, "@odata.nextLink": nextMails.data["@odata.nextLink"] || ""}, { revalidate: false });
        
        if (!nextMails.data["@odata.nextLink"]) return setHasMore(false);
    }

    const afterDelete = (item: Message) => {
        if (selectedMail && selectedMail.id === item.id) setSelectedMail(undefined);
        const newItems = folderMails && folderMails.value ? folderMails.value.filter(i => i.id !== item.id) : [];
        reloadFolderMails({value: newItems, "@odata.nextLink": folderMails && folderMails["@odata.nextLink"]});
    }

    if (account.status === OfficeAccountStatus.NeedsReAuth) return (
        <Flex>
            <h6>Bitte verknüpfen Sie Ihr Office 365 Konto erneut.</h6>
            <span>Der Zugriff auf Ihr Konto ist abgelaufen oder wir benötigen Ihr Einverständnis zum Zugriff auf Ihren Account.<br/>Bitte melden Sie sich erneut für dieses Konto an oder kontaktieren Sie Ihren Administrator.</span>
            {
                account.type === OfficeAccountType.Personal && <LinkOfficeAccountButton type={account.type} onBehalfOfUser={account.assignments?.personalUser} reAuthOfficeAccountId={account._id}  />
            }
        </Flex>
    )

    return (
        <Flex className="h-100 w-100 position-relative" row align="start">
            <OfficeInboxFolders 
                account={account}
                setSelectedFolderTitle={setSelectedFolderTitle}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
            />
            <Flex className="h-100 office-inbox-mail-list ms-3" style={{width: "fit-content"}}>
                <Flex row className="w-100" justify="between">
                    <OfficeInboxUnreadSwitch />
                    <OfficeInboxSortToggle />
                </Flex>
                <div className="d-flex flex-column w-100 h-100 pe-2" style={{overflowY: "auto", overflowX: "hidden", minWidth: "370px" }} ref={mailDisplayRef} >
                    {
                        loadingFolderMails
                        ? <LoadingSpinner />
                        : (
                            folderMails && folderMails.value && folderMails.value.length
                            ? (
                                <>
                                    {
                                        folderMails.value.map(mail => (
                                            <OfficeInboxItem 
                                                afterDelete={afterDelete} 
                                                folderId={selectedFolder} 
                                                afterMarkUnread={() => setSelectedMail(undefined)} 
                                                account={account} 
                                                isActive={!!selectedMail && selectedMail.id === mail.id} 
                                                item={mail} 
                                                key={mail.id} 
                                                onClick={i => setSelectedMail(i)} 
                                            />))
                                    }
                                    {
                                        hasMore && <LabelButton className="justify-self-center" text="Mehr laden" onClick={async (e) => await loadMore()} /> 
                                    }
                                </>
                            )
                            : (
                                <Flex justify="center" align="center" className="w-100">
                                    <Icon icon="cup-hot" size={24} />
                                    <span>Dieser Ordner ist leer.</span>
                                </Flex>
                            )
                        )
                    }
                </div>
            </Flex>
        </Flex>
    )

}

