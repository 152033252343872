import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IDocumentClearanceLog } from "../../../types/ApiTypes";

export const useClientDocumentClearanceLog = (id: string) => {
    const { data, isLoading, mutate } = useSWR<Array<IDocumentClearanceLog>>(ApiRoutes.Clients.DocumentClearanceLog.getValue({param: RouteParam.ClientId, value: id}), {
        revalidateOnMount: false
    });

    return {
        documentClearanceLogs: data,
        loadingDocumentClearanceLogs: isLoading,
        reloadDocumentClearanceLogs: mutate
    }
}