import React from "react";
import { ITeamBase } from "../../types/ApiTypes";
import Banner from "../pills/Banner";
import Pill from "../pills/Pill";
import { useTeams } from "../../state/swr/teams/useTeams";
import { getId } from "../../util/mongoUtil";
import Typography from "../text/Typography";
import { getTextColorFromBackground } from "../../util/util";

export interface ITeamBanner {
    team: ITeamBase | string,
    onClick?: () => void,
    isActive?: boolean,
    textOnEmpty?: string
}

export default function TeamBanner({team, textOnEmpty, isActive = true, onClick}: ITeamBanner) {

    const {
        teamsById,
        loadingTeams
    } = useTeams();

    if (loadingTeams) return <Pill loading loadingText="Lädt..." />

    const usedTeam = typeof team === "string" ? teamsById[team] : team;

    if (!usedTeam || !usedTeam.name) {
        if (textOnEmpty) return <Pill icon="people" color="bright" text={textOnEmpty} />
        return null;
    }
    
    const inactiveColor = "#A0A0A0";
    const color = (isActive && usedTeam.color) || inactiveColor;

    return (
        <Pill icon="people" color={color} onClick={onClick} withDivider text={usedTeam.name} />
    )
}