import React from "react";
import useTicketPermission from "../../../hooks/useTicketPermissions";
import { useTicket } from "../../../state/swr/tickets/useTicket";
import { useTicketAssignee } from "../../../state/swr/tickets/useTicketAssignee";
import { ITeam, ITicket } from "../../../types/ApiTypes";
import Button from "../../buttons/Button";
import Expandable from "../../comboBox/Expandable";
import WithPermissions from "../../permissions/WithPermissions";
import "./AssignTicketToOtherTeamButton.css";
import AssignTicketToOtherTeamMemberButton from "./AssignTicketToOtherTeamMemberButton";

interface IAssignTicketToOtherTeamButtonProps {
    ticket: ITicket,
    team: ITeam,
}

export default function AssignTicketToOtherTeamButton({team, ticket}: IAssignTicketToOtherTeamButtonProps) {

    const permissions = useTicketPermission("update", ticket);
    
    const { reloadTicketAssignee } = useTicketAssignee(ticket);
    const { reloadTicket } = useTicket(ticket._id);

    const hasMembers = team && team.members && !!team.members.length;

    return (
        <WithPermissions permissions={permissions}>
            <Expandable
                disabled={!hasMembers}
                expander={<Button disabled={!hasMembers} variant="text" icon="chevron-right" className="w-100" iconPosition="end">{team.name}</Button>}
            >
                {
                    team.members.map(u => (
                        <AssignTicketToOtherTeamMemberButton 
                            key={u} 
                            afterAssigningTicket={() => {
                                reloadTicket()
                                reloadTicketAssignee();
                            }} 
                            team={team} 
                            ticket={ticket} 
                            userId={u}
                        />
                    ))
                }
            </Expandable>
        </WithPermissions>
    );
}