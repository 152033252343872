import React from "react";
import { useTeams } from "../../state/swr/teams/useTeams";
import { useAllTeamsStatistics } from "../../state/swr/statistics/useAllTeamsStatistics";
import { useAnyTeamUserStatistics } from "../../state/swr/statistics/useAnyTeamUserStatistics";
import Button from "../buttons/Button";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import Flex from "../container/Flex";
import WithPermissions from "../permissions/WithPermissions";
import TicketStatsCardGrid from "./TicketStatsCardGrid";
import TicketStatsPieGrid from "./TicketStatsPieGrid";
import { TicketProductivityTab } from "./ProductivityOverview";

export interface ITeamProductivityOverviewProps {
    teamId?: string,
    setTeamId: (teamId?: string) => void
}

export default function TeamProductivityOverview({ teamId, setTeamId }: ITeamProductivityOverviewProps) {

    const { teams } = useTeams();

    React.useEffect(() => {
        if (!teams || !teams.length) return;
        if (teamId) return;

        setTeamId(teams[0]._id);
    }, [teams]);


    const resetTeam = () => {
        if (teams && teams.length) return setTeamId(teams[0]._id);
        setTeamId(undefined);
    }

    const { loadingTeamUserStatistics, teamUserStatistics } = useAnyTeamUserStatistics(teamId || "");
    const { allTeamsStatistics, loadingAllTeamsStatistics } = useAllTeamsStatistics();

    return (
        <WithPermissions permissions={[ "statistics.team.read" ]}>
            <Flex className="w-100">
                <TicketStatsCardGrid 
                    currentTab={TicketProductivityTab.Team}
                    resetTarget={teamId ?? ""}
                    title="Performance deines Teams" 
                    cardColor="bright" 
                    isLoading={loadingTeamUserStatistics} 
                    statistics={teamUserStatistics?.statistics || undefined} 
                    filterComponent={(
                        <Flex row>
                            <SearchableComboBox
                                values={teams || []}
                                itemToId={t => t._id}
                                itemToString={t => t.name}
                                value={teamId}
                                onItemClick={(team) => setTeamId(team?._id)}
        
                            />
                            {teamId && <Button onClick={async () => resetTeam()} variant="icon" icon="arrow-clockwise" />}
                        </Flex>
        
                    )}
                />
                <TicketStatsPieGrid cardColor="bright" title="Übersicht der Teams" isLoading={loadingAllTeamsStatistics} statistics={allTeamsStatistics || []} hasTeam />
            </Flex>
        </WithPermissions>
    )
}