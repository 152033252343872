import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import useTiggiQuery from "../../../hooks/useTiggiQuery";
import { ITicket, ITicketAssignment } from "../../../types/ApiTypes";

export const useTicketClientContact = (ticket: ITicket) => {
    const { data, isLoading, mutate } = useTiggiQuery<ITicketAssignment>(ApiRoutes.Tickets.Assignments.GetClientContact.getValue({
        param: RouteParam.TicketId,
        value: ticket._id
    }));

    return {
        ticketClientContact: data,
        loadingTicketClientContact: isLoading,
        reloadTicketClientContact: mutate
    }
} 