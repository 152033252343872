import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IClientImportLogEntry } from "../../../types/ApiTypes";

export const useLatestClientImportLog = () => {
    const { data, isLoading, mutate } = useSWR<IClientImportLogEntry>(ApiRoutes.ClientsImportLog.Latest);

    return {
        latestClientImportLog: data,
        loadingLatestClientImportLog: isLoading,
        reloadLatestClientImportLog: mutate
    }
}