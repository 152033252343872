import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IUserStatistics } from "../../../types/ApiTypes";

export const useTenantUserStatistics = () => {
    const { data, isLoading, mutate } = useSWR<IUserStatistics>(ApiRoutes.Statistics.CurrentTenant, { revalidateOnFocus: false, revalidateIfStale: false });

    return {
        tenantUserStatistics: data,
        loadingTenantUserStatistics: isLoading,
        reloadTenantUserStatistics: mutate
    }
}