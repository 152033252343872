import React from "react";
import { useNavigate } from "react-router-dom";
import { AppColor } from "../../app/AppStyles";
import { generateClassName, generateStyle, generateStyleWithBase } from "../../hooks/useAttributes";
import IElementProps from "../../types/ElementProps";
import { getLuminosityOfColor, getRangeValueBasedOnBrightness, getReadableColor, getTextColorFromBackground, hexWithOpacity, isBrightColor, parseVarColorToHex } from "../../util/util";
import Icon from "../icons/Icon";
import "./Button.css";
import Tooltip from "../tooltip/Tooltip";
import Flex from "../container/Flex";

export type ButtonSize = "tiny" | "small" | "regular" | "large";

export type ButtonVariant = "solid" | "outline" | "text" | "hyperlink" | "icon" | "subtle";
export type ButtonType = "button" | "submit" | "reset";

export interface IButtonPropsBase {
    icon?: string, 
    iconPosition?: "start" | "end",
    iconSize?: number,
    variant?: ButtonVariant,
    text?: string, 
    type?: ButtonType,
    tooltip?: string,
    externalLink?: boolean,
    disabled?: boolean,
    readOnly?: boolean,
    disabledText?: string,
    size?: ButtonSize,
    openInNewTab?: boolean,
    to?: string,
    disabledIcon?: string,
}

export interface IButtonProps extends IElementProps, IButtonPropsBase {
    color?: AppColor,
    hexColor?: string,
    loading?: boolean,
    loadingText?: string,
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => (Promise<any> | any), 
    align?: "start" | "end" | "center",
    secondStepQuestion?: string,
    preventFloatEndOnSubmit?: boolean,
}

export default function Button(props: IButtonProps) {

    const {
        className, 
        externalLink = false, 
        readOnly,
        to = "", 
        hexColor,
        align = "center", 
        iconPosition = "start",
        preventFloatEndOnSubmit = false, 
        iconSize, 
        variant = "solid", 
        disabledIcon, 
        disabledText, 
        onClick, 
        disabled, 
        openInNewTab, 
        loading, 
        tooltip,
        text, 
        type, 
        loadingText, 
        style,
        children, 
        icon, 
        secondStepQuestion,
        color, 
        size = "regular"
    } = props;
    
    const [secondStepActive, setSecondStepActive] = React.useState<boolean>(false);
    const [hover, setHover] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(loading || false)
    
    React.useEffect(() => {
        if (loading === undefined) return;
        setIsLoading(loading);
    }, [loading]);

    const navigate = useNavigate();

    const clickHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (readOnly) return;

        if (secondStepQuestion && !secondStepActive) {
            setSecondStepActive(true);
            return;
        }

        try {
            setIsLoading(true);

            if (!to) {
                if (!onClick) return;
                await onClick(e);
                return;
            }
    
            if (!externalLink) {
                navigate(to);
                e.preventDefault();
                return;
            }
    
            if (openInNewTab) {
                window.open(to, "_blank");
                e.preventDefault();
                return;
            }
            
            window.location.href = to;
            e.preventDefault();
        }
        catch { }
        finally {
            setIsLoading(false);
            setSecondStepActive(false);
        }
    }

    const usedColor = parseVarColorToHex(color ?? hexColor ?? "primary");

    const getTextColor = () => {
        switch (variant) {
            case "solid":

                
                return (
                    usedColor 
                    ? getTextColorFromBackground(usedColor, "#FFFFFF") 
                    : "#000000"
                )
                
            case "subtle":

                return getReadableColor(usedColor, 0.25);

            default: return usedColor;
        }
    }
    
    const textColor = getTextColor();

    const backgroundStyle = generateStyle({
        value: hexWithOpacity(usedColor, variant === "subtle" ? 0.1 : 1),
        name: "backgroundColor"
    })

    const getIconSize = () => {
        switch (size) {
            case "large": return iconSize ?? 24;
            case "regular": return iconSize ?? 18;
            case "small": return iconSize ?? 14;
            case "tiny": return 12;
        }
    }

    const getFontSize = () => {
        switch (size) {
            case "large": return "1.25";
            case "regular": return "1";
            case "small": return "0.8";
            case "tiny" : return "0.6";
        }
    }

    const buttonStyle = generateStyleWithBase(style, [{
            value: textColor,
            name: "color"
        }, {
            unit: "em",
            standard: "1",
            value: getFontSize(),
            name: "fontSize"
        }, {
            name: "border",
            applyCondition: (variant === "outline" || variant === "solid"),
            value: `2px solid ${usedColor}`
        }, {
            name: "border",
            applyCondition: hover && variant !== "outline" && variant !== "solid",
            value: `2px solid ${hexWithOpacity(usedColor, 0.3, true)})`
        }, {
            name: "padding",
            value: size === "tiny" ? "0.375em 0.5em" : ".375em .75em"
        }
    ]);

    const isSubmit = type === "submit";
    const canFloatEnd = isSubmit && !preventFloatEndOnSubmit;

    const buttonClass = generateClassName(className, "button position-relative", {
        value: canFloatEnd,
        onTrue: "align-self-end"
    }, {
        value: variant,
        base: "button-"
    });

    const contentClass = generateClassName("button-content position-relative d-flex flex-row align-items-center gap-2", {
        value: align,
        base: "justify-content-"
    })

    const textClass = generateClassName("button-text text-nowrap m-0 p-0 ", {
        value: variant === "icon",
        onTrue: "button-text-icon",
        standard: "text-uppercase fw-bold"
    })

    const textStyle = generateStyle({
        name: "color",
        value: hover ? getTextColorFromBackground(hexWithOpacity(usedColor, 0.3, true), "#FFFFFF") : "black",
        important: true,
        applyCondition: variant === "icon"
    });

    const getButtonText = () => {
        if (isLoading) return loadingText || "Bitte warten...";
        if (disabled) return disabledText || text;
        if (secondStepActive) return secondStepQuestion ?? "Sicher?";
        if (children && typeof children === "string") return children;
        return text ?? "";
    }

    const buttonText = getButtonText();
    const hasText = !!buttonText;

    const iconComponent = (
        <Icon 
            icon={disabled ? (disabledIcon || icon) : icon} 
            loading={isLoading} 
            size={getIconSize()} 
            tooltip={variant === "icon" ? buttonText : ""} 
        />
    );

    const btn = (
        <button 
            type={type || "button"} 
            onClick={clickHandler} 
            className={buttonClass} 
            style={buttonStyle} 
            disabled={isLoading || disabled} 
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            { 
                hover && !(disabled || isLoading) &&  (
                    <div 
                        className="button-nonsolid-hover-effect position-absolute w-100 h-100 top-0 start-0" 
                        style={generateStyle({
                            name: "backgroundColor", 
                            important: false, 
                            value: hexWithOpacity(usedColor, 0.2, true) 
                        })} 
                    />
                )
            }
            {
                (variant === "solid" || variant === "subtle") && <div className="button-background w-100 h-100 position-absolute top-0 start-0" style={backgroundStyle} />
            }
            <div className={contentClass}>
                {
                    iconPosition === "start" && iconComponent
                }
                {
                    (hasText || children) && <div style={textStyle} className={textClass}>{children || buttonText}</div>
                }
                {
                    iconPosition === "end" && iconComponent
                }
            </div>
        </button>
    )

    const btnWithSecondStep = (secondStepQuestion && secondStepActive) ? (
        <Flex row gap="0">
            {btn}
            <Icon 
                icon="x" 
                color="error"
                size={getIconSize() * 1.5} 
                onClick={() => setSecondStepActive(false)} 
            />
        </Flex>
    ) : btn;

    if (!tooltip) return btnWithSecondStep;

    return (
        <Tooltip tooltip={tooltip}>{btnWithSecondStep}</Tooltip>
    )
}