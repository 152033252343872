import React from "react";
import Pill from "../../pills/Pill";
import { OfficeAccountType } from "../../../types/officeAccount.types";

export default function OfficeAccountTypeBanner({type, className}: {type: OfficeAccountType, className?: string}) {
    
    const getColor = () => {
        switch (type) {
            case OfficeAccountType.Personal: return "#F0F0F0";
            case OfficeAccountType.Team: return "#A0A0FF";
            case OfficeAccountType.Tenant: return "#FF5050";
        }
    }

    const getIcon = () => {
        switch (type) {
            case OfficeAccountType.Personal: return "person";
            case OfficeAccountType.Team: return "people";
            case OfficeAccountType.Tenant: return "building";
        }
    }

    const getText = () => {
        switch (type) {
            case OfficeAccountType.Personal: return "Nutzer";
            case OfficeAccountType.Team: return "Team";
            case OfficeAccountType.Tenant: return "Kanzlei";
        }
    }

    return (
        <Pill 
            text={getText()} 
            color={getColor()} 
            icon={getIcon()}
            className={className} 
        />
    )
}