import React from "react";
import useApi from "../../hooks/useApi";
import { useLatestClientImportLog } from "../../state/swr/clients/useLatestClientImportLog";
import { useDatevImports } from "../../state/swr/datevImport/useDatevImports";
import Card from "../card/Card";
import Flex from "../container/Flex";
import CloudGatewaySetUp from "../datev/CloudGatewaySetUp";
import DownloadDatevExporter from "../datev/DownloadDatevExporter";
import TabSwitcher from "../tabswitcher/TabSwitcher";
import Typography from "../text/Typography";
import ImportClientsForm from "./ImportClientsForm";
import "./ImportClientsForm.css";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import { IDatevImport } from "../../types/datevImport.schema";
import { ExternalService } from "../../types/log.schema";
import TableRow from "../tables/TableRow";
import TableCell from "../tables/TableCell";
import { useClientImportLog } from "../../state/swr/clients/useClientImportLog";
import ExternalServiceBanner from "../services/ExternalServiceBanner";
import RoleBanner from "../user/RoleBanner";
import Button from "../buttons/Button";
import Pill from "../pills/Pill";
import { datevImportCancel, datevImportsResume } from "../../api/Api";
import Icon from "../icons/Icon";

const x: IDatevImport = {
    _id: "test",
    alsoImportInactiveData: false,
    cancelled: false,
    completed: false,
    importLog: "66f5310f449eff2d45bc8dae",
    serviceSource: ExternalService.CloudGateway,
    tenant: "",
    createdAt: new Date(),
    importingUser: "641b9319327991fb942a4add",
    updatedAt: new Date()
}

export default function CurrentClientImports() {

    const {
        datevImports,
        reloadDatevImports,
        loadingDatevImports
    } = useDatevImports();

    const callApi = useApi();

    if (loadingDatevImports) return <LoadingSpinner />

    console.log(datevImports);

    return (
        <Table
            headers={[
                { label: "Startdatum" },
                { label: "Status" },
                { label: "Erstellt von" },
                { label: "Aktionen" }
            ]}
            items={datevImports ?? []}
            renderItem={d => (
                <TableRow>
                    <TableCell date={x.createdAt} />
                    <TableCell>
                        <ImportLogMessage id={d.importLog } />
                    </TableCell>
                    <TableCell>
                        <Flex>
                            <ExternalServiceBanner service={d.serviceSource} />
                            <RoleBanner user={d.importingUser} displayNameAsValue />
                        </Flex>
                    </TableCell>
                    <TableCell>
                        <Flex align="end">
                            {
                                d.completed 
                                ? <Pill text="Erfolgreich abgeschlossen!" color="success" />
                                : (
                                    <Button
                                        icon={d.cancelled ? "check" : "x"}
                                        text={d.cancelled ? "Erneut starten" : "Abbrechen"}
                                        color={d.cancelled ? "success" : "error"}
                                        onClick={async () => {
                                            const res = (
                                                d.cancelled
                                                ? await callApi(datevImportsResume({ id: d._id })) 
                                                : await callApi(datevImportCancel({ id: d._id }))
                                            );

                                            await reloadDatevImports();
                                        }}
                                    />
                                )
                            }
                        </Flex>
                    </TableCell>
                </TableRow>
            )}

        />
    )
}

function ImportLogMessage({ id }: { id: string}) {

    const [lastText, setLastText] = React.useState<string>("-");

    const {
        clientImportLog,
        loadingClientImportLog,
        reloadClientImportLog
    } = useClientImportLog(id);

    React.useEffect(() => {
        if (!clientImportLog) return;
        setLastText(clientImportLog?.message ?? "-");
    }, [clientImportLog]);

    React.useEffect(() => {
        const refreshTimer = setInterval(async () => await reloadClientImportLog(undefined, { revalidate: true }), 5000);
        return () => clearInterval(refreshTimer);
    }, []);

    return (
        <Flex row>
            <Icon
                loading={loadingClientImportLog}
                icon="arrow-clockwise"
                onClick={() => reloadClientImportLog(undefined, { revalidate: true })}
            />
            <Typography wrap>
                {lastText}
            </Typography>
            {
                !!(clientImportLog?.allErrors?.length) && <Pill color="#FF0000" text="Fehler" />
            }
        </Flex>
    )
}