import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { ILegalForm } from "../../../types/ApiTypes";
import { ICollection } from "../../../types/collection.types";

export const useLegalForms = () => {
    const { data, isLoading, mutate } = useSWR<ICollection<ILegalForm>>(ApiRoutes.LegalForms);

    return {
        legalForms: data?.items ?? [],
        legalFormsById: data?.byId ?? {},
        loadingLegalForms: isLoading,
        reloadLegalForms: mutate
    }
}