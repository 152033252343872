import { Form } from "formik";
import React from "react";
import { officeAccountsTransformMailToTicketMessage } from "../../../../api/Api";
import { IOfficeAccountAddMailAsMessageToTicketRequest } from "../../../../api/ApiRequests";
import { ModalType } from "../../../../config/ModalTypes";
import useApi from "../../../../hooks/useApi";
import useModal from "../../../../hooks/useModal";
import { useTicketAssignee } from "../../../../state/swr/tickets/useTicketAssignee";
import { useTicketClientContact } from "../../../../state/swr/tickets/useTicketClientContact";
import { ITicket } from "../../../../types/ApiTypes";
import { IHydratedTicket } from "../../../../types/ticket.types";
import Button from "../../../buttons/Button";
import Card from "../../../card/Card";
import ClientTypeBanner from "../../../clients/ClientTypeBanner";
import Flex from "../../../container/Flex";
import LoadingSpinner from "../../../loader/LoadingSpinner";
import ModalForm from "../../../modal/ModalForm";
import TeamBanner from "../../../teams/TeamBanner";
import TicketAssigneeBanner from "../../../ticketing/banner/TicketAssigneeBanner";
import TicketTypeBanner from "../../../ticketing/banner/TicketTypeBanner";
import TicketChatMessagePreview from "../../../ticketing/ticketChat/TicketChatMessagePreview";
import UserRoleIndicator from "../../../user/RoleBanner";
import { IOfficeAccount } from "../../../../types/officeAccount.types";
import { useTicketsWithFilter } from "../../../../state/swr/tickets/useTicketsWithFilter";
import { TicketFilter } from "../../../../hooks/useFilteredTickets";

export interface IAddMailAsMessageToTicketButtonProps {
    account: IOfficeAccount,
    mailId?: string
}

export interface IAddMailAsMessageToTicketFormValues extends IOfficeAccountAddMailAsMessageToTicketRequest {
    ticket: ITicket | undefined
}

export default function AddMailAsMessageToTicketButton({account, mailId}: IAddMailAsMessageToTicketButtonProps) {

    const [filter, setFilter] = React.useState<string>("");

    const callApi = useApi();
    const showModal = useModal();

    const { tickets, loadingTickets } = useTicketsWithFilter({ max: -1, show: TicketFilter.All, text: filter});

    if (!account || !account._id) return null;
    if (!mailId) return null;
    if (!loadingTickets && (!tickets || !tickets.length)) return null;
    
    return (
        <ModalForm 
            loading={loadingTickets}
            button={<Button text="Als Nachricht an Ticket anhängen" iconPosition="end" icon="chat" variant="outline" loadingText="Lädt Tickets..." loading={loadingTickets} />}
            title="Mail als Nachricht an Ticket anhängen"
            initialValues={{
                ticketId: "",
                id: account._id,
                mailId: mailId,
                ticket: undefined
            } as IAddMailAsMessageToTicketFormValues }
            onSubmit={async (values) => {

                if (!values.ticketId) {
                    showModal({
                        text: "Bitte wählen Sie Ticket aus",
                        type: ModalType.Error
                    });
                    return false;
                }
                
                const res = await callApi(officeAccountsTransformMailToTicketMessage({
                    id: values.id,
                    mailId: values.mailId,
                    ticketId: values.ticketId
                }));

                if (!res || !res.success) return false;

                return true;
            }}
            sidebar={formik => <Button type="submit" text="Als Nachricht hinzufügen" icon="plus" loading={formik.isSubmitting} />}
        >
            {
                formik => (
                    <Form className="w-100">
                        <Flex className="w-100" gap={4}> 
                            <Flex className="w-100">
                                <strong>Suche</strong>
                                <input placeholder="Betreff oder internen Betreff durchsuchen..." onChange={(e) => setFilter(e.target.value)} value={filter} className="form-control" />
                            </Flex>
                            <Flex className="w-100">
                                <strong>Tickets</strong>
                                <Flex className="w-100" style={{maxHeight: "350px", overflowY: "auto"}}>
                                    {
                                        loadingTickets
                                        ? <LoadingSpinner /> 
                                        : (
                                            tickets && tickets.length
                                            ? (
                                                tickets.map(t => {
                                                    const isSelected = !!formik.values.ticketId && formik.values.ticketId === t._id;

                                                    return (
                                                        <Flex key={t._id} row align="start" className="w-100">
                                                            {
                                                                <div className="d-flex flex-column check-box align-items-start p-2" >
                                                                    <div className="d-flex flex-row align-items-center" >
                                                                        <input 
                                                                            type="checkbox" 
                                                                            style={{height: "16px", width: "16px"}} 
                                                                            checked={isSelected} 
                                                                            onChange={() => {
                                                                                formik.setFieldValue("ticket", t);
                                                                                formik.setFieldValue("ticketId", t._id)
                                                                            }} 
                                                                            className="form-check-input m-0 p-0" 
                                                                        />
                                                                    </div>
                                                                </div>
                                                            }
                                                            <TicketCard isSelected={isSelected} ticket={t.ticket} />
                                                        </Flex>
                                                    )
                                                })
                                            )
                                            : <span>Keine Tickets</span>
                                        )
                                    }
                                </Flex>
                            </Flex>
                            {
                                formik.values.ticket && (
                                    <Flex className="w-100">
                                        <strong>Auswahl</strong>
                                        <TicketCard isSelected ticket={formik.values.ticket} />
                                    </Flex>
                                )
                            }
                            
                        </Flex>
                    </Form>
                )
            }
        </ModalForm>
    );
}

function TicketCard({ticket, isSelected}: {ticket: ITicket, isSelected?: boolean}) {

    const { loadingTicketAssignee, ticketAssignee } = useTicketAssignee(ticket);
    const { loadingTicketClientContact, ticketClientContact } = useTicketClientContact(ticket);

    return (
        <Card className="p-2 w-100" noPadding background={ isSelected ? "rgba(212, 175, 55, 0.2)" : "var(--backgroundLighter)"} style={{ border: `1px solid ${isSelected ? "gold" : "transparent"}`}}>
            <Flex row className="w-100" justify="between" align="start">
                <Flex>
                    <strong>{ticket.internalSubject}</strong>
                    <TicketChatMessagePreview ticket={ticket} />
                    <Flex row>
                        <TicketTypeBanner ticket={ticket} />
                        { ticket.team && <TeamBanner team={ticket.team} /> }
                        <TicketAssigneeBanner ticket={ticket} />
                        { 
                            loadingTicketAssignee 
                            ? <LoadingSpinner />
                            : ticketAssignee && ticketAssignee.user && <UserRoleIndicator user={ticketAssignee.user} displayNameAsValue />
                        }
                        { 
                            loadingTicketClientContact
                            ? <LoadingSpinner />
                            : ticketClientContact && ticketClientContact.user && <UserRoleIndicator user={ticketClientContact.user} displayNameAsValue />}
                        { 
                            loadingTicketClientContact
                            ? <LoadingSpinner />
                            : ticketClientContact && ticketClientContact.client && <ClientTypeBanner client={ticketClientContact.client} showClientName />
                        }
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
}