import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { ISignature } from "../../../types/ApiTypes";

export const useTenantSignature = () => {
    const { data, isLoading, mutate } = useSWR<ISignature>(ApiRoutes.Signatures.All);

    return {
        tenantSignature: data,
        loadingTenantSignature: isLoading,
        reloadTenantSignature: mutate
    }
}