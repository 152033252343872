import React from "react";
import { rolesCreate, rolesUpdate } from "../../api/Api";
import { IRoleCreateRequest } from "../../api/ApiRequests";
import useApi from "../../hooks/useApi";
import { useTenant } from "../../state/swr/tenant/useTenant";
import { IRole, Permission } from "../../types/ApiTypes";
import Button from "../buttons/Button";
import Flex from "../container/Flex";
import FormikField from "../formik/FormikField";
import Icon from "../icons/Icon";
import ModalForm from "../modal/ModalForm";
import Typography from "../text/Typography";
import RoleBanner from "../user/RoleBanner";
import PermissionCheckboxes from "./PermissionCheckboxes";
import PermissionGroup from "./PermissionGroup";
import WithPermissions from "./WithPermissions";
import { useRoles } from "../../state/swr/roles/useRoles";

export interface IUpdateRoleFormProps {
    role?: IRole,
    isDuplicate?: boolean
}

export default function UpdateRoleForm({role, isDuplicate = false}: IUpdateRoleFormProps) {
    
    const {
        reloadRoles
    } = useRoles();

    const {
        tenant
    } = useTenant();

    const callApi = useApi();

    const permission: Permission = role && !isDuplicate
    ? "roles.all.update"
    : "roles.all.create";

    const title = isDuplicate ? "Rolle duplizieren" : (role ? "Rolle ändern" : "Rolle erstellen");
    const buttonText = isDuplicate ? "Duplizieren" : (role ? (role.isInitialRole ? "Ansehen" : "Ändern") : "Erstellen");
    const buttonIcon = isDuplicate ? "clipboard" : (role ? (role.isInitialRole ? "eye" : "pen") : "plus");

    return (
        <WithPermissions permissions={[ permission ]}>
            <ModalForm
                title={title}
                button={<Button variant={isDuplicate ? "subtle" : "solid"} text={buttonText} icon={buttonIcon} />}
                initialValues={{
                    accessLevel: role?.accessLevel || 0,
                    permissions: role?.permissions || {},
                    displayName: isDuplicate ? "" : (role?.displayName ?? ""),
                    isClient: role?.isClient ?? false,
                    initialRoleId: isDuplicate ? "" : (role?.initialRoleId ?? ""),
                    isInitialRole: isDuplicate ? false : (role?.isInitialRole ?? false)
                } as IRoleCreateRequest}
                onSubmit={async (values) => {
                    const res = role && !isDuplicate
                    ? await callApi(rolesUpdate({...values, id: role._id}))
                    : await callApi(rolesCreate(values));

                    if (!res || !res.success) return false;

                    await reloadRoles();
                    return true;
                }}
            >   
                {
                    (formik, close) => (
                        <Flex fullWidth gap={3}>
                            <Flex row fullWidth >
                                <FormikField name="displayName" label="Name" className="w-100" />
                                <FormikField name="accessLevel" label="Level (0-99)" className="w-25" min="0" max="99" type="number" />
                                <Flex>
                                    <Typography color="primary">Banner</Typography>
                                    <RoleBanner role={formik.values} />
                                </Flex>
                            </Flex>
                            <Flex fullWidth>
                                <Flex fullWidth gap={1}>
                                    <Flex fullWidth row justify="between">
                                        <Typography bold color="primary" wrap size={20}>Berechtigungen</Typography>
                                        <Button
                                            icon="person" 
                                            text={formik.values.isClient ? "Mandantenrolle" : "Mitarbeiterrolle"}
                                            color="secondary" 
                                            variant="subtle"
                                            disabled={(role?.isInitialRole && !isDuplicate)}
                                            size="small"
                                            onClick={() => formik.setFieldValue("isClient", !formik.values.isClient)}
                                        />
                                    </Flex>
                                    { formik.values.isInitialRole && <Typography wrap className="w-100" italic>Diese Rolle wird vom System verwaltet. Bitte teilen Sie uns Anpassungswünsche mit oder erstellen Sie Ihre eigene Rolle, um Anpassungen vorzunehmen.</Typography>}
                                </Flex>
                                <PermissionGroup 
                                    label="Tickets" 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "tickets.all", label: "Alle", disabled: formik.values.isClient },
                                        { name: "tickets.own", label: "Eigene" },
                                        { name: "tickets.external", label: "Extern", disabled: formik.values.isClient },
                                        { name: "tickets.internal", label: "Intern", disabled: formik.values.isClient },
                                        { name: "tickets.unassigned", label: "Ohne Zuweisung", disabled: formik.values.isClient, description: "Erlaubt die Einsicht oder Verwaltung von Tickets ohne Zuweisung sowie die Verwaltung der Ticketzuweisung."  }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Benutzer" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "users.clientContacts", label: "Mandantenkontakte" },
                                        { name: "users.employees", label: "Mitarbeiter" }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Mandanten" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "clients.all", label: "Alle" },
                                        { name: "clients.own", label: "Eigene" }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Addressaten" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "addressees.all", label: "Alle" }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Mitarbeiterzuständigkeiten" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "employeeResponsibilities.all", label: "Alle", description: "Erlaubt das Einsehen von Zuständigkeiten der Mitarbeiter für Mandanten." }
                                    ]}
                                />
                                <PermissionGroup
                                    label="Mandantenzuständigkeiten" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "clientResponsibilities.all", label: "Alle", description: "Erlaubt das Einsehen von Zuständigkeiten zwischen Mandant und etwa wirtschaftlich Berechtigten." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Addressatenbeziehungen" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "addresseeRelationships.all", label: "Alle", description: "Erlaubt das Einsehen von Zuständigkeiten zwischen verschiedenen Addressaten." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Postfächer" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "inboxes.all", label: "Alle" },
                                        { name: "inboxes.own", label: "Eigene" },
                                        { name: "inboxes.teams", label: "Teams" },
                                        { name: "inboxes.tenant", label: "Kanzlei / Info" }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Teams" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "teams.all", label: "Alle" },
                                        { name: "teams.own", label: "Eigene" }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Aufgaben & Termine" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "tasks.all", label: "Alle" },
                                        { name: "tasks.own", label: "Eigene" },
                                        { name: "tasks.team", label: "Teams" }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Statistiken" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "statistics.tenant", label: "Kanzlei" },
                                        { name: "statistics.own", label: "Eigene" },
                                        { name: "statistics.team", label: "Teams" }
                                    ]}
                                />
                                <PermissionGroup 
                                    label={tenant?.settings?.titles?.documentClearanceWord || "Belegfreigabe"} 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "documentClearance.all", label: "Alle", description: "Erlaubt den Zugriff auf unterschiedliche Mandate. Die Bestätigung kann auch ohne Berechtigung erfolgen, Mandanten haben immer Zugriff." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Alias-Mails" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "alias.all", label: "Alle", description: "Erlaubt das Verwalten von Mandats-Alias." },
                                        { name: "alias.own", label: "Eigene", description: "Nutzer können eigene Alias-Mails festlegen." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Kanzlei" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "tenant.own", label: "Kanzleiverwaltung", description: "Erlaubt es, Einstellungen wie Öffnungszeiten oder Eskalation für die Kanzlei festzulegen." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Abo & Zahlung" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "billing.tenant", label: "Kanzlei", description: "Erlaubt den Zugriff auf das Rechnungswesen." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Domains" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "domains.tenant", label: "Kanzlei", description: "Erlaubt den Zugriff auf die Domainverwaltung und kontrolliert die Erreichbarkeit Ihres Systems." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Vorlagen" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "templates.mails", label: "Mailvorlagen", description: "Erlaubt den Zugang zur Verwaltung der Mailvorlagen für die externe Kommunikation." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Abwesenheiten & Fehlzeiten" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "absence.all", label: "Alle", description: "Erlaubt den Zugang zur Fehlzeitenverwaltung aller Mitarbeiter." },
                                        { name: "absence.own", label: "Eigene" }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Rollenverwaltung" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "roles.all", label: "Alle", description: "Erlaubt den Zugang zur Berechtigungsverwaltung, wie etwa das Erstellen von neuen Rollen oder das Zuweisen von Rollen. ACHTUNG: Diese Berechtigung ist mit Admin-Rechten gleichzusetzen und sollte nur wenigen Benutzern zugewiesen werden." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Schnittstellen" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "interfaces.pointchamp", label: "PointChamp", description: "Erlaubt das Verwalten der PointChamp-Integration." },
                                        { name: "interfaces.cloudGateway", label: "CloudGateway", description: "Erlaubt das Hinterlegen von Zugangsdaten für den Abruf von DATEV-Mandanteninformationen über CloudGateway." },
                                        { name: "interfaces.datev", label: "DATEV", description: "Erlaubt den direkten Zugriff auf unsere Schnittstellen zu DATEV, etwa den JSON-Import." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Logbuch" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "logs.api", label: "API", description: "Erlaubt den Zugriff auf das Logbuch des Servers. Dies ist sehr technisch und für wenige Nutzer von wirklichem Interesse, kann aber im Fall der Fälle aufschlussreich sein. Keine Sorge, wir schauen uns das sowieso an." },
                                        { name: "logs.email", label: "Mailversand", description: "Erlaubt den Zugriff auf das Mail-Log. Hier sehen Sie genau, wann eine Mail gesendet und empfangen wurde. Wir speichern den Inhalt Ihrer Nachrichten nicht." }
                                    ]}
                                />
                                <PermissionGroup 
                                    label="Sonstige" 
                                    disabled={formik.values.isClient} 
                                    readOnly={role?.isInitialRole} 
                                    data={[
                                        { name: "countryCodes.all", label: "Ländercodes", description: "Erlaubt das Einsehen von Ländercodes." },
                                        { name: "legalForms.all", label: "Rechtsformen", description: "Erlaubt das Einsehen von Rechtsformen." }
                                    ]}
                                />
                            </Flex>
                        </Flex>
                    )
                }
            </ModalForm>
        </WithPermissions>    
    )
}