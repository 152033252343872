import useSWR from "swr";
import { ApiRoutes, RouteParam } from "../../../api/ApiRoutes";
import { IUserDefaultTeams } from "../../../types/ApiTypes";

export const useAnyUserDefaultTeam = (id: string) => {
    const { data, isLoading, mutate } = useSWR<IUserDefaultTeams>(ApiRoutes.User.Teams.Default.getValue({ param: RouteParam.UserId, value: id}));

    return {
        defaultTeam: data,
        loadingDefaultTeam: isLoading,
        reloadDefaultTeam: mutate
    }
}