import React from "react";
import { useSearchParams } from "react-router-dom";
import { generateClassName } from "../../hooks/useAttributes";
import { IAuthorizationOptions } from "../../hooks/useAuthorized";
import Tab from "./Tab";
import "./TabSwitcher.css";

export interface ITabData extends IAuthorizationOptions {
    label?: string,
    data: string,
    first?: boolean,
    priority?: number,
    hidden?: boolean,
    showLabelOnlyOnActive?: boolean,
    icon?: string,
    iconSize?: number,
    iconTooltip?: string,
    foreColor?: string,
    backColor?: string
}

export interface ITabHighlightData {
    left: number,
    width: number,
    height: number
}

export type TabSwitcherSize = "small" | "regular" | "tiny";

export type TabSwitcherVariant = "muted" | "standard" | "icons";

interface ITabSwitcherProps {
    tabs: Array<ITabData>,
    primaryPriority?: number,
    variant?: TabSwitcherVariant,
    size?: TabSwitcherSize,
    className?: string,
    gap?: 0 | 1 | 2 | 3 | 4 | 5,
    defaultIconSize?: number,
    align?: "start" | "end",
    removeOnUnmount?: boolean,
    displayedPriority?: number,
    tabQueryParamKey: string,
    saveActiveTab?: (data: string) => void
}

export default function TabSwitcher({saveActiveTab, removeOnUnmount = false, defaultIconSize, size = "regular", gap, align, tabs, displayedPriority, primaryPriority, variant = "standard", tabQueryParamKey, className}: ITabSwitcherProps) {
    const [currentData, setCurrentData] = React.useState<string>();
    const [query, setQuery] = useSearchParams();

    const switcherRef = React.useRef<HTMLDivElement>(null);

    const getGap = () => {
        if (gap) return gap;

        switch (size) {
            case "small": return 2;
            case "regular": return 3;
            case "tiny": return 2;
        }
    }

    const getFirstTab = (): ITabData | undefined => {
        if (!tabs || !tabs.length) return;
        const firstTab = tabs.find(t => t.first && !t.hidden);
        if (firstTab) return firstTab;
        return tabs.find(t => !t.hidden);
    }

    React.useEffect(() => {
        return () => {
            if (!removeOnUnmount) return;
            const newQuery = new URLSearchParams(query);
            newQuery.delete(tabQueryParamKey);
            
            setQuery(newQuery, { replace: true });
        }
    }, [])
    
    React.useEffect(() => {
        if (!tabs || !tabs.length) return;
        
        const firstTab = getFirstTab();
        
        if (!firstTab) return;
        if (primaryPriority !== undefined && firstTab.priority !== primaryPriority) return;
        
        if (!tabQueryParamKey) {
            switchTabs(firstTab);
            return;
        }
        
        if (!query) {
            switchTabs(firstTab);
            return;
        }
        
        const data = query.get(tabQueryParamKey);

        if (!data) {
            switchTabs(firstTab);
            return;
        }
    
        const tab = tabs.find(t => t.data === data);

        if (tab) switchTabs(tab);
        else switchTabs(firstTab);

    }, [tabs, query]);

    if (!tabs) return null;

    const switchTabs = (tab: ITabData) => {
        const currentQuery = query.get(tabQueryParamKey);

        setCurrentData(tab.data);

        if (saveActiveTab) saveActiveTab(tab.data);

        if (currentQuery === tab.data) return;

        const newQuery = new URLSearchParams(query);
        newQuery.set(tabQueryParamKey, tab.data);
        
        setQuery(newQuery, { replace: true });
    }
    
    const visibleTabs = !!tabs && !!tabs.length && tabs.filter(t => !t.hidden);

    if (!tabs || !tabs.length || !visibleTabs || visibleTabs.length <= 1) return null;

    const tabSwitcherClassName = generateClassName(className, "position-relative tab-switcher d-flex flex-row align-items-center", {
        value: size,
        base: "tab-switcher-"
    }, {
        value: align,
        base: "justify-content-"
    }, {
        value: getGap(),
        base: "gap-"
    });

    return (
        <div className={tabSwitcherClassName} ref={switcherRef} style={{overflowX: "auto", overflowY: "hidden"}}>
            {
                tabs.map((t, index) => (
                    (displayedPriority === undefined || t.priority === displayedPriority) && (
                    <Tab 
                        size={size} 
                        key={`tab-switcher-tab-${t}-${index}`} 
                        content={t} 
                        defaultIconSize={defaultIconSize}
                        variant={variant} 
                        currentData={currentData} 
                        setActiveTab={switchTabs} 
                    />
                )))
            }
        </div>
    )

}