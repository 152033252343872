import React from "react";
import { AppColor } from "../../app/AppStyles";
import Button from "../buttons/Button";
import Expandable from "../comboBox/Expandable";
import Typography, { TypographySize } from "../text/Typography";
import "./Select.css";

export type SelectItem<T extends (string | number)> = T | {
    label: string,
    data: T
}

export interface ISelectProps<T extends (string | number)> {
    values: Array<SelectItem<T>>,
    getLabel?: (data: T) => string,
    value?: T,
    disabled?: boolean,
    readOnly?: boolean,
    bold?: boolean,
    color?: AppColor,
    size?: TypographySize,
    onChange: (value: T) => void,
    label?: string
}

export default function Select<T extends (string | number)>(props: ISelectProps<T>) {

    const {
        color, 
        values, 
        bold, 
        disabled, 
        label, 
        onChange, 
        getLabel,
        size, 
        value,
        readOnly
    } = props;

    const [currentValue, setCurrentValue] = React.useState<SelectItem<T> | undefined>();

    const getLabelFromValue = (value: SelectItem<T>) => {
        switch (typeof value) {
            case "string":
            case "number":

                return getLabel?.(value) ?? `${value}`;
            
            default:

                return value.label;
        }
    }

    const getDataFromValue = (value: SelectItem<T>) => {
        switch (typeof value) {
            case "string":
            case "number":

                return value;
            
            default:

                return value.data;
        }
    }

    React.useEffect(() => {
        if (!value) return setCurrentValue(undefined);
        if (!values || !values.length) return setCurrentValue(undefined);

        const matchingValue = values.find(v => getDataFromValue(v) === value);

        if (!matchingValue) return setCurrentValue(undefined);

        setCurrentValue(matchingValue)

    }, [value, values]);

    const clickHandler = (o: SelectItem<T>) => onChange(getDataFromValue(o));

    return (
        <div className="d-flex flex-column position-relative gap-2 select-button" >
            {
                label && <Typography bold={bold} color="primary">{label}</Typography>
            }
            <Expandable
                disabled={disabled || readOnly}
                expander={(
                    <Button
                        readOnly={readOnly}
                        disabled={disabled}
                        className="select-button-button"
                        color={color ?? "primary"}
                        variant={disabled ? "text" : "subtle"}
                        text={currentValue ? getLabelFromValue(currentValue) : "Auswählen..."}
                    />
                )}

            >
                <div className="d-flex flex-column pb-2 pt-2 gap-0 w-100 h-100 text-start">
                    {
                        values && values.map((o: SelectItem<T>) => (
                            <div key={`select-item-${getDataFromValue(o)}`} className="w-100 p-1 ps-3 pe-3 select-button-item" onClick={() => clickHandler(o)}>
                                <Typography 
                                    size={size} 
                                    bold    
                                    align="start"
                                    color={value && value === getDataFromValue(o) ? "primary" : "muted"} 
                                    upper
                                >
                                    {getLabelFromValue(o)}
                                </Typography>
                            </div>
                        ))
                    }
                </div>
            </Expandable>
        </div>
    )
}

