import React from "react";
import { useImportValidationError } from "../../state/swr/importValidation/useImportValidationError";
import { IAddressee } from "../../types/ApiTypes";
import ImportValidationChip from "../importValidation/ImportValidationChip";
import LoadingSpinner from "../loader/LoadingSpinner";

export default function AddresseeDuplicateStatus({addressee, showName = false}: {addressee: IAddressee, showName?: boolean}) {
    const {
        importValidationErrors,
        loadingImportValidationErrors
    } = useImportValidationError({ addressee: addressee._id });

    if (loadingImportValidationErrors) return <LoadingSpinner />
    if (!importValidationErrors || !importValidationErrors.length) return null;

    return (
        <>
            { importValidationErrors.map(i => <ImportValidationChip importValidationError={i} key={i._id} addressee={addressee} />) }
        </>
    )
}