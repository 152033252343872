import React, { useEffect } from "react";
import { DatevAddresseeAddressType, DbId, IUser } from "../../types/ApiTypes";
import LoadingSpinner from "../loader/LoadingSpinner";
import Table from "../tables/Table";
import EmployeeListItem from "./EmployeeListItem";
import { useDatevEmployees } from "../../state/swr/employees/useDatevEmployees";
import { useEmployees } from "../../state/swr/employees/useEmployees";
import { useRoles } from "../../state/swr/roles/useRoles";
import WithPermissions from "../permissions/WithPermissions";
import { getId } from "../../util/mongoUtil";
import { IDatevEmployee } from "../../types/employee.schema";

interface ILinkedEmployee {
    id: string,
    datevEmployee?: IDatevEmployee,
    employee: string | IUser
}

export default function EmployeeList() {
    const [sortedEmployees, setSortedEmployees] = React.useState<ILinkedEmployee[]>([]);
    
    const { datevEmployees, loadingDatevEmployees } = useDatevEmployees();
    const { employees, loadingEmployees } = useEmployees();

    useEffect(() => {
        if (loadingEmployees) return;
        if (loadingDatevEmployees) return;

        const map: { [key: string]: ILinkedEmployee } = {};

        if (datevEmployees && !!datevEmployees.length) {
            datevEmployees.forEach(d => {
                const id = d.user ? getId(d.user) : getId(d);

                const item = {
                    id: d._id, 
                    employee: d.user, 
                    datevEmployee: d
                }

                map[id] = item;
            });
        }

        if (employees && !!employees.length) {
            employees.forEach(e => {
                const id = getId(e);
                if (!!map[id]) map[id].employee = e;
                else map[id] = {id: e._id, employee: e, datevEmployee: undefined};
            })
        }

        const result = Object.values(map);
        setSortedEmployees(result);
    }, [datevEmployees, employees, loadingEmployees, loadingDatevEmployees]);
    
    if (loadingDatevEmployees || loadingEmployees) return <LoadingSpinner centered={false} />
    
    if (!sortedEmployees || !sortedEmployees.length) return <span>Keine Mitarbeiter</span>;

    return (
        <WithPermissions permissions={["users.employees.read"]}>
            <div className="h-100">
                <Table 
                    border 
                    className="w-100" 
                    headers={[{label: "Name"}, {label: "Mail"}, {label: "Status"}, {label: "Mit DATEV verknüpft"}, {label: "Tenant-Admin"}, {label: "Aktiv seit"}, {label: ""}]}
                    renderItem={e => <EmployeeListItem key={e.id} employee={typeof e.employee === "string" ? undefined : e.employee} datevEmployee={e.datevEmployee} />}
                    items={sortedEmployees}
                />
            </div>
        </WithPermissions>
    )
}