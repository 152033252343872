import useSWR from "swr";
import { IUserStatisticsForTeam } from "../../../types/ApiTypes";
import { ApiRoutes } from "../../../api/ApiRoutes";

export const useAllTeamsStatistics = () => {
    const { data, isLoading, mutate } = useSWR<Array<IUserStatisticsForTeam>>(ApiRoutes.Statistics.CurrentTenantTeams, { revalidateOnFocus: false, revalidateIfStale: false });

    return {
        allTeamsStatistics: data,
        loadingAllTeamsStatistics: isLoading,
        reloadAllTeamsStatistics: mutate
    }
}