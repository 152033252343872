import { Message } from "@microsoft/microsoft-graph-types";
import React from "react";
import { generateClassName } from "../../../hooks/useAttributes";
import useFilteredOfficeMails, { OfficeMailboxTab } from "../../../hooks/useFilteredOfficeMails";
import useInboxPermissions from "../../../hooks/useInboxPermissions";
import { useAllTeamOfficeAccounts } from "../../../state/swr/office/useAllTeamOfficeAccounts";
import { useUser } from "../../../state/swr/user/useUser";
import { IOfficeAccount, OfficeAccountType } from "../../../types/officeAccount.types";
import LinkOfficeAccountButton from "../account/LinkOfficeAccountButton";
import Flex from "../../container/Flex";
import LoadingSpinner from "../../loader/LoadingSpinner";
import WithPermissions from "../../permissions/WithPermissions";
import TeamBanner from "../../teams/TeamBanner";
import Typography from "../../text/Typography";
import "./OfficeInbox.css";
import OfficeInboxMails from "./OfficeInboxMails";

export interface IWithSelectedMailProps {
    selectedMail?: Message,
    setSelectedMail: (mail?: Message) => void,
    setSelectedFolder: (folder?: string) => void,
    setSelectedFolderTitle: (title?: string) => void,
    setEditorVisible: (visible: boolean) => void,
    selectedFolder?: string,
    selectedFolderTitle?: string,
    isResponding?: boolean,
    isEditorVisible?: boolean
}

export interface IOfficeInboxProps extends IWithSelectedMailProps {
    account?: IOfficeAccount, 
    showAll?: boolean
}

export default function OfficeInbox({account, setSelectedMail, setSelectedFolderTitle, isEditorVisible, isResponding, setEditorVisible, setSelectedFolder, selectedFolder, selectedFolderTitle, selectedMail, showAll}: IOfficeInboxProps) {
    const [currentAccount, setCurrentAccount] = React.useState<IOfficeAccount>();
    
    const { getAccountPermissions } = useInboxPermissions();

    const { allTeamOfficeAccounts, loadingAllTeamOfficeAccounts, reloadAllTeamOfficeAccounts } = useAllTeamOfficeAccounts();
    const { user, loadingUser } = useUser();
    const { view } = useFilteredOfficeMails();

    React.useEffect(() => {
        if (view !== OfficeMailboxTab.Teams) return setCurrentAccount(account);
        else if (loadingAllTeamOfficeAccounts) return;
        else if (!allTeamOfficeAccounts || !allTeamOfficeAccounts.length) return setCurrentAccount(undefined);
        else if (!account) return setCurrentAccount(allTeamOfficeAccounts[0]);
        else {
            const teamAccount = allTeamOfficeAccounts.find(a => a._id === account._id);
            if (teamAccount) return setCurrentAccount(teamAccount);
            else return setCurrentAccount(allTeamOfficeAccounts[0]);
        }
    }, [view, loadingAllTeamOfficeAccounts, allTeamOfficeAccounts, account]);

    if (!currentAccount) {
        if (view === OfficeMailboxTab.Teams && loadingAllTeamOfficeAccounts) return <LoadingSpinner text="Konten werden geladen..." />
        else if (view !== OfficeMailboxTab.Personal) return <span>{showAll ? "Kein Konto ausgewählt" : "Kein Konto verknüpft"}</span>
        
        return (
            <Flex justify="start">
                <p>Sie haben Ihr Office365-Konto noch nicht mit unserem System verknüpft. Verknüpfen Sie Ihr Konto, um Ihre Nachrichten zu sehen.</p>
                {
                    loadingUser
                    ? <LoadingSpinner />
                    : <LinkOfficeAccountButton type={OfficeAccountType.Personal} onBehalfOfUser={user?._id} />
                }
            </Flex>
        )
    } 

    const className = generateClassName("office-inbox position-relative w-100", {
        value: view === OfficeMailboxTab.Teams,
        onTrue: "office-inbox-team"
    });

    const permissions = getAccountPermissions("read", currentAccount);

    return (
        <WithPermissions permissions={permissions}>
            <Flex className={className} gap={3}>
                {
                    view === OfficeMailboxTab.Teams && (
                        <Flex className="w-100">
                            <Typography size={14} bold>Verfügbare Teams</Typography>
                            {

                                loadingAllTeamOfficeAccounts        
                                ? <LoadingSpinner />
                                : (
                                    allTeamOfficeAccounts && allTeamOfficeAccounts.length
                                    ? (
                                        <Flex row wrap className="w-100">
                                            {
                                                allTeamOfficeAccounts.map(a => <TeamBanner team={a.assignments.team!} key={a._id} onClick={() => setCurrentAccount(a)} isActive={currentAccount && currentAccount._id === a._id}/>)
                                            }
                                        </Flex>
                                    )
                                    : <span>Keine Teams</span>
                                )
                            }
                        </Flex>
                    )
                }
                <OfficeInboxMails   
                    setSelectedFolderTitle={setSelectedFolderTitle}
                    setEditorVisible={setEditorVisible}
                    setSelectedFolder={setSelectedFolder}
                    selectedFolder={selectedFolder}
                    selectedFolderTitle={selectedFolderTitle}
                    account={currentAccount} 
                    selectedMail={selectedMail} 
                    isEditorVisible={isEditorVisible}
                    isResponding={isResponding}
                    setSelectedMail={setSelectedMail} 
                />
            </Flex>
        </WithPermissions>
    )
}