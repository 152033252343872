import { IMongooseDocument } from "./ApiTypes";

export enum TicketActivityType {
    Created = "created",
    Assigned = "assigned",
    Unassigned = "unassigned",
    ClientChanged = "clientChanged",
    Closed = "closed",
    Reopened = "reopened",
    Resubmission = "resubmission",
    Solved = "solved",
    Archived = "archived",
    Escalated = "escalated",
    Merged = "merged",
    NoteAdded = "noteUpdated",
    MessageAdded = "messageAdded",
    MessageUpdated = "messageUpdated",
    InternalSubjectChanged = "internalSubjectChanged",
    AssignedToTeam = "assignedToTeam",
}

export interface ITicketActivityData {
    ticket?: string,
    mergedIntoTicket?: string,
    message?: string,
    activityType: TicketActivityType,
    activityBy?: string,
    activityAffectsClient?: string,
    activityAffectsUser?: string,
    activityAffectsMail?: string,
    activityAffectsTeam?: string,
    activityByMail?: string,
    isSystemActivity?: boolean
}

export interface ITicketActivity extends IMongooseDocument, ITicketActivityData { }

