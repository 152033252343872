import React from "react";
import { useClients } from "../../state/swr/clients/useClients";
import { useAnyUserClients } from "../../state/swr/user/useAnyUserClients";
import { Permission } from "../../types/ApiTypes";
import { IClient } from "../../types/client.schema";
import SearchableComboBox from "../comboBox/SearchableComboBox";
import WithPermissions from "../permissions/WithPermissions";
import ClientTypeBanner from "./ClientTypeBanner";

export interface IClientSelectProps {
    saveClient: (client?: IClient) => void,
    clientId?: string,
    hideLabel?: boolean,
    useDefaultValue?: boolean,
    showAllClients?: boolean,
    clientContactId?: string,
    bold?: boolean,
    hideIfEmpty?: boolean
}

export default function ClientSelect({saveClient, hideLabel, useDefaultValue, bold, hideIfEmpty = true, showAllClients = false, clientId, clientContactId}: IClientSelectProps) {

    const { clients, loadingClients } = useClients();
    const { userClients, loadingUserClients } = useAnyUserClients(clientContactId || "");

    const requiredPermissions: Array<Permission> = showAllClients ? ["clients.all.read"] : ["clients.own.read" ];

    const realClients = showAllClients ? clients : userClients?.clients;

    if ((!loadingClients && !loadingUserClients) && (!realClients || !realClients.length)) return null;

    return (
        <WithPermissions permissions={requiredPermissions} >
            <SearchableComboBox
                loading={loadingUserClients || loadingClients }
                bold={bold}
                useDefaultValue={useDefaultValue}
                renderItem={c => <ClientTypeBanner className="w-100" client={c} showClientName />}
                label={hideLabel ? undefined : "Mandant"}
                className="w-100"
                values={realClients || []}
                value={clientId}
                itemToId={client => client._id}
                itemToString={client => client.name}
                onItemClick={client => saveClient(client)}
                placeholder={hideLabel ? "Mandant" : "Mandant auswählen"}
            />
        </WithPermissions>
    )
}