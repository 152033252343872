export const Locale = {
    errors: {
        logInVerification: {
            noOtp: "Bitte füllen Sie alle Felder aus.",
            otpVerificationFailed: "Der eingegebene Code ist nicht korrekt. Bitte überprüfen Sie den Code."
        },
        notFound: "Das angeforderte Element existiert nicht.",
        generic: "Es ist ein Fehler aufgetreten."
    },
    titles: {
        salutations: "Anreden",
        roundMail: "Rundmail",
        testing: "Testing",
        roleManagement: "Rollenverwaltung",
        inbox: "Posteingang",
        mailLog: "E-Mail-Logbuch",
        clientResponsibilityManagement: "Mandatszuständigkeiten",
        mailManagement: "E-Mail-Postfächer verwalten",
        giveDocumentClearance: "Abgabe aller Belege bestätigen",
        documentClearance: "FIBU-Freigabe",
        billing: "Bezahlung und Abrechnung",
        addresseeManagement: "Addressaten",
        logs: "Logbuch",
        feedback: "Feedback geben",
        clientManagement: "Mandate",
        teamsManagement: "Teams",
        userManagement: "Alle Nutzer verwalten",
        tenantManagement: "Alle Tenants verwalten",
        tickets: "Tickets",
        dashboard: "Dashboard",
        register: "Registrieren",
        logIn: "Log In",
        profile: "Profil",
        tenantDomainManagement: "Domains verwalten",
        domainManagement: "Alle Domains verwalten",
        settings: "Einstellungen",
        clientContactManagement: "Mandantenkontakte",
        employeeManagement: "Mitarbeiterverwaltung",
        serviceManagement: "Abos und Pläne"
    },
    pages: {
        billing: {
            addPaymentMethod: "Zahlungsmethode hinzufügen",
            removePaymentMethod: "Zahlungsmethode entfernen",
            makePaymentMethodDefault: "Zahlungsmethode als Standard setzen"
        },
        services: {
            updateService: "Abo aktualisieren",
            updateFeature: "Feature aktualisieren",
            updatePlan: "Plan aktualisieren",
            addService: "Neues Abo",
            addPlan: "Neuer Plan",
            addFeature: "Neues Feature",
            addCoupon: "Neue Rabattgruppe",
            addPromotionCode: "Neuer Gutscheincode"
        },
        teamManagement: {
            addTeam: "Team hinzufügen",
            updateTeam: "Team bearbeiten",
            updateTeamButton: "Bearbeiten",
            newTeam: "Neues Team"
        },
        tenantManagement: {
            addTenant: "Tenant hinzufügen",
            updateTenant: "Tenant bearbeiten",
            updateTenantButton: "Bearbeiten",
            newTenant: "Neuer Tenant"
        },
        clientContactManagement: {
            addClientContact: "Mandantenkontakt hinzufügen",
            updateClientContactButton: "Nutzer Bearbeiten",
            updateClientContact: "Mandantenkontakt bearbeiten",
            newClientContact: "Neuer Mandantenkontakt"
        },
        clientManagement: {
            addClient: "Mandant hinzufügen",
            updateClientButton: "Bearbeiten",
            updateClient: "Mandant bearbeiten",
            newClient: "Neuer Mandant"
        },
        employeeManagement: {
            addEmployee: "Mitarbeiter hinzufügen",
            updateEmployeeButton: "Bearbeiten",
            updateEmployee: "Mitarbeiter bearbeiten",
            newEmployee: "Neuer Mitarbeiter"
        },
        userManagement: {
            addUser: "Nutzer hinzufügen",
            updateUserButton: "Bearbeiten",
            updateUser: "Nutzer bearbeiten",
            newUser: "Neuer Nutzer",
        }
    }
}