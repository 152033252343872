import React from "react";
import Card from "../components/card/Card";
import ConnectorSettings from "../components/connectors/ConnectorSettings";
import GlobalSettings from "../components/settings/GlobalSettings";
import TabSwitcher from "../components/tabswitcher/TabSwitcher";
import TenantUpdateForm from "../components/tenant/settings/TenantUpdateForm";
import UserSettings from "../components/user/UserSettings";
import useTenantSettings from "../hooks/useTenantSettings";
import Page from "../layout/Page";
import { Locale } from "../locale/Locale";
import { useTenant } from "../state/swr/tenant/useTenant";
import { useUser } from "../state/swr/user/useUser";

enum SettingsTab {
    User = "user",
    Portal = "portal",
    Interfaces = "interfaces",
    Tenant = "tenant"
}
export default function Settings() {
    const {
        titles
    } = useTenantSettings(true);

    const [tab, setTab] = React.useState<SettingsTab>(SettingsTab.User);

    const { tenant } = useTenant();
    const { user } = useUser();

    if (!user) return null;
    
    const getContent = () => {
        switch (tab) {
            case SettingsTab.Interfaces: return <ConnectorSettings />;
            case SettingsTab.Portal: return <GlobalSettings />;
            case SettingsTab.Tenant: return tenant ? <TenantUpdateForm tenant={tenant} canDelete={false} /> : null;
            case SettingsTab.User: return <UserSettings />
        }
    }

    return (
        <Page header={Locale.titles.settings} >
            <TabSwitcher 
                tabQueryParamKey="details" 
                saveActiveTab={(t) => setTab(t as SettingsTab)} 
                tabs={[
                    {
                        data: SettingsTab.User,
                        icon: "person-badge-fill",
                        label: "Ihr Konto"
                    },
                    {
                        data: SettingsTab.Tenant,
                        icon: "building",
                        label: titles?.tenantWord || "Ihre Kanzlei",
                        permissions: [ "tenant.own.update" ]
                    }, 
                    {
                        data: SettingsTab.Portal,
                        icon: "gear-wide-connected",
                        label: "Systemeinstellungen",
                        permissions: [ "tenant.own.update" ]
                    },
                    {
                        icon: "router-fill",
                        data: SettingsTab.Interfaces,
                        label: "Schnittstellen",
                        permissions: [ 
                            "interfaces.cloudGateway.read", 
                            "interfaces.datev.read",
                            "interfaces.pointchamp.read"
                        ]
                    },
                ]} 
            />
            <Card>
                {getContent()}
            </Card>
        </Page>
    )
}