import useSWR from "swr";
import { ApiRoutes } from "../../../api/ApiRoutes";
import { IRole } from "../../../types/ApiTypes";

export const useCurrentTenantRole = () => {
    const { data, isLoading, mutate } = useSWR<IRole>(ApiRoutes.Roles.Current);

    return {
        role: data,
        loadingRole: isLoading,
        reloadRole: mutate
    }
} 