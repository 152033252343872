import React from "react";
import {  } from "../../../api/ApiRequests";
import { IAttachment, ITicketMessage } from "../../../types/ApiTypes";
import Pill from "../../pills/Pill";
import useAttachments from "../../../hooks/useAttachments";
import TicketChatAttachmentIcon from "./TicketChatAttachmentIcon";
import Icon from "../../icons/Icon";
import { formatFileSize } from "../../../util/formatter";
import LoadingSpinner from "../../loader/LoadingSpinner";
import { ticketMessagesDownloadAttachments } from "../../../api/Api";
import useApi from "../../../hooks/useApi";
import { generateClassName, generateStyle } from "../../../hooks/useAttributes";
import useDownloads from "../../../hooks/useDownloads";
import { hexWithOpacity } from "../../../util/util";
import { getTextColorFromBackground } from "../../../util/util";
import Flex from "../../container/Flex";
import MailAttachment from "../../mail/MailAttachment";

export default function TicketChatMessageAttachment({attachment, message, fullWidth, size = "small"}: {attachment: IAttachment, message: ITicketMessage, fullWidth?: boolean, size?: "small" | "regular"}) {
    
    const {
        downloadFile    
    } = useDownloads();

    const callApi = useApi();

    const clickHandler = async () => {
        const res = await callApi(ticketMessagesDownloadAttachments({attachments: [attachment], id: message._id}));
        if (res && res.success) downloadFile(attachment, res.data.content);
    }

    return (
        <MailAttachment 
            hoverIcon="download"
            onClick={clickHandler}
            size="small"
            attachment={attachment}
        />
    )
}