import { IDomain } from "../types/ApiTypes";
import { Mode } from "./envVars";

export const hexWithOpacity = (color: string, opacity: number, isVar: boolean = false) => {
    try {
        const realInitialColor = parseVarColorToHex(color);
        const realOpacity = opacity < 0 ? 0 : (opacity > 1 ? 1 : opacity);
        const opacityHex = Math.floor(realOpacity * 255).toString(16).padStart(2, "0");

        const realColor = realInitialColor ? realInitialColor.replace("#", "").substring(0, 6) : "000000";

        return `#${realColor}${opacityHex}`;
    }
    catch { }

    return color;
}

export const generatePleasantBlueHex = () => {
    const hasMoreGreen = Math.random() > 0.5;
    const r = Math.floor(Math.random() * 50);
    const g = (hasMoreGreen ? 50 : 0) + Math.floor(Math.random() * 100);
    const b = 110 + Math.floor(Math.random() * 106); // 150 to 255

    const toHex = (value: number) => {
        let hex = value.toString(16);
        return hex.length < 2 ? "0" + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const getColorOrShade = (color?: string) => {
    if (!color) return generatePleasantBlueHex();

    const colorRgb = hexToRgb(color);

    if (!colorRgb) return generatePleasantBlueHex();

    if (colorRgb.r > 220 && colorRgb.b > 220 && colorRgb.g > 220) return generatePleasantBlueHex();

    return color;
}


export const isDevelopment = (): Boolean => {
  if (!Mode) return false;
  return Mode === "DEV";
}

export const buildUrlFromDomain = (domain: IDomain) => {
    let result = domain.domain;
    
    if (domain.protocol) result = [domain.protocol, domain.domain].join("://").trim();
    if (domain.usePort) result += `:${domain.portNumber}`;

    return result;
}

export const hexToRgb = (hex: string): {r: number, b: number, g: number} | null => {
    if (!hex || typeof hex !== "string") return null;

    const regex = hex.length === 4 
    ? /^#?([a-f\d])([a-f\d])([a-f\d])$/i 
    : /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{0,2})$/i;

    var result = regex.exec(hex.trim());

    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
}

export const parseVarColorToHex = (color?: string, standard: string = "#FFFFFF"): string => {

    if (!color) return parseVarColorToHex(standard);
    if (color.includes("#")) {
        if (color.length === 7 || color.length === 9) return color;

        if (color.length === 4) {
            const r = color[1];
            const g = color[2];
            const b = color[3];
            return `#${r}${r}${g}${g}${b}${b}`;
        }
    }
    
    const computedValue = getComputedStyle(document.documentElement).getPropertyValue(`--${color}`);
    
    if (!computedValue) return "#FFFFFF";

    return computedValue;
}

export const getRangeValueBasedOnBrightness = (start: number, end: number, color?: string): number => {
    const luminosity = getLuminosityOfColor(color);
    const range = end - start;

    const offsetStart = 80;
    const offsetEnd = 200;
    const offsetRange = offsetEnd - offsetStart;

    if (luminosity < offsetStart) return start;
    if (luminosity > offsetEnd) return end;

    const percentage = (luminosity - offsetStart) / offsetRange;
    return start + (range * percentage);
}

export const isBrightColor = (color?: string): boolean => {
    const luminosity = getLuminosityOfColor(color);
    return (luminosity > 186);
}

export const getLuminosityOfRgb = (rgb: {r: number, g: number, b: number}): number => {
    return (rgb.r*0.299 + rgb.g*0.587 + rgb.b*0.114);
}

export const getLuminosityOfColor = (color?: string): number => {
    const realColor = parseVarColorToHex(color);
    const rgb = hexToRgb(realColor);

    if (!rgb) return 255;
    return getLuminosityOfRgb(rgb);
}

export const getReadableColor = (color?: string, darkenBy: number = 0.1) => {
    const realColor = parseVarColorToHex(color);
    
    const colorIsBright = isBrightColor(realColor);
    
    if (!colorIsBright) return realColor;

    const rgb = hexToRgb(realColor);
    
    if (!rgb) return realColor;

    const primaryColor = Math.max(rgb.r, rgb.g, rgb.b);

    const getAdjustment = (n: number) => {
        if (n === primaryColor) {
            const pResult = n * (1 + darkenBy);
            return pResult > 255 ? 255 : pResult;
        }

        const result = n * (1 - darkenBy);
        return result < 0 ? 0 : result;
    }
    
    const newValues = {
        r: Math.floor(getAdjustment(rgb.r)),
        g: Math.floor(getAdjustment(rgb.g)),
        b: Math.floor(getAdjustment(rgb.b))
    }

    return `#${newValues.r.toString(16)}${newValues.g.toString(16)}${newValues.b.toString(16)}`;
}

export const getTextColorFromBackground = (color?: string, standard: string = "#000000"): string => {
    if (isBrightColor(color)) return "#000000";
    return "#FFFFFF";
}

export const getEnumAsArray = <T = string | number>(x: Object): Array<{key: string, value: T}> => {
    if (!x) return [];

    const enumContent = Object.values(x);

    if (!enumContent || !enumContent.length) return [];

    const startOfValues = enumContent.length / 2;

    const keys = enumContent.splice(0, startOfValues);
    const values = enumContent;

    return keys.map((k, index) => ({key: k, value: values[index]}));
}

export interface TimeSpan {
    minutes?: number,
    hours?: number,
    days?: number,
    weeks?: number
  };
  
export const getDateInXFromNow = ({minutes = 0, hours = 0, days = 0, weeks = 0}: TimeSpan) => {

    const minute = 1000 * 60;
    const hour =  minute * 60;
    
    let millies = 0;

    if (minutes !== 0) millies += minute * minutes;
    if (hours !== 0) millies += hours * hour;
    if (days !== 0) millies += (days * hour * 24);
    if (weeks !== 0) millies += (weeks * hour * 24 * 7);
    
    const result = new Date();
    result.setTime(Date.now() + millies);
    return result;
}